import { Component, OnInit, Input } from '@angular/core';
import { BlockModel } from '@app/shared/models/block.model';

@Component({
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss']
})
export class BlockComponent implements OnInit {

  @Input() block: BlockModel;
  @Input() day: Date;

  constructor() { }

  ngOnInit(): void {
    console.log(this.day)
  }

}
