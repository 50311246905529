import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundPipe } from './pipes/round.pipe';
import { RouterModule } from '@angular/router';

// info: need to be imported in every child module
import { TranslateModule } from '@ngx-translate/core';

// Shared components
import { LogoComponent } from './components/logo/logo.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SidebarContainerComponent } from './components/sidebar/sidebar-container/sidebar-container.component';
import { SidebarHeaderComponent } from './components/sidebar/sidebar-header/sidebar-header.component';
import { SiccheEmoticonComponent } from './components/sicche-emoticon/sicche-emoticon.component';
import { CoreModule } from '@app/core/core.module';
import { DateAgoPipe } from './pipes/date-ago.pipe';

@NgModule({
    declarations: [
        RoundPipe,
        LogoComponent,
        SpinnerComponent,
        SidebarContainerComponent,
        SidebarHeaderComponent,
        SiccheEmoticonComponent,
        DateAgoPipe
    ],
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule
    ],
    exports: [
        RoundPipe,
        LogoComponent,
        SpinnerComponent,
        TranslateModule,
        SidebarContainerComponent,
        SidebarHeaderComponent,
        SiccheEmoticonComponent,
        DateAgoPipe
    ]
})
export class SharedModule { }
