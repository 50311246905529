import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { BlockModel } from '@app/shared/models/block.model';
import { TeamModel } from '@app/shared/models/team.model';
import { AnswerService } from '@app/shared/services/answer.service';
import { BlockService } from '@app/shared/services/block.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AnswerBaseComponent } from '../answer-base-component';

@Component({
  selector: 'app-communications',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.scss']
})
export class CommunicationsComponent extends AnswerBaseComponent implements OnInit {

  @Input() questionInput: QuestionModel;
  @Input() blockInput: BlockModel;
  @Input() userInput: TeamModel;
  @Output() closeAnswerInput = new EventEmitter();


  constructor(
    public toastService: ToastrService,
    public translateService: TranslateService,
    public answerService: AnswerService,
    public notificationService: NotificationService,
    public blockService: BlockService
  ) {
    super(toastService, translateService, answerService, notificationService, blockService);
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['link'],
        ['emoji']
      ]
    };
  }

  ngOnInit(): void {
    this.initialize(this.questionInput, this.blockInput, this.userInput, this.closeAnswerInput, this.answer);
  }
}
