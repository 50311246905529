import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { BlockModel } from '@app/shared/models/block.model';
import { ResponseModel } from '@app/core/models/response.model';
import { ClosedMultiple } from '../models/closed-multiple';
import { AnswerService } from '@app/shared/services/answer.service';
import { TeamModel } from '@app/shared/models/team.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AnswerModel } from '@app/shared/models/answer.model';
import { OpenAnswer } from '../models/open-answer';
import { CommentModel } from '@app/shared/models/comment.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ClosedSingle } from '../models/closed-single';
import { NotificationService } from '@app/shared/services/notification.service';
import { AnswerBaseComponent } from '../answer-base-component';
import { BlockService } from '@app/shared/services/block.service';

@Component({
  selector: 'app-closed-multiple',
  templateUrl: './closed-multiple.component.html',
  styleUrls: ['./closed-multiple.component.scss']
})
export class ClosedMultipleComponent extends AnswerBaseComponent implements OnInit {


  @Input() questionInput: QuestionModel;
  @Input() blockInput: BlockModel;
  @Input() userInput: TeamModel;
  @Output() closeAnswerInput = new EventEmitter();

  constructor(
    public toastService: ToastrService,
    public translateService: TranslateService,
    public answerService: AnswerService,
    public notificationService: NotificationService,
    public blockService: BlockService
  ) {
    super(toastService, translateService, answerService, notificationService, blockService);
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['link'],
        ['emoji']
      ]
    };
  }
  currentAnswerComment = {};


  answer: ClosedSingle[] = [];


  ngOnInit(): void {
    this.initialize(this.questionInput, this.blockInput, this.userInput, this.closeAnswerInput, this.answer);
  }

  getAnswerFromId(id: number) {
    const answer = this.question.question_data.answers.filter(a => a.id === id);
    return answer[0];
  }

  checkAnswer(id: number) {
    if (this.isChecked(id)) {
      this.answer = this.answer.filter(a => a.optionid !== id);
    } else {
      this.answer = [...this.answer, {optionid: id, comment: ''}];
    }
  }
  isChecked(id: number) {
    const temp = this.answer.filter(a => a.optionid === id);
    return (temp.length > 0 ? true : false);
  }

  addCommentToAnswer(id: number) {
    const current = this.answer.filter(a => a.optionid === id)[0];
    this.answer = this.answer.filter(a => a.optionid !== id);
    current.comment = this.currentAnswerComment[id];
    this.answer = [...this.answer, current];
  }

  canSubmit() {
    return this.answer.length > 0 &&
      this.answer.every(a => !this.question.question_data.add_comment_field || (!!a.comment || !!this.currentAnswerComment[a.optionid]));
  }

  submit() {
    this.answer.forEach(a => a.comment = a.comment.trim().replace(/  +/g, ' '));
    super.submit();
  }
}
