import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { QuestionModel } from '../models/question.model';
import { PingModel } from '../models/ping.model';


@Injectable({
  providedIn: 'root'
})
export class PingService extends BaseService implements BaseServiceI {
  // tslint:disable: variable-name

  constructor(public router: Router, public apiService: ApiService, private http: HttpClient) {
    super(router, apiService, PingModel);
  }


}
