import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { ResponseModel } from '@app/core/models/response.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthLoginService {

  constructor(private apiService: ApiService) { }

  login(username: string, password: string): Observable<ResponseModel> {
    const data = {
      username,
      password,
      type: 'INTERVIEWED'
    }
    return this.apiService.post('/auth/login', data);
  }

  getCurrentUser() {
    return this.apiService.get('/current-user');
  }
}
