<div>
    <div *ngIf="question">
        <div class="question-content px-3">
            <div class="attached-file-container" *ngIf="question.question_data.attached_pdf_files">
                <span class="attached-file" *ngFor="let pdf of question.question_data.attached_pdf_files">
                    <a [href]="pdf.pdf_url | privatesrc" target="blank">{{pdf.name}}</a>
                </span>
            </div>
            <div class="attached-image-container" *ngIf="question.question_data.attached_images">
                <img *ngFor="let img of question.question_data.attached_images" [src]="img.img_url | privatesrc" [alt]="img.name">
            </div>

            <!-- answer box -->
            <div *ngIf="!question.answered_by_currentuser && (question.is_masked || answerBox || !block.is_room_group)">
                <div class="py-3">
                    <!-- answer custom -->
                    <div class="mb-3">
                        <!-- {{ 'RANK' | translate }} <b>{{ (question.question_data.best_worst ? 'BEST' : 'WORST') | translate }}</b> -->
                        <span *ngIf="question.question_data.best_worst" [innerHTML]="'RANK_BEST' | translate"></span>
                        <span *ngIf="!question.question_data.best_worst" [innerHTML]="'RANK_WORST' | translate"></span>
                    </div>

                    <div class="drag-container mb-3">
                        <div
                            class="unpositioned"
                            cdkDropList
                            [id]="'items-list'"
                            [cdkDropListConnectedTo]="cdkLists"
                            (cdkDropListDropped)="itemDropLeft($event)">
                            <div class="item-list-box">
                                <ng-container *ngFor="let item of itemsForDrag">
                                    <div cdkDrag>

                                        <!--<div *cdkDragPreview class="drag-preview">
                                        </div>-->

                                        <div *cdkDragPlaceholder class="drag-release-placeholder">
                                            <div class="answer-container">
                                                <div class="panel shadow p-0 m-0">
                                                    <div>
                                                        <div *ngIf="item.image_url" class="img-fitted position-relative">
                                                            <img [src]="item.image_url | privatesrc" alt="">
                                                        </div>
                                                    </div>
                                                    <div class="text-box p-2">{{ item.comment }}</div>
                                                    <div class="drag-handle"><i class="fas fa-grip-lines"></i></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="answer-container">
                                            <div class="panel shadow p-0 m-0">
                                                <div>
                                                    <div *ngIf="item.image_url" class="img-fitted position-relative">
                                                        <img [src]="item.image_url | privatesrc" alt="">
                                                    </div>
                                                </div>
                                                <div class="text-box p-2">{{ item.comment }}</div>
                                                <div class="drag-handle" cdkDragHandle><i class="fas fa-grip-lines"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <div class="positioned d-flex justify-content-center">
                            <div class="d-flex flex-column justify-content-between w-100 p-2">
                                <ng-container *ngFor="let pos of rank; let i = index">
                                    <div class="d-flex flex-grow-1 align-items-center mb-2">
                                        <div
                                            class="drag-zone"
                                            cdkDropList
                                            [class.r3]="rank.length === 3"
                                            [class.r5]="rank.length === 5"
                                            [id]="'items-rank-' + pos"
                                            [cdkDropListConnectedTo]="['items-list']"
                                            (cdkDropListDropped)="itemDropRight($event, pos)">

                                            <div
                                                class="drag-zone-placeholder"
                                                [class.d-none]="answer.rank[pos - 1] !== null">
                                                {{ 'DRAG_HERE' | translate }}<br/>
                                                {{ positionStr(pos) | translate }} {{ 'ITEM' | translate }}
                                            </div>

                                            <div *ngIf="answer.rank[pos - 1] !== null" class="drag-zone-element">
                                                <p class="position font-weight-bold mb-0">
                                                    {{ positionStr(pos) | translate }}
                                                </p>
                                                <div cdkDrag>
                                                    <div>
                                                        <div class="answer-container">
                                                            <div class="panel shadow p-0 m-0">
                                                                <div>
                                                                    <div *ngIf="getItemFromId(answer.rank[pos - 1]).image_url" class="img-fitted position-relative d-none">
                                                                        <img [src]="getItemFromId(answer.rank[pos - 1]).image_url | privatesrc" alt="">
                                                                    </div>
                                                                </div>
                                                                <div class="h-100 d-flex align-items-center justify-content-center p-2">
                                                                    <p class="text-box m-0">{{ getItemFromId(answer.rank[pos - 1]).comment }}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <div class="mb-0 comment-box w-100 py-3 mt-2">
                        <h5 class="mb-2">
                            <span *ngIf="this.question.question_data.mandatory_comment">
                                {{ 'COMMENT' | translate }}
                            </span>
                            <span *ngIf="!this.question.question_data.mandatory_comment">
                                {{ 'ADD_REASONS' | translate }}
                            </span>

                        </h5>
                        <div class="form-group">
                            <!--<label for="reasons" class="placeholder">{{ 'ADD_REASONS' | translate }}</label>-->
                            <!--<input id="reasons" class="form-control" type="text-area" (change)="addCommentToAnswer()" [(ngModel)]="comment">-->
                            <textarea id="reasons" class="form-control" [(ngModel)]="answer.comment"></textarea>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="mt-3 text-right">
                        <button *ngIf="!submitting" (click)="submit()" class="btn-primary sticky-footer" [disabled]="!canSubmit()">{{
                                ((question.minimum_multiple_answers_nr && question.minimum_multiple_answers_nr > 0 && !block.is_room_group)
                                ? 'SHOW_ANSWER'
                                : 'SEND_ANSWER' )| translate
                            }}</button>
                        <button *ngIf="submitting" class="btn-primary sticky-footer"><i class="fal fa-spinner-third fa-spin"></i></button>
                    </div>
                </div>
            </div>

            <!-- risposte altri utenti -->
            <div class="container-fluid mt-3" *ngIf="question.answered_by_currentuser || (!question.is_masked && block.is_room_group)">

                <div *ngIf="answers">
                    <div *ngFor="let a of answers" id="answer_id_{{a.id}}">
                        <div  class="panel shadow m-0 h-100 d-flex flex-column mt-3" id="answer_id_{{a.id}}">
                            <div class="d-flex p-0">
                                <div class="col-2 col-xl-1 px-1 text-center">
                                    <!--<img [src]="(a.team.avatar_url | privatesrc) || 'https://www.w3schools.com/howto/img_avatar.png'" class="w-100 avatar mb-3">-->
                                    <div class="avatar-image-container" [style.background-image]="'url(' + (a.team.avatar_url | privatesrc) + ')'">
                                        <div class="placeholder-avatar" *ngIf="!a.team.avatar_url">
                                            <i class="fal fa-user-alt"></i>
                                        </div>
                                    </div>
                                    <span (click)="like(a)"><small class="bold"><i class="fal fa-heart" [ngClass]="{'fill': a.total_like_to_answer || a.total_like_to_comment || a.currentuser_like_answer}"></i>&nbsp;{{a.total_like_to_answer || a.total_like_to_comment}}</small></span>
                                </div>
                                <!-- custom -->
                                <div [className]="block.is_room_group ? 'col-10' : 'col-12'">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class=" user-name mb-2">{{a.team.nickname || (a.team.firstname + ' ' + a.team.lastname)}}</div>
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <ng-container *ngFor="let itemid of a.answer_data.rank; let i = index">
                                            <div class="d-flex flex-column justify-content-center positioned-item-answer my-3 align-items-center">
                                                <p class="position text-center font-weight-bold mb-1">{{ positionStr(i + 1) | translate }}</p>
                                                <img *ngIf="getItemFromId(itemid).image_url" class="mb-1 max-width-200" [src]="getItemFromId(itemid).image_url | privatesrc" alt="">
                                                <p class="m-0 text-center">{{ getItemFromId(itemid).comment }}</p>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div *ngIf="a.answer_data.comment">
                                        <p class="font-weight-bold">{{ 'REASONS' | translate }}</p>
                                        <p>
                                            {{ a.answer_data.comment }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!-- comments and likes -->
                            <app-questions-comments-like [a]="a"  [answers]="answers"></app-questions-comments-like>

                        </div>
                    </div>
                </div>
                <div *ngIf="answers && answers.length == 0">
                    {{'NO_ANSWERS_YET' | translate}}
                </div>

                <button *ngIf="!question.answered_by_currentuser && !(!question.answered_by_currentuser && (question.is_masked || answerBox || !block.is_room_group))" (click)="answerBox = true" class="btn-sicche btn-primary sticky-footer">
                   <span *ngIf="question.minimum_multiple_answers_nr && block.is_room_group">
                        {{'ADD_NEW_POST' | translate}}<i class="fal fa-reply ml-2 mr-0"></i>
                    </span>
                    <span *ngIf="!question.minimum_multiple_answers_nr || block.is_room_group">
                        {{'SHOW_ANSWER' | translate}}<i class="fal fa-reply ml-2 mr-0"></i>
                    </span>
                </button>

            </div>
        </div>
    </div>
</div>
