import { Component, OnInit, Input, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { BlockService } from '@app/shared/services/block.service';
import { BlockModel } from '@app/shared/models/block.model';
import { QuestionService } from '@app/shared/services/question.service';
import { ResponseModel } from '@app/core/models/response.model';
import { QuestionModel } from '@app/shared/models/question.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AnswerGroupChatComponent } from './answer-group-chat/answer-group-chat.component';
import { TeamModel } from '@app/shared/models/team.model';
import { NoticeModel } from '@app/shared/models/notice.model';
import { Router } from '@angular/router';
import { NotificationService } from '@app/shared/services/notification.service';
import { UserService } from '@app/shared/services/user.service';
import { TeamService } from '@app/shared/services/team.service';
import { CoreBaseComponent } from '@app/core/components/base.component';

@Component({
  selector: 'app-question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.scss']
})
export class QuestionListComponent extends CoreBaseComponent implements OnInit {

  block: BlockModel;
  user: TeamModel;
  notice: NoticeModel;
  @ViewChildren(AnswerGroupChatComponent) public answerGroup: QueryList<AnswerGroupChatComponent>;
  answerGroupChatComponent: AnswerGroupChatComponent;

  loading = true;

  constructor(
    public coreLayoutService: CoreLayoutService,
    private questionService: QuestionService,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private blockService: BlockService,
    private router: Router,
    private notificationService: NotificationService,
    private teamService: TeamService
  ) {
    super();
  }

  questions: QuestionModel[];
  answeredQuestions: QuestionModel[];
  answeredMultipleAnswerQuestions: QuestionModel[];
  currentQuestion: QuestionModel;
  closeToHome = false;

  showQuestion = false;

  ngOnInit(): void {
    this.notice = this.notificationService.currentNotice;
    this.block = this.blockService.currentBlock;
    this.teamService.getCurrentUserTeam().subscribe((res: ResponseModel) => {
      this.user = res.getData();
      this.teamService.currentTeam = this.user;
    }, err => console.log(err));

    if (!this.notice && !this.block) {
      this.goBackToHome();
    } else {
      this.loadQuestions();
    }
  }

  previousQuestionIsAnswered(question: QuestionModel, allQuestions = []): boolean {
    if (this.block && !this.block.is_sequential_questions) {
      return true;
    }
    allQuestions.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0));
    const prevQuestion = allQuestions[allQuestions.indexOf(question) - 1] || null;
    if (!prevQuestion) {
      return true;
    }
    if (prevQuestion.nr_current_user_answers > 0) {
      return true
    }
    return false;
  }

  loadQuestions() {
    this.operation('loading-questions').reset().isSubmitting();
    this.questionService.getQuestionsByBlockId((this.block ? this.block.id : this.notice.blockid)).subscribe((res: ResponseModel) => {
      const tempQuestions = [];
      this.operation('loading-questions').isSuccess();
      const tempAnsweredQuestions = [];
      const tempMultipleAnswerQuestions = [];
      let index = 1;
      for (const key in res.getData()) {
        if (res.getData().hasOwnProperty(key)) {
          const question = res.getData()[key];
          question.minimum_multiple_answers_nr = parseInt(question.minimum_multiple_answers_nr);
          question.index = index;
          index++;
          if (!this.notice) {
            question.position = parseInt(question.position, 10);
            if (question.nr_current_user_answers == 0) {
              tempQuestions.push(question);
            } else {
              tempAnsweredQuestions.push(question);
            }
          } else {
            tempQuestions.push(question);
          }
        }
      }

      const allQuestionsArr = tempQuestions.concat(tempMultipleAnswerQuestions);

      for (const question of tempQuestions) {
        if (!this.previousQuestionIsAnswered(question, allQuestionsArr)) {
          question.disabled = true;
        }
      }


      this.questions = tempQuestions;
      this.answeredQuestions = tempAnsweredQuestions.filter(question => !question.minimum_multiple_answers_nr || question.minimum_multiple_answers_nr == 0);
      this.answeredMultipleAnswerQuestions = tempAnsweredQuestions.filter(question => question.minimum_multiple_answers_nr && question.minimum_multiple_answers_nr > 0);
      this.loading = false;
      console.log(this.answeredQuestions);

      // notice here
      this.checkNotice();
    });
  }

  hasNotDisabledQuestion(questionArr: QuestionModel[]) {
    return questionArr.find(q => !q.disabled);
  }

  closeAnswer() {
    if (this.closeToHome) {
      this.goBackToHome();
    } else {
      this.showQuestion = false;
      this.loadQuestions();
    }
  }

  checkNotice() {
    if (this.notice) {
      this.blockService.getBlockById(this.notice.blockid).subscribe((res: ResponseModel) => {
        if (res.getData()) {
          this.block = res.getData();
          const question = this.questions.find(q => q.id === this.notice.questionid);
          this.closeToHome = true;
          this.openQuestion(question, true);
        } else {
          this.toastService.error(this.translateService.instant('NOT_FOUND'));
          this.router.navigate(['/researches']);
        }
      });
    }
  }

  unlockNextQuestion() {
    this.showQuestion = false;
    if (this.block.is_sequential_questions) {
      let found = false;
      for (const question of this.questions) {
        if (question.disabled && !found) {
          question.disabled = false;
          found = true;
        }
      }
    }
  }

  openQuestion(question: QuestionModel, forceAnswered: boolean = false) {
    if (question.disabled && !forceAnswered) {
      this.toastService.error(this.translateService.instant('YOU_NEED_TO_ANSWER_PREVIOUS_FIRST'));
      return;
    }
    if (forceAnswered) {
      question.answered_by_currentuser = true;
    }
    this.currentQuestion = question;
    this.showQuestion = true;
    this.answerGroup.changes.subscribe((comps: QueryList <AnswerGroupChatComponent>) => {
      if (comps.first) {
        this.answerGroupChatComponent = comps.first;
        // this.answerGroupChatComponent.openQuestionComponent();
      }
    });
  }

  goBackToHome() {
    this.router.navigate(['/research']);
  }

}
