<div class="row p-2">
    <div class="mt-4 col-12 d-flex flex-column align-items-center justify-content-center mb-4">
        <div class="d-flex flex-column align-items-center" (click)="file.click()">
            <div class="avatar-image-container" style="background-image: url({{userAvatarHolder}})">
                <div class="placeholder-avatar" *ngIf="!userAvatarHolder">
                    <i class="fal fa-user-alt"></i>
                </div>
            </div>
        <!--<div class="profile-image big">-->
        <!--<img *ngIf="user.avatar_url" [src]="user.avatar_url" alt="" class="avatar-image">-->
        <!-- se non c'è immagine avatar -->
        <!--<i class="fal fa-user-alt" *ngIf="!user.avatar_url" class="avatar-image"></i>-->
        <!--</div>-->

            <button class="btn-sicche btn-secondary btn-icon small mt-n2" ><i class="fal fa-pencil"></i></button>
        
            <input type="file" accept="image/*" (change)="uploadAvatar($event.target.files)" style="visibility: hidden;" #file>
        </div>
    </div>
    <div class="col-12">
        <div class="form-group">
            <input type="text" [(ngModel)]="user.firstname" class="form-control">
            <span class="placeholder">{{'PROFILE.FIRSTNAME' | translate}}</span>
        </div>
    </div>
    <div class="col-12">
        <div class="form-group">
            <input type="text" [(ngModel)]="user.lastname" class="form-control">
            <span class="placeholder">{{'PROFILE.LASTNAME' | translate}}</span>
        </div>
    </div>
    <div class="col-12">
        <div class="form-group">
            <input type="text" [(ngModel)]="user.nickname" class="form-control">
            <span class="placeholder">{{'PROFILE.NICKNAME' | translate}}</span>
        </div>
    </div>
    <div class="col-12">
        <div class="form-group">
            <input inputmode="numeric" pattern="[0-9]*" min="0" type="number" [(ngModel)]="user.telephone" class="form-control hide-input-arrows">
            <span class="placeholder">{{'PROFILE.TELEPHONE' | translate}}</span>
        </div>
    </div>

    <div class="col-12 text-center">
        <app-core-spinner [toggle]="saving"></app-core-spinner>
        <button class="btn-sicche" (click)="submit()">{{'SAVE' | translate}}</button>
    </div>

</div>