import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { User } from '../models/user.model';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { RoomModel } from '../models/room.model';
import { StorageService } from '@app/core/services/storage.service';
import { CountryService } from './country.service';

@Injectable({
  providedIn: 'root'
})
export class RoomsService extends BaseService implements BaseServiceI {

  constructor(public router: Router, public apiService: ApiService, private countryService: CountryService) {
    super(router, apiService, RoomModel);
  }

  getUserRooms() {
    // TODO remove for prod
    const countryUniqid = this.countryService.getCountryCode();
    return this.apiService.get('/manage-rooms/rooms_interview?country_uniqid=' + countryUniqid);
  }
}
