import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { BlockModel } from '@app/shared/models/block.model';
import { AnswerService } from '@app/shared/services/answer.service';
import { TeamModel } from '@app/shared/models/team.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ClosedSingle } from '../models/closed-single';
import { NotificationService } from '@app/shared/services/notification.service';
import { AnswerBaseComponent } from '../answer-base-component';
import { BlockService } from '@app/shared/services/block.service';
import { PreferenceRanking } from '../models/preference-ranking';
import { moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-preference-ranking',
  templateUrl: './preference-ranking.component.html',
  styleUrls: ['./preference-ranking.component.scss']
})

export class PreferenceRankingComponent extends AnswerBaseComponent implements OnInit {

  @Input() questionInput: QuestionModel;
  @Input() blockInput: BlockModel;
  @Input() userInput: TeamModel;
  @Output() closeAnswerInput = new EventEmitter();

  comment: string;
  answer: PreferenceRanking = { rank: [], comment: null };
  cdkLists = [];      // cdk lists ids
  rank = [];          // placeholder for rank rendering
  itemsForDrag = [];  // start as question items, then the list gets modified

  constructor(
    public toastService: ToastrService,
    public translateService: TranslateService,
    public answerService: AnswerService,
    public notificationService: NotificationService,
    public blockService: BlockService
  ) {
    super(toastService, translateService, answerService, notificationService, blockService);
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['link'],
        ['emoji']
      ]
    };
  }

  ngOnInit(): void {
    this.initialize(this.questionInput, this.blockInput, this.userInput, this.closeAnswerInput, this.answer);
    this.rank = Array(parseInt(this.question.question_data.how_many, 10)).fill(0).map((el, i) => i + 1);
    this.cdkLists = this.rank.map(pos => `items-rank-${pos}`);
    this.itemsForDrag = this.question.question_data.randomize_order
      ? [...this.randomizeOrder(this.question.question_data.items)]
      : [...this.question.question_data.items];
    this.answer.rank = Array(parseInt(this.question.question_data.how_many, 10)).fill(null);
  }

  positionStr(pos: number): string {
    return `#${(this.question.question_data.best_worst ? 1 : -1) * pos}`;
  }

  getItemFromId(id: number) {
    return this.question.question_data.items.find((item: any) => item.id === id);
  }

  itemDropRight(event: any, position: number) {
    if (event.previousContainer.id === 'items-list') {
      if (this.answer.rank[position - 1] !== null) {
        this.itemsForDrag.push(this.question.question_data.items.find(el => el.id === this.answer.rank[position - 1]));
      }
      this.answer.rank[position - 1] = this.itemsForDrag[event.previousIndex].id;
      this.itemsForDrag.splice(event.previousIndex, 1);
    }
  }

  itemDropLeft(event: any) {
    if (event.previousContainer.id.startsWith('items-rank')) {
      const rank = parseInt(event.previousContainer.id.replace('items-rank-', ''), 10);
      this.itemsForDrag.push(this.question.question_data.items.find(el => el.id === this.answer.rank[rank - 1]));
      this.answer.rank[rank - 1] = null;
    } else {
      moveItemInArray(this.itemsForDrag, event.previousIndex, event.currentIndex);
    }
  }

  addCommentToAnswer() {
    this.answer.comment = this.comment;
  }

  canSubmit() {
    return (this.answer.rank.every(el => el !== null) && (!this.question.question_data.mandatory_comment || this.answer.comment != null));
  }
}
