
<div *ngIf="question" class="question-content px-3">
    <!-- keep compatibility with old questions (2021-05-19) TODO - remove -->
    <div class="attached-file-container" *ngIf="question.question_data.pdf_files">
        <span class="attached-file" *ngFor="let pdf of question.question_data.pdf_files">
            <a [href]="pdf.pdf_url | privatesrc" target="blank">{{pdf.name}}</a>
        </span>
    </div>
    <div class="attached-image-container" *ngIf="question.question_data.images">
        <img  *ngFor="let img of question.question_data.images" [src]="img.img_url | privatesrc" [alt]="img.name">
    </div>

    <!-- new questions -->
    <div class="attached-file-container" *ngIf="question.question_data.attached_pdf_files">
        <span class="attached-file" *ngFor="let pdf of question.question_data.attached_pdf_files">
            <a [href]="pdf.pdf_url | privatesrc" target="blank">{{pdf.name}}</a>
        </span>
    </div>
    <div class="attached-image-container" *ngIf="question.question_data.attached_images">
        <img *ngFor="let img of question.question_data.attached_images" [src]="img.img_url | privatesrc" [alt]="img.name">
    </div>

    <!-- answer box -->
    <div *ngIf="!question.answered_by_currentuser && (question.is_masked || answerBox || !block.is_room_group)">
        <div class="panel shadow">
            <label class="answer-label" style="width: 100%;">
                <!-- <quill-editor [(ngModel)]="answer.text"
                    [placeholder]="question.question_data.answer_suggestion || ''" [modules]="modules"
                    [styles]="{height: '300px'}"></quill-editor> -->
                    <textarea [placeholder]="question.question_data.answer_suggestion || 'WRITE_YOUR_ANSWER' | translate" [(ngModel)]="answer.text" style="height: 300px;" class="form-control"></textarea>
            </label>
        </div>
        <div>
            <div class="image-upload form-group choose-file-input"
                *ngIf="question.question_data.can_upload_images">
                <label>
                    <span class="placeholder">{{'UPLOAD_IMAGE' | translate}}</span>
                    <input type="file" accept="image/*" (change)="addImage($event.target.files[0], $event)">
                </label>
            </div>

            <div class="image-upload form-group choose-file-input"
                *ngIf="question.question_data.can_upload_videos">
                <label>
                    <span class="placeholder">{{'UPLOAD_VIDEO' | translate}}</span>
                    <input type="file" accept="video/*" id="videoInput" (change)="addVideo($event.target.files, $event)">
                </label>
            </div>


            <div class="container-fluid">
                <div class="row">

                    <div *ngIf="imagePreviews.length > 0" class="col-6 col-md-4">
                        <div *ngFor="let img of imagePreviews">
                            <i class="fal fa-times remove-item-btn" (click)="removeImage(img)"></i>
                            <img [src]="img" class="answer-image">
                        </div>
                    </div>

                    <div *ngIf="videoNamesKey.length > 0" class="col-6 col-md-4">
                        <div *ngFor="let vid of videoNamesKey" class="answer-video-container">
                            <span *ngIf="vid">
                                <i class="fal fa-times remove-item-btn" (click)="removeVideo(vid.key)"></i>
                                <span>{{vid.name}}</span>
                            </span>
                        </div>
                    </div>

                </div>
            </div>

            <div class="my-3 text-right">
                <button *ngIf="!submitting" (click)="submit()" class="btn-primary sticky-footer" [disabled]="!answer.text">{{
                                ((question.minimum_multiple_answers_nr && question.minimum_multiple_answers_nr > 0 && !block.is_room_group)
                                ? 'SHOW_ANSWER'
                                : 'SEND_ANSWER' )| translate
                            }}</button>
                <button *ngIf="submitting" class="btn-primary sticky-footer"><i class="fal fa-spinner-third fa-spin"></i></button>
            </div>
        </div>
    </div>

    <!-- risposte altri utenti -->
    <div class="container-fluid mt-3" *ngIf="question.answered_by_currentuser || (!question.is_masked && block.is_room_group)">
        <div *ngIf="answers">
            <div *ngFor="let a of answers" id="answer_id_{{a.id}}">
                <div class="row py-3 panel shadow notice-content" *ngIf="a.answer_data">
                    <div class="col-2 pl-0 pr-1 text-center" style="max-width: 100px;">
                        <div class="avatar-image-container" [style.background-image]="'url(' + (a.team.avatar_url | privatesrc) + ')'">
                            <div class="placeholder-avatar" *ngIf="!a.team.avatar_url">
                                <i class="fal fa-user-alt"></i>
                            </div>
                        </div>
                        <!-- comments and likes -->
                        <span (click)="like(a)">
                            <small class="bold">
                                <i class="fal fa-heart" [ngClass]="{'fill': a.total_like_to_answer || a.total_like_to_comment || a.currentuser_like_answer}"></i>&nbsp;{{a.total_like_to_answer || a.total_like_to_comment}}
                            </small>
                        </span>
                    </div>
                    <div class="col-10 pr-0">
                        <div class=" user-name mb-2">{{a.team.nickname}}</div>

                        <div *ngIf="a.answer_data.text" style="white-space: pre-wrap;" [innerHTML]="a.answer_data.text"></div>
                        <div *ngIf="a.answer_data.videos && a.answer_data.videos.length > 0">
                            <div class="answer-video-container" *ngFor="let vid of a.answer_data.videos">
                                <video controls class="answer-video">
                                    <source [src]="vid.video_url | privatesrc" type="video/mp4">
                                </video>
                            </div>
                        </div>
                        <div class="mt-2" *ngIf="a.answer_data.images && a.answer_data.images.length > 0">
                            <div class="answer-image-container" *ngFor="let img of a.answer_data.images">
                                <img class="answer-image" [src]="img.image_url | privatesrc">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 px-0">
                        <!-- comments and likes -->
                        <app-questions-comments-like [a]="a" [answers]="answers"></app-questions-comments-like>
                    </div>

                </div>
            </div>
        </div>
        <div *ngIf="answers && answers.length == 0 && block.is_room_group">
            {{'NO_ANSWERS_YET' | translate}}
        </div>
    </div>
</div>

<button *ngIf="!question.answered_by_currentuser && !(!question.answered_by_currentuser && (question.is_masked || answerBox || !block.is_room_group))" (click)="answerBox = true" class="btn-sicche btn-primary sticky-footer">
    <span *ngIf="question.minimum_multiple_answers_nr">
        {{'ADD_NEW_POST' | translate}}<i class="fal fa-reply ml-2 mr-0"></i>
    </span>
    <span *ngIf="!question.minimum_multiple_answers_nr">
        {{'SHOW_ANSWER' | translate}}<i class="fal fa-reply ml-2 mr-0"></i>
    </span>
</button>