<!-- si da per scontato che la faq sia una solo per ora -->
<div class="p-3">
    <div class="row" *ngIf="ready">
        <div class="col-12" *ngFor="let faq of faqs">
            <h1>{{faq.question}}</h1>
            <p [innerHTML]="faq.answer.changingThisBreaksApplicationSecurity">
            </p>
            <span *ngIf="faq.video_embed && faq.video_embed.changingThisBreaksApplicationSecurity" [innerHTML]="faq.vimeo_embed"></span>
        </div>
    </div>
</div>


