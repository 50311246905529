import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

interface VideoPin {
  pin_uniqueid: number;
  time: number;
  comment: string;
  emoticon: any;
}

@Component({
  selector: 'app-pin-on-video',
  templateUrl: './pin-on-video.component.html',
  styleUrls: ['./pin-on-video.component.scss']
})
export class PinOnVideoComponent implements OnInit {

  @Input() media: any;
  @Input() requireEmoticons = false;
  @Input() form: UntypedFormGroup;
  @Input() pinsControlName = 'pins';
  @Input() inputPins: VideoPin[];
  @Output() pinsChange = new EventEmitter<VideoPin[]>();

  @ViewChild('videoplayer') videoplayer: ElementRef;
  videoLen: number;
  currentPinUniqueid = 0;
  selectedPin: VideoPin = null;   // for visualization
  formNewPin: UntypedFormGroup;
  showForm = false;

  get pins(): VideoPin[] {
    return this.form
      ? this.form.controls[this.pinsControlName].value
      : this.inputPins;
  }

  constructor(
    public toastService: ToastrService,
    public translateService: TranslateService,
  ) { }


  ngOnInit(): void {
    if (this.form) {
      this.formNewPin = new UntypedFormGroup({
        time: new UntypedFormControl('', Validators.required),
        emoticon: new UntypedFormControl('', this.requireEmoticons ? Validators.required : null),
        comment: new UntypedFormControl('', Validators.required)
      });
      this.currentPinUniqueid = this.pins.reduce((max, pin) => pin.pin_uniqueid > max ? pin.pin_uniqueid : max, 0);
    }
  }

  onMetadataLoad(event: any, videoplayer: any) {
    this.videoLen = videoplayer.duration;
  }

  pinPlace() {
    this.videoplayer.nativeElement.pause();
    this.formNewPin.patchValue({ time: this.videoplayer.nativeElement.currentTime });
  }

  pinConfirm() {
    if (!this.formNewPin.valid) { return; }
    this.currentPinUniqueid++;
    (this.form.controls[this.pinsControlName] as UntypedFormArray).push(new UntypedFormGroup({
      pin_uniqueid: new UntypedFormControl(this.currentPinUniqueid),
      time: new UntypedFormControl(this.formNewPin.value.time),
      comment: new UntypedFormControl(this.formNewPin.value.comment),
      emoticon: new UntypedFormControl(this.formNewPin.value.emoticon)
    }));
    this.formNewPin.patchValue({ emoticon: '', comment: '' });
    this.videoplayer.nativeElement.play();
    this.pinsChange.next(this.pins);
  }

  // returns the percentage distance from left inside the pins-bar
  getPinDistance(pin: VideoPin): string {
    return `${pin.time * 100 / this.videoLen}%`;
  }

  pinSelect(pin: VideoPin) {
    this.selectedPin = pin;
    this.videoplayer.nativeElement.pause();
    this.videoplayer.nativeElement.currentTime = pin.time;
  }

  pinDelete(pin: VideoPin) {
    (this.form.controls[this.pinsControlName] as UntypedFormArray).removeAt(
      this.pins.findIndex(p => p.pin_uniqueid === pin.pin_uniqueid)
    );
    this.selectedPin = null;
    this.pinsChange.next(this.pins);
  }
}
