<div>
    <div *ngIf="question">
        <div class="question-content px-3">
            <!-- answer box -->
            <div *ngIf="!question.answered_by_currentuser && (question.is_masked || answerBox || !block.is_room_group)">
                <div class="py-3">
                    <!-- answer custom -->
                    <div class="w-100 mb-0">
                        <div class="col-12 panel shadow mt-0 h-100">
                            <ng-container *ngIf="currentStep !== null && formSteps[currentStep]">
                                <ng-container *ngIf="formSteps[currentStep].id === 'intr'">
                                    <ng-container *ngTemplateOutlet="formIntro"></ng-container>
                                </ng-container>
                                <ng-container *ngIf="formSteps[currentStep].id === 'view'">
                                    <ng-container *ngTemplateOutlet="formMediaView"></ng-container>
                                </ng-container>
                                <ng-container *ngIf="formSteps[currentStep].id === 'rate'">
                                    <ng-container *ngTemplateOutlet="formRating"></ng-container>
                                </ng-container>
                                <ng-container *ngIf="formSteps[currentStep].id === 'comm'">
                                    <ng-container *ngTemplateOutlet="formComment"></ng-container>
                                </ng-container>
                                <ng-container *ngIf="formSteps[currentStep].id === 'pins'">
                                    <ng-container *ngTemplateOutlet="formPins"></ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="mt-3 text-right">
                        <button *ngIf="formSteps[currentStep].couldNext" class="btn-primary sticky-footer" [disabled]="!formSteps[currentStep].canNext()" (click)="nextStep()">
                            {{ formSteps[currentStep].nextTxt }}
                            <i class="fas fa-arrow-right ml-2 mr-0"></i>
                        </button>
                        <button *ngIf="currentStep === formSteps.length - 1 && !submitting" (click)="submit()" class="btn-primary sticky-footer" [disabled]="!form.valid">{{
                                ((question.minimum_multiple_answers_nr && question.minimum_multiple_answers_nr > 0 && !block.is_room_group)
                                ? 'SHOW_ANSWER'
                                : 'SEND_ANSWER' )| translate
                            }}</button>
                        <button *ngIf="currentStep === formSteps.length - 1 && submitting" class="btn-primary sticky-footer"><i class="fal fa-spinner-third fa-spin"></i></button>
                    </div>
                </div>
            </div>

            <!-- risposte altri utenti -->
            <div class="container-fluid mt-3" *ngIf="question.answered_by_currentuser || (!question.is_masked && block.is_room_group)">

                <div *ngIf="answers">
                    <div *ngFor="let a of answers" id="answer_id_{{a.id}}">
                        <div  class="panel shadow m-0 h-100 d-flex flex-column mt-3" id="answer_id_{{a.id}}">
                            <div class="d-flex p-0">
                                <div class="col-2 col-xl-1 px-1 text-center">
                                    <!--<img [src]="(a.team.avatar_url | privatesrc) || 'https://www.w3schools.com/howto/img_avatar.png'" class="w-100 avatar mb-3">-->
                                    <div class="avatar-image-container" [style.background-image]="'url(' + (a.team.avatar_url | privatesrc) + ')'">
                                        <div class="placeholder-avatar" *ngIf="!a.team.avatar_url">
                                            <i class="fal fa-user-alt"></i>
                                        </div>
                                    </div>
                                    <span (click)="like(a)"><small class="bold"><i class="fal fa-heart" [ngClass]="{'fill': a.total_like_to_answer || a.total_like_to_comment || a.currentuser_like_answer}"></i>&nbsp;{{a.total_like_to_answer || a.total_like_to_comment}}</small></span>
                                </div>
                                <!-- custom -->
                                <div [className]="block.is_room_group ? 'col-10' : 'col-12'">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class=" user-name mb-2">{{a.team.nickname || (a.team.firstname + ' ' + a.team.lastname)}}</div>
                                        </div>
                                    </div>

                                    <div>
                                        <div *ngIf="a.answer_data.rating">
                                            <b>{{ 'RATING' | translate }}</b>: {{ a.answer_data.rating }}
                                        </div>
                                        <div *ngIf="a.answer_data.comment">
                                            <b>{{ 'COMMENT' | translate }}</b>: {{ a.answer_data.comment }}
                                        </div>
                                        <div *ngIf="question.question_data.require_pins">
                                            {{ 'DETAILS' | translate }}:
                                            <app-pin-on-video
                                                *ngIf="question.question_data.media_type === 'video'"
                                                [media]="question.question_data.videos[0]"
                                                [inputPins]="a.answer_data.pins">
                                            </app-pin-on-video>
                                            <app-pin-on-image
                                                *ngIf="question.question_data.media_type === 'image'"
                                                [media]="question.question_data.image"
                                                [inputPins]="a.answer_data.pins">
                                            </app-pin-on-image>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- comments and likes -->
                            <app-questions-comments-like [a]="a"  [answers]="answers"></app-questions-comments-like>

                        </div>
                    </div>
                </div>
                <div *ngIf="answers && answers.length == 0">
                    {{'NO_ANSWERS_YET' | translate}}
                </div>

                <button *ngIf="!question.answered_by_currentuser && !(!question.answered_by_currentuser && (question.is_masked || answerBox || !block.is_room_group))" (click)="answerBox = true" class="btn-sicche btn-primary sticky-footer">
                   <span *ngIf="question.minimum_multiple_answers_nr && block.is_room_group">
                        {{'ADD_NEW_POST' | translate}}<i class="fal fa-reply ml-2 mr-0"></i>
                    </span>
                    <span *ngIf="!question.minimum_multiple_answers_nr || block.is_room_group">
                        {{'SHOW_ANSWER' | translate}}<i class="fal fa-reply ml-2 mr-0"></i>
                    </span>
                </button>

            </div>
        </div>
    </div>
</div>


<ng-template #formIntro id="formIntro">
    <div class="intro">
        <ng-container *ngIf="question.question_data.media_type === 'video'; else imageIntro">
            <p *ngIf="question.question_data.video_max_views">
                {{ 'PIN_ON_VIDEO_INTRO' | translate: { times: question.question_data.video_max_views } }}
            </p>
            <p *ngIf="!question.question_data.video_max_views">
                {{ 'PIN_ON_VIDEO_INTRO_NO_LIMITS' | translate }}
            </p>
        </ng-container>
        <ng-template #imageIntro>
            <p *ngIf="question.question_data.image_max_seconds">
                {{ 'PIN_ON_IMAGE_INTRO' | translate: { seconds: question.question_data.image_max_seconds } }}
            </p>
            <p *ngIf="!question.question_data.image_max_seconds">
                {{ 'PIN_ON_IMAGE_INTRO_NO_LIMITS' | translate }}
            </p>
        </ng-template>
    </div>
</ng-template>

<ng-template #formMediaView>
    <div class="text-center">
        <ng-container *ngIf="question.question_data.media_type === 'video'; else imageView">
            <div>
                <div *ngIf="question.question_data.video_max_views === 1 || question.question_data.video_max_views === 2; else unlimitedVideo" class="video-container" [class.fullscreen]="fullscreen">
                    <video
                        #viewVideo
                        [controls]="false"
                        preload="metadata"
                        webkitallowfullscreen mozallowfullscreen allowfullscreen
                        webkit-playsinline playsinline
                        class="answer-video"
                        (timeupdate)="updateVideoCurrentTime($event)"
                        (ended)="videoEnd($event)">
                        <source [src]="question.question_data.videos[0].video_url + '#t=0.1'">
                    </video>

                    <div class="d-flex justify-content-between align-items-center custom-video-controls p-2">
                        <div>
                            <button *ngIf="!isPlaying(viewVideo)" class="btn-sicche video-controllers mx-3" (click)="viewVideo.play()">
                                <i *ngIf="videoPlays === 0" class="fal fa-play m-0"></i>
                                <span *ngIf="videoPlays > 0">
                                    <i class="fas fa-redo"></i>
                                    {{ 'SEE_AGAIN' | translate }}
                                </span>
                            </button>
                            <button *ngIf="isPlaying(viewVideo)" class="btn-sicche video-controllers mx-3" (click)="viewVideo.pause()">
                                <i class="fal fa-pause m-0"></i>
                            </button>
                        </div>
                        <div [class.text-white]="fullscreen">
                            {{ num(videoCurrentTime | number:'0.0') | secondsToTime}} / {{ num(viewVideo.duration | number:'0.0') | secondsToTime }}
                        </div>
                        <button class="btn-sicche video-controllers mx-3" (click)="goFullScreen(viewVideo)">
                            <i *ngIf="!fullscreen" class="fas fa-expand m-0"></i>
                            <i *ngIf="fullscreen" class="fas fa-compress m-0"></i>
                        </button>
                    </div>
                </div>

                <ng-template #unlimitedVideo>
                    <div class="video-container" [class.fullscreen]="fullscreen" *ngFor="let video of question.question_data.videos">
                        <video
                            #viewVideo
                            controls
                            preload="metadata"
                            class="answer-video"
                            (ended)="videoEnd($event)">
                            <source [src]="video.video_url + '#t=0.1'" type="video/mp4">
                        </video>
                    </div>
                </ng-template>
            </div>
        </ng-container>
        <ng-template #imageView>
            <div id="pin-answer-image-wrap" class="d-flex flex-column">
                <div *ngIf="question.question_data.image_max_seconds" class="timer text-right p-2">{{ imageTimer | secondsToTime }}</div>
                <div *ngIf="!question.question_data.image_max_seconds" class="timer text-center p-2">{{ 'PIN_ON_IMAGE_PROCEED_INSTRUCTIONS' | translate }}</div>
                <div class="d-flex flex-grow-1 justify-content-center align-items-center">
                    <img #viewImage
                        [src]="question.question_data.image.image_url | privatesrc"
                        alt=""
                        (load)="imageStart()"
                        (click)="imageNext()">
                </div>
            </div>
        </ng-template>
    </div>
</ng-template>

<ng-template #formPins>
    <div class="text-center">
        <p>{{ (question.question_data.pins_question ? question.question_data.pins_question : 'PIN_ON_MEDIA_DEFAULT_QUESTION') | translate}}</p>
        <p class="font-weight-bold">
            {{ 'MIN_PIN_REQ' | translate: { min_num_pins: question.question_data.pins_min_num } }}
        </p>
    </div>
    <app-pin-on-video
        *ngIf="question.question_data.media_type === 'video'"
        [requireEmoticons]="question.question_data.pins_type === 'text_emoticon'"
        [media]="question.question_data.videos[0]"
        [form]="form"
        [pinsControlName]="'pins'">
    </app-pin-on-video>
    <app-pin-on-image
        *ngIf="question.question_data.media_type === 'image'"
        [requireEmoticons]="question.question_data.pins_type === 'text_emoticon'"
        [media]="question.question_data.image"
        [form]="form"
        [pinsControlName]="'pins'">
    </app-pin-on-image>
</ng-template>

<ng-template #formRating>
    <div class="d-flex align-items-center justify-content-center">
        <div>
            <div class="text-center">
                <p *ngIf="question.question_data.rating_question">{{ question.question_data.rating_question }}</p>
                <p *ngIf="!question.question_data.rating_question">{{ 'RATE_MEDIA_DEFAULT_QUESTION' | translate }}</p>
            </div>
            <div
                class="d-inline-block text-center rating-container"
                [class.rating-17]="this.question.question_data.rating_range === '1_7'"
                [class.rating-010]="this.question.question_data.rating_range === '0_10'"
                [formGroup]="form">
                <label *ngFor="let rating of ratings" class="rating-radio-label" [for]="'media-rating-' + rating">
                    <input
                        formControlName="rating"
                        name="rating"
                        type="radio"
                        [id]="'media-rating-' + rating"
                        [value]="rating">
                    <p>{{ rating }}</p>
                </label>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #formComment>
    <div>
        <div class="d-flex justify-content-center">
            <p *ngIf="question.question_data.comment_question">{{ question.question_data.comment_question }}</p>
            <p *ngIf="!question.question_data.comment_question">{{ 'COMMENT_MEDIA_DEFAULT_QUESTION' | translate }}</p>
        </div>
        <div class="mb-0 w-100">
            <div class="form-group required mb-0" [formGroup]="form">
                <textarea formControlName="comment" id="media-comment" class="form-control" style="height: 100px !important;"></textarea>
            </div>
        </div>
    </div>
</ng-template>