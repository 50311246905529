import { Component, OnInit, Input } from '@angular/core';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { UserService } from '@app/shared/services/user.service';
import { AuthService } from '@app/core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomValidators } from '@app/core/validators/validators';
import { ResponseModel } from '@app/core/models/response.model';
import { Mode } from '../../../../../../../setup';
import { CoreLayoutService } from '@app/core/services/layout.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends CoreBaseComponent implements OnInit {

  @Input() token: string;
  form: UntypedFormGroup = null;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private translateService: TranslateService,
    public coreLayoutService: CoreLayoutService
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      token: new UntypedFormControl(this.token, Validators.required),
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(6)
      ]),
      repeatpassword: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(6)
      ])
    }, {
      validator: CustomValidators.Match('password', 'repeatpassword')
    });
  }

  submit() {
    this.operation('requesting').reset().isSubmitting();
    const data = this.form.getRawValue();
    this.authService.resetPassword(data).subscribe((res: ResponseModel) => {
      this.operation('requesting').isSuccess(res.getErrors());
    }, (error) => {
      this.operation('requesting').isFailed(error.errors);
    });
  }

}
