<div class="bg-orange vh-100 d-flex align-items-center">
    <div class="container" *ngIf="user">
        <div class="row">
            <div class="offset-1 offset-md-3 offset-xl-4 col-10 col-md-6 col-xl-4 text-center mb-4">
                <h1 class="h2 black mb-3">{{'WELCOME' | translate}}</h1>
                <h2 class="h4 mb-3">{{'CHOOSE_YOUR_NICKNAME' | translate}}</h2>
                <p class="d-flex justify-content-center">{{'CHOOSE_YOUR_NICKNAME_SUBTITLE' | translate}}</p>
            </div>
            <div class="offset-1 offset-md-3 offset-xl-4 col-10 col-md-6 col-xl-4 mt-3 p-3">
                <div class="form-group required">
                    <input class="form-control"  type="text"  [readOnly]="realName"[(ngModel)]="user.nickname">
                    <span class="placeholder">{{'NICKNAME' | translate}}</span>
                </div>
                <!--<input type="text"  [readOnly]="realName"[(ngModel)]="user.nickname" >-->
                <div>
                    <div class="check-radio-box" *ngIf="user && user.firstname">
                        <input id="realname" type="checkbox" (click)="useRealName()" [checked]="realName">
                        <label for="realname" class="mb-0 ml-2">{{'USE_MY_REAL_NAME' | translate}}</label>
                    </div>
                </div>

                <div class="mt-5 d-flex flex-column align-items-center justify-content-center text-center">
                    <label class="mb-2 ml-2">{{'ONBOARDING_IMAGE_LABEL' | translate}}</label>
                    <div  (click)="file.click()" class="avatar-image-container pointer" style="background-image: url({{avatar_url_holder}})">
                        <div class="placeholder-avatar" *ngIf="!avatar_url_holder">
                            <i class="fal fa-user-alt"></i>
                        </div>
                    </div>

                        <!-- <img style="width: 50%;" *ngIf="user.avatar_url" [src]="user.avatar_url" alt="" class="avatar-image"> -->
                        <!-- se non c'è immagine avatar -->
                        <i class="fal fa-pencil mt-n2" *ngIf="!user.avatar_url" class="avatar-image"></i>


                    <button (click)="file.click()" class="btn-sicche btn-icon small mt-n2" ><i class="fal fa-pencil"></i></button>
                    <input type="file" accept="image/*" (change)="uploadAvatar($event.target.files)" style="visibility: hidden;" #file>

                    <div class="text-center">
                        <button [disabled]="!user.nickname || !user.avatar_url" class="btn-sicche" (click)="submit()">
                            <i class="fal fa-arrow-right"></i>{{'CONTINUE' | translate}}
                        </button>
                    </div>


            </div>
        </div>
    </div>
</div>
