import { Component, OnInit, Sanitizer } from '@angular/core';
import { Router } from '@angular/router';
import { TeamService } from '@app/shared/services/team.service';
import { TeamModel } from '@app/shared/models/team.model';
import { ResponseModel } from '@app/core/models/response.model';
import { DomSanitizer } from '@angular/platform-browser';
import { PrivateSrcPipe } from '@app/core/pipes/private-src.pipe';

@Component({
  selector: 'app-nickname-selection',
  templateUrl: './nickname-selection.component.html',
  styleUrls: ['./nickname-selection.component.scss']
})
export class NicknameSelectionComponent implements OnInit {

  constructor(
    private router: Router,
    private teamService: TeamService,
    private privateSrcPipe: PrivateSrcPipe
  ) { }

  user: TeamModel;
  realName = false;
  avatar_url_holder = null;


  ngOnInit(): void {
    this.teamService.getCurrentUserTeam().subscribe((res: ResponseModel) => {
      this.user = res.getData();
      if (this.user.avatar_url) {
        this.user.avatar_url = this.privateSrcPipe.transform(this.user.avatar_url);
      }
      this.teamService.currentTeam = this.user;
      console.log('user', this.user);
    }, err => console.log(err));
  }


  uploadAvatar(files: FileList) {
    const reader = new FileReader();
    let newImage;
    reader.onload = (e) => {
      newImage = e.target.result;
      this.user.avatar = newImage;
      this.user.avatar_url = newImage;
      this.avatar_url_holder = newImage;
    };
    reader.readAsDataURL(files.item(0));
  }

  useRealName() {
    if (!this.realName) {
      this.user.nickname = this.user.firstname;
    }
    this.realName = !this.realName;
  }

  submit() {
    this.user.avatar_url = '';
    // this.user.has_onboarded = true;
    this.teamService.updateCurrentUserTeam(this.user).subscribe((res: ResponseModel) => {
      this.goToPasswordSelection();
    });
    // this.goToPasswordSelection();
  }

  goToPasswordSelection() {
    this.router.navigateByUrl('/onboarding/password-selection');
  }

}
