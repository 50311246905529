import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { CountryModel } from '@app/shared/models/country.model';
import { CountryService } from '@app/shared/services/country.service';
import { ResponseModel } from '@app/core/models/response.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { TeamService } from '@app/shared/services/team.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(
    private router: Router,
    private countryService: CountryService,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private teamService: TeamService,
    @Inject(DOCUMENT) document
  ) { }

  country: CountryModel;
  scrolledDown = true;
  accepted = false;

  ngOnInit(): void {
    this.countryService.getCurrentCountry().subscribe((res: ResponseModel) => {
      this.country = res.getData();

      if (!this.country.privacy_html) {
        this.router.navigateByUrl('/onboarding/summary');
      }
    });
  }

  accept() {
    this.accepted = !this.accepted;
  }

  goToPrivacySummary() {
    if (this.scrolledDown && this.accepted) {

      this.teamService.getCurrentUserTeam(true).subscribe((res: ResponseModel) => {
        const user = res.getData();
        user.has_onboarded = user.nickname ? true : false;
        this.teamService.updateCurrentUserTeam(user).subscribe(() => {
          this.router.navigateByUrl('/onboarding/summary');
        });
      }, err => console.log(err));

    } else {
      this.toastService.error(this.translateService.instant('READ_PRIVACY_FIRST'));
    }
  }

  rejectPrivacy() {
    this.teamService.getCurrentUserTeam().subscribe((res: ResponseModel) => {
      const user = res.getData();
      user.is_locker = true;
      this.teamService.updateCurrentUserTeam(user).subscribe(() => {
        this.router.navigate(['login']);
      });
    }, err => console.log(err));
  }

}
