import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { BlockModel } from '@app/shared/models/block.model';
import { TeamModel } from '@app/shared/models/team.model';
import { AnswerService } from '@app/shared/services/answer.service';
import { BlockService } from '@app/shared/services/block.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AnswerBaseComponent } from '../answer-base-component';
import { VideoAnswer } from '../models/video-answer';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-video-answer',
  templateUrl: './video-answer.component.html',
  styleUrls: ['./video-answer.component.scss']
})
export class VideoAnswerComponent extends AnswerBaseComponent implements OnInit {

  @Input() questionInput: QuestionModel;
  @Input() blockInput: BlockModel;
  @Input() userInput: TeamModel;
  @Output() closeAnswerInput = new EventEmitter();

  answer: VideoAnswer = { videos: [] };


  constructor(
    public toastService: ToastrService,
    public translateService: TranslateService,
    public answerService: AnswerService,
    public notificationService: NotificationService,
    public blockService: BlockService,
    private sanitizer: DomSanitizer
    ) {
    super(toastService, translateService, answerService, notificationService, blockService);
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['link'],
        ['emoji']
      ]
    };
  }

  ngOnInit(): void {
    this.initialize(this.questionInput, this.blockInput, this.userInput, this.closeAnswerInput, this.answer);
  }

  previewVideos() {
    this.videoPreviews = [];
    for (const vid of this.answer.videos) {
      if (vid.video) {
        this.videoPreviews = [...this.videoPreviews, this.sanitizer.bypassSecurityTrustUrl(vid.video)];
      } else {
        this.videoPreviews = [...this.videoPreviews, vid.video_url];
      }
    }
  }

}
