import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { User } from '../models/user.model';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { RoomModel } from '../models/room.model';
import { BlockModel } from '../models/block.model';
import { CountryService } from './country.service';
import { DatetimeToDbPipe } from '@app/core/pipes/datetime-to-db.pipe';

@Injectable({
  providedIn: 'root'
})
export class BlockService extends BaseService implements BaseServiceI {

  constructor(
    public router: Router,
    public apiService: ApiService,
    private dateToDb: DatetimeToDbPipe,
    private countryService: CountryService
  ) {
    super(router, apiService, BlockModel);
  }

  sessionBlocks$: BlockModel[];
  currentBlock$: BlockModel;
  currentDay$: any;
  redirectToBlock$: {
    date: Date,
    blockid: number
  };

  set currentDay(day: any) {
    this.currentDay$ = day;
  }

  get currentDay(): any {
    return this.currentDay$;
  }

  set redirectToBlock(redirect: any) {
    this.redirectToBlock$ = redirect;
  }

  get redirectToBlock(): any {
    return this.redirectToBlock$;
  }

  getBlocksByRoomid(roomid: number) {
      return this.get('block', {roomid});
  }

  getBlocksByDate(date: string) {
    const country_uniqid = this.countryService.getCountryCode();
    const parsedDate = this.dateToDb.transform(date);
    console.log('getting block by ', parsedDate)
    return this.get('interviewed-blocks', {country_uniqid, date: parsedDate, include_stats: true});
  }

  set sessionBlock(blocks: BlockModel[]) {
    this.sessionBlocks$ = blocks;
  }

  get sessionBlock() {
    return this.sessionBlocks$;
  }

  set currentBlock(block: BlockModel) {
    this.currentBlock$ = block;
  }

  get currentBlock() {
    return this.currentBlock$;
  }

  getSessionBlockById(blockid) {
    return this.sessionBlock.find(b => b.id === blockid);
  }

  getBlockById(blockid) {
    return this.get(`blocks/${blockid}`);
  }

  getBlockCompletionByCountryUniqid(country_uniqid: string) {
    return this.get('interviewed-blocks-completion', {country_uniqid});
  }

  getFirstBlockWithMissingAnswers(country_uniqid: string) {
    return this.get('interviewed-first-block-with-missing-answers', {country_uniqid});
  }

  getActiveDays(country_uniqid: string) {
    return this.get('active-days', {country_uniqid});
  }

}
