import { Role } from './role.model';
import { BaseModel } from '@app/core/models/base';

/**
 * User model class
 */

export class User extends BaseModel {

    id: string;
    username: string;
    password: string;
    mail: string;
    firstname: string;
    lastname: string;
    usertype: string;

    static getResourceName() {
        return 'users';
    }

    getFullName() {
        return `${this.firstname} ${this.lastname}`;
    }

}
