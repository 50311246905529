import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { BlockModel } from '@app/shared/models/block.model';
import { TeamModel } from '@app/shared/models/team.model';
import { TranslateService } from '@ngx-translate/core';
import { AnswerService } from '@app/shared/services/answer.service';
import { BlockService } from '@app/shared/services/block.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { ToastrService } from 'ngx-toastr';
import { AnswerBaseComponent } from '../answer-base-component';
import { ImageAssociation } from '../models/image-association';

@Component({
  selector: 'app-image-association',
  templateUrl: './image-association.component.html',
  styleUrls: ['./image-association.component.scss']
})
export class ImageAssociationComponent extends AnswerBaseComponent implements OnInit {

  @Input() questionInput: QuestionModel;
  @Input() blockInput: BlockModel;
  @Input() userInput: TeamModel;
  @Output() closeAnswerInput = new EventEmitter();

  images: any = []; // for random order rendering
  answer: ImageAssociation[] = [];
  colClass = '';
  addedMandatoryComments = false;
  showAddCommentToChoices = false;

  constructor(
    public toastService: ToastrService,
    public translateService: TranslateService,
    public answerService: AnswerService,
    public notificationService: NotificationService,
    public blockService: BlockService,
  ) {
    super(toastService, translateService, answerService, notificationService, blockService);
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['link'],
        ['emoji']
      ]
    };
  }

  ngOnInit(): void {
    this.initialize(this.questionInput, this.blockInput, this.userInput, this.closeAnswerInput, this.answer);
    switch (this.question.question_data.dimensions) {
      case 1: this.colClass = 'col-12'; break;
      case 2: this.colClass = 'col-6'; break;
      case 3: this.colClass = 'col-4'; break;
      default: this.colClass = 'col-4'; break;
    }
    if (this.question.question_data.randomize_order) {
      this.question.question_data.images = this.randomizeOrder(this.question.question_data.images);
    }
  }

  getAnswerFromId(id: number) {
    return this.question.question_data.images.find((img: any) => img.answer_id === id);
  }

  checkAnswer(id: number) {
    if (this.isChecked(id)) {
      this.answer = this.answer.filter(a => a.optionid !== id);
    } else {
      this.answer = [...this.answer, { optionid: id, comment: null }];
    }
  }

  isChecked(id: number) {
    return !!this.answer.find(a => a.optionid === id);
  }

  cantSubmit() {
    return (
      this.answer.length <= 0 || (
        this.showAddCommentToChoices && this.hasNotCommentedAllChoices()
      )
    )
  }

  hasNotCommentedAllChoices() {
    return this.answer.find(a => !a.comment)
  }

  next() {
    if (this.question.question_data.mandatory_comment && this.hasNotCommentedAllChoices()) {
      this.showAddCommentToChoices = true;
    } else {
      this.showAddCommentToChoices = false;
      this.submit()
    }
  }
}
