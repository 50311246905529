import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { User } from '../models/user.model';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { ResponseModel } from '@app/core/models/response.model';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService implements BaseServiceI {

  constructor(public router: Router, public apiService: ApiService) {
    super(router, apiService, User);
  }

  

}
