import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { FaqModel } from '../models/faq.model';
import { CountryService } from './country.service';


@Injectable({
  providedIn: 'root'
})
export class HelpService extends BaseService implements BaseServiceI {

  constructor(public router: Router, public apiService: ApiService, private http: HttpClient, private countryService: CountryService) {
    super(router, apiService, FaqModel);
  }

  getFaq() {
    let lang_code = this.countryService.getSessionCountry().language_code
    // temp, finché non caricano anche in altre lingue
    return this.apiService.get('/api/help/faq-interviewed?lang_code=' + lang_code, null, environment.websiteUrl);
  }

}
