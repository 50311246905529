import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TeamService } from '@app/shared/services/team.service';
import { TeamModel } from '@app/shared/models/team.model';
import { ResponseModel } from '@app/core/models/response.model';
import { CountryService } from '@app/shared/services/country.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  meridian = false

  constructor(
    private router: Router,
    private teamService: TeamService,
    private countryService: CountryService
  ) { }

  user: TeamModel;

  async ngOnInit() {
    const data: ResponseModel = await this.countryService.getCurrentCountry().toPromise();
    const country = data.getData();
    this.countryService.currentCountry = country;
    this.meridian = country.country_code !== 'it'

    this.teamService.getCurrentUserTeam(true).subscribe((res: ResponseModel) => {
      this.user = res.getData();
      console.log({user: this.user});
    }, err => console.log(err));
  }

  goToResearch() {
    // Has onboarded//
    this.user.has_onboarded = true;
    this.teamService.updateCurrentUserTeam(this.user).subscribe((res: ResponseModel) => {
      this.router.navigate(['research']);
    });
  }


}
