<div class="card">
    <div class="image">
        <img src="../../../../../assets/images/blogimage.png" class="img-fluid" height="100px">
    </div>
    <div class="description py-3">
        <h3>{{ block.title }}</h3>
        <div class="row">
            <div class="col-8">
                <p *ngIf="block.is_room_group"><i class="fal fa-user"></i> {{ "ROOM.INDIVIDUAL_INTERVIEW" | translate }}</p>
                <p *ngIf="!block.is_room_group"><i class="fal fa-user"></i> {{ "ROOM.GROUP_INTERVIEW" | translate }}</p>
                <p><i class="fal fa-clock"></i>{{ (block.datetime_start | date) + ' ' + (block.datetime_end | date) }}</p>
                <p *ngIf="block.has_geolocation"><i class="fal fa-map-marker-alt"></i>{{ block.geolocation_address }}</p>
            </div>
            <div class="col-4 row justify-content-center align-items-center">
                <h2>18/20</h2>
                <h3>{{ "ROOM.MISSING_ANSWERS" | translate }}</h3>
            </div>
        </div>
    </div>
</div>
