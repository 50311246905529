import { QuestionModel } from '@app/shared/models/question.model';
import { AnswerModel } from '@app/shared/models/answer.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageAssociation } from '../../models/image-association';

@Component({
  selector: 'app-choices-comments',
  templateUrl: './choices-comments.component.html',
  styleUrls: ['./choices-comments.component.scss']
})
export class ChoicesCommentsComponent implements OnInit {

  @Input() answer: ImageAssociation[];
  @Input() question: QuestionModel;

  constructor() { }

  ngOnInit(): void {
    console.log(this.answer, this.question);
  }

  getImageUrlByAnswer(answer: ImageAssociation): string {
    return this.question.question_data.images.find(img => img.answer_id === answer.optionid).image_url
  }

}
