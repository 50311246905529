import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { BlockModel } from '@app/shared/models/block.model';
import { ResponseModel } from '@app/core/models/response.model';
import { AnswerService } from '@app/shared/services/answer.service';
import { TeamModel } from '@app/shared/models/team.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AnswerModel } from '@app/shared/models/answer.model';
import { OpenAnswer } from '../models/open-answer';
import { CommentModel } from '@app/shared/models/comment.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ClosedSingle } from '../models/closed-single';
import { NotificationService } from '@app/shared/services/notification.service';
import { AnswerBaseComponent } from '../answer-base-component';
import { BlockService } from '@app/shared/services/block.service';

@Component({
  selector: 'app-closed-single',
  templateUrl: './closed-single.component.html',
  styleUrls: ['./closed-single.component.scss']
})
export class ClosedSingleComponent extends AnswerBaseComponent implements OnInit {

  @Input() questionInput: QuestionModel;
  @Input() blockInput: BlockModel;
  @Input() userInput: TeamModel;
  @Output() closeAnswerInput = new EventEmitter();

  constructor(
    public toastService: ToastrService,
    public translateService: TranslateService,
    public answerService: AnswerService,
    public notificationService: NotificationService,
    public blockService: BlockService
  ) {
    super(toastService, translateService, answerService, notificationService, blockService);
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['link'],
        (this.isMobile() ? [] : ['emoji'])
      ]
    };
  }

  currentAnswerComment: any = {};


  answer: ClosedSingle = { optionid: null, comment: null };


  ngOnInit(): void {
    this.initialize(this.questionInput, this.blockInput, this.userInput, this.closeAnswerInput, this.answer);
  }

  answerSelected(answer: AnswerModel, id) {
    if (answer.answer_data.optionid === id) {
      return true;
    }
    return false;
  }

  isChecked(id: number): boolean {
    if (this.answer.optionid === id) {
      return true;
    }
    return false;
  }

  checkAnswer(id: number) {
    this.answer.optionid = (this.answer.optionid && this.answer.optionid === id  ? null : id);
  }

  addCommentToAnswer(id: number) {
    this.answer.comment = this.currentAnswerComment[id];
  }

}
