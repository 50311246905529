<div *ngIf="invalidBrowser" class="container">
    <div class="row">
        <div class="col-md-6 offset-md-3 p-3 mt-5">
            {{'INVALID_BROWSER_IE' | translate: {link: currentLink} }}
        </div>
    </div>
</div>
<div class="container" *ngIf="!invalidBrowser">
    <div class="row">
        <div class="col-12 text-center mt-3">
            <app-shared-spinner [toggle]="true"></app-shared-spinner>
        </div>
    </div>
</div>