import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { RoomsService } from '@app/shared/services/rooms.service';
import { BlockService } from '@app/shared/services/block.service';
import { RoomModel } from '@app/shared/models/room.model';
import { ResponseModel } from '@app/core/models/response.model';
import { AuthService } from '@app/core/services/auth.service';
import { DatePipe } from '@angular/common';
import { BlockModel } from '@app/shared/models/block.model';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { QuestionListComponent } from './block/question-list/question-list.component';
import { TeamModel } from '@app/shared/models/team.model';
import { ProfileSidebarComponent } from '../profile-sidebar/profile-sidebar.component';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { CountryModel } from '@app/shared/models/country.model';
import { CountryService } from '@app/shared/services/country.service';
import { environment } from '../../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DateToLocaleStringPipe } from '@app/core/pipes/date-to-locale-string.pipe';
import { DatetimeToDbPipe } from '@app/core/pipes/datetime-to-db.pipe';
import { TeamService } from '@app/shared/services/team.service';
import { Router } from '@angular/router';
import { NotificationService } from '@app/shared/services/notification.service';
import { NoticeModel } from '@app/shared/models/notice.model';
import { NotificationListComponent } from '../components/notification-list/notification-list.component';

@Component({
  selector: 'app-research',
  templateUrl: './research.component.html',
  styleUrls: ['./research.component.scss']
})
export class ResearchComponent extends CoreBaseComponent implements OnInit {

  @ViewChild('ds') ds: ElementRef;

  rooms: RoomModel[];
  room: RoomModel;
  activeDays = [];
  days = [];
  day;
  allBlocks :BlockModel[];
  blocks: BlockModel[];
  diaryBlocks: BlockModel[];
  highlightedBlocks: BlockModel[];
  user: TeamModel;
  country: CountryModel;
  dayIsCompleted = false;
  completionPerc = 0;
  lastNotificationCheck = null;
  firstBlockDate = null;
  meridian = false;

  notices: NoticeModel[];
  noticesCount: number;
  loadingDays = true;
  loadingBlocks = true;

  constructor(
    private roomsService: RoomsService,
    private authService: AuthService,
    private blockService: BlockService,
    private coreLayoutService: CoreLayoutService,
    private countryService: CountryService,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private dateToLocaleStringPipe: DateToLocaleStringPipe,
    private dateToDbPipe: DatetimeToDbPipe,
    private teamService: TeamService,
    private router: Router,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadingDays = true;
    this.teamService.getCurrentUserTeam(true).subscribe((res: ResponseModel) => {
      const userData = res.getData();
      if (!userData.is_locked && !this.teamService.isLocked) {
        this.loadUsers();

        this.countryService.getCurrentCountry().subscribe((res: ResponseModel) => {
          this.country = res.getData();
          if (this.country.language_code != 'it') {
            this.meridian = true
          }
          this.countryService.currentCountry = this.country;
          if (this.country.color) {
            this.changeCssVariable('--orange', this.country.color);
          }

          this.translate.use(this.countryService.currentCountry.language_code);
          document.querySelector('html').lang = this.countryService.currentCountry.language_code;

          this.meridian = this.countryService.currentCountry.language_code == 'it' ? false : true

          this.countryService.getCountryActiveDays(this.country.country_uniqid, true).subscribe((resDays: ResponseModel) => {
            const temp = resDays.getData();
            this.activeDays = temp.map(d => new Date(this.parseCompatibilityDate(d)));
            this.updateDays();
            this.loadingDays = false;
            this.getBlocksCompletionPercentage();

          });


          this.roomsService.getUserRooms().subscribe((resRooms: ResponseModel) => {
            this.rooms = resRooms.getData();
            this.setRoom(this.rooms[0]);
            // this.loadBlocks();
          }, err => console.log(err));

        }, err => console.log(err));

        // get Notifications
        this.loadNotices();
        this.coreLayoutService.sidebarOnClosed$.subscribe(() => {
          this.checkNotification();
        });
      } else {
        this.router.navigate(['locked']);
      }
    });

  }

  getBlocksCompletionPercentage() {
    this.blockService.getBlockCompletionByCountryUniqid(this.country.country_uniqid).subscribe((res: ResponseModel) => {
      this.completionPerc = res.getData().completion_perc;
      console.log('block percentage', res.getData());
    });
  }

  loadUsers() {
    this.teamService.getCurrentUserTeam().subscribe((res: ResponseModel) => {
      this.user = res.getData();
      console.log({currentUser: this.user})
      if (!this.user.has_onboarded) {
        this.router.navigate(['onboarding', 'nickname-selection']);
      }
      if (this.user.request_change_password) {
        this.router.navigate(['onboarding', 'password-selection', 'true']);
      }
    }, err => console.log(err));

    this.teamService.userUpdated.subscribe(user => {
      this.user = user;
    });
  }

  loadNotices() {
    this.notificationService.getNotices().subscribe((res: ResponseModel) => {
      this.notices = res.getData();
      const unreadNotices = this.notices.filter(n => !n.read);
      this.noticesCount = unreadNotices.length;
    }, err => console.log(err));
  }

  checkNotification() {
    if (this.notificationService.currentNotice) {
      this.goToQuestionList();
    }
    this.loadNotices();
  }

  openNotificationSidebar() {
    this.coreLayoutService.openSidebar(NotificationListComponent, {notices: this.notices});
  }

  openProfileSidebar() {
    this.coreLayoutService.openSidebar(ProfileSidebarComponent, {user: this.user, room: this.room});
  }

  openQuestionList(block: BlockModel) {
    let canOpen = true;
    if (block.device === 'SMARTPHONE' && !this.isMobile()) {
      this.toastService.error(this.translateService.instant('YOU_NEED_TO_USE_A_SMARTPHONE'));
      canOpen = false;
    }
    if (block.device === 'DESKTOP' && this.isMobile()) {
      this.toastService.error(this.translateService.instant('YOU_NEED_TO_USE_A_DESKTOP'));
      canOpen = false;
    }
    if (block.has_geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const distance = this.getDistanceFromCoords(
          block.geolocation_lat, block.geolocation_long,
          position.coords.latitude, position.coords.longitude
        );
        // if distance less than 1 km
        if (distance > 1) {
          this.toastService.error(this.translateService.instant('YOU_ARE_NOT_CLOSE_ENOUGH_TO_THE_POSITION'));
          canOpen = false;
        }
        if (canOpen) {
          this.goToQuestionList(block);
        }
      }, err => {
        this.toastService.error(this.translateService.instant('GEOLOCATION_IS_DENIED'));
      }, {enableHighAccuracy: true, maximumAge: 10000});
    } else {
      if (canOpen) {
        this.goToQuestionList(block);
      }
    }
  }

  goToQuestionList(block: BlockModel = null) {
    this.blockService.currentBlock = block;
    this.router.navigate(['/question-list']);
  }

  setRoom(room: RoomModel, load = true) {
    this.room = room;
    this.blockService.getFirstBlockWithMissingAnswers(this.country.country_uniqid).subscribe((res: ResponseModel) => {
      const firstBlockWIthMissingAnswers = res.getData();
      if (firstBlockWIthMissingAnswers) {
        const date = new Date(this.parseCompatibilityDate(firstBlockWIthMissingAnswers.date));
        date.setHours(0, 0, 0, 0);
        const foundDate = this.days.find(d => d.date.getTime() === date.getTime());
        if (foundDate) {
          this.changeDate(foundDate);
        } else {
          console.log('date not found in blocks');
          this.loadBlocks();
        }
      } else {
        this.loadBlocks();
      }
    });
  }

  updateDays() {
    this.days = [];
    const daysTemp  = [];
    for (const activeDay of this.activeDays) {
      daysTemp.push({date: new Date(activeDay), name: this.dateToLocaleStringPipe.transform(activeDay)});
    }
    this.days = daysTemp;
    console.log({days: this.days})
    this.day  = this.days[this.days.length - 1];

    // se redirectToBlock è valorizzato imposto la data corrispondente
    if (this.blockService.redirectToBlock) {
      for (const day of this.days) {
        if (day.date.getTime() === this.blockService.redirectToBlock.date.getTime()) {
          this.day = day;
        }
      }
      this.day.date = this.blockService.redirectToBlock.date;
    }
    this.blockService.currentDay = this.day;
  }

  isInActiveDays(date: Date) {
    for (const day of this.activeDays) {
      day.setHours(0, 0, 0, 0);
      date.setHours(0, 0, 0, 0);
      if (day.getTime() === date.getTime()) {
        return true;
      }
    }
    return false;
  }

  logout(): void {
    this.authService.logout('/').subscribe();
  }

  loadBlocks() {
    this.operation('loading-blocks').reset().isSubmitting();
    let date = new Date();
    if (this.day && this.day.date) {
      date = this.day.date;
    }

    this.blockService.getBlocksByDate(this.dateToDbPipe.transform(date)).subscribe((res: ResponseModel) => {
      this.blockService.sessionBlock = res.getData();
      this.allBlocks = res.getData();
      console.log({allBlocks: this.allBlocks})

      let tempBlocks = res.getData().filter(b => !b.is_diary && !b.is_highlighted);
      tempBlocks = tempBlocks.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))
      tempBlocks = tempBlocks.sort((a,b) => (a.roomid > b.roomid) ? 1 : ((b.roomid > a.roomid) ? -1 : 0))
      this.blocks = tempBlocks;

      this.highlightedBlocks = res.getData().filter(b => b.is_highlighted);
      this.diaryBlocks       = res.getData().filter(b => b.is_diary);
      this.dayIsCompleted    = true;
      console.log({blocks: this.blocks, highlightedBlocks: this.highlightedBlocks, diaryBlocks: this.diaryBlocks})
      this.operation('loading-blocks').isSuccess();
      for (const block of this.allBlocks) {
        if (block.current_interviewed_nr_question_answered_for_today < block.current_interviewed_nr_question_total) {
          this.dayIsCompleted = false;
        }
      }
      // se redirectToBlock è valorizzato lo apro e devalorizzo redirectToBlock
      if (this.blockService.redirectToBlock) {
        // tslint:disable: radix
        for (const block of this.blocks) {
          if (block.id === parseInt(this.blockService.redirectToBlock.blockid)) {
            this.openQuestionList(block);
          }
        }
        for (const block of this.diaryBlocks) {
          if (block.id === parseInt(this.blockService.redirectToBlock.blockid)) {
            this.openQuestionList(block);
          }
        }
        for (const block of this.highlightedBlocks) {
          if (block.id === parseInt(this.blockService.redirectToBlock.blockid)) {
            this.openQuestionList(block);
          }
        }
        this.blockService.redirectToBlock = null;
      }
      this.loadingBlocks = false;
    }, (err) => console.log(err));
  }

  changeDate(e) {
    console.log('changeDate', e);
    this.day = e;
    this.blockService.currentDay = this.day;
    this.loadBlocks();
  }

}
