import { BaseModel } from '@app/core/models/base';
import { TeamModel } from './team.model';

/**
 * User model class
 */

export class NoticeModel extends BaseModel {

    // tslint:disable: variable-name
    id?: number;
    creationtime: Date;
    type: string; // MENTION / COMMENT / LIKE
    roomid: number;
    blockid: number;
    questionid: number;
    commentid?: number;
    answerid: number;
    from_team: TeamModel;
    to_userid: number;
    to_role: string;
    read: boolean;
    is_room_group: boolean;

    static getResourceName() {
        return 'current-user/manage-notifications';
    }

    isCommentNotice() {
        return this.commentid ? true : false;
    }

    isAnswerNotice() {
        return this.answerid ? true : false;
    }

    isCommentType() {
        return this.type === 'COMMENT';
    }


}
