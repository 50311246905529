import { BaseModel } from '@app/core/models/base';


export class QuestionModel extends BaseModel {

    // tslint:disable: variable-name
    // todo
    date: string;
    category: any;
    text: string;
    image: string;
    link: string;
    // grosso oggetto json con tutti i dati della domanda. cambia in base al type della domanda
    id: number;
    title: string;
    question_data: any;
    country_uniqid: string;
    blockid: number;
    roomid: number;
    sample_variable_tags: string[];
    answer_tags: string[];
    personas_tags: string[];
    type: string;
    position: number;
    disabled: boolean;
    is_masked: boolean;
    answered: boolean;
    answered_by_currentuser: boolean;
    minimum_multiple_answers_nr: number;
    nr_current_user_answers: number;
    index: number;

    static getResourceName() {
        return 'question-management';
    }

}
