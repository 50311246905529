import { Component, OnInit, Input } from '@angular/core';
import { TeamModel } from '@app/shared/models/team.model';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { RoomModel } from '@app/shared/models/room.model';
import { AuthService } from '@app/core/services/auth.service';
import { CountryService } from '@app/shared/services/country.service';
import { CountryModel } from '@app/shared/models/country.model';
import { TeamService } from '@app/shared/services/team.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-profile-sidebar',
  templateUrl: './profile-sidebar.component.html',
  styleUrls: ['./profile-sidebar.component.scss']
})
export class ProfileSidebarComponent implements OnInit {

  @Input() user: TeamModel;
  @Input() room: RoomModel;

  selected: string;
  country: CountryModel;
  version = ''

  constructor(
    public router: Router,
    public coreLayoutService: CoreLayoutService,
    private authService: AuthService,
    private countryService: CountryService,
    private teamService: TeamService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.country = this.countryService.currentCountry;

    this.teamService.userUpdated.subscribe(user => {
      this.user = user;
    });

    this.http.get('./assets/version.json').subscribe((data: any) => {
      this.version = data.version
    })
  }

  close() {
    if (!this.selected) {
      this.coreLayoutService.closeSidebar();
    } else {
      this.selected = '';
    }
  }

  logout(): void {
    // this.coreLayoutService.closeSidebar();
    this.authService.logout('/').subscribe(response => {
      location.reload();
      // this.router.navigate(['/'])
    });
  }
}
