import { Component, OnInit, Input } from '@angular/core';
import { ResponseModel } from '@app/core/models/response.model';
import { Router, ActivatedRoute } from '@angular/router';
import { CookiesService } from '@app/core/services/cookies.service';
import { AuthService } from '@app/core/services/auth.service';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { StorageService } from '@app/core/services/storage.service';
import { environment } from '../../../../../environments/environment';
import { CountryService } from '@app/shared/services/country.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CountryModel } from '@app/shared/models/country.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { TeamService } from '@app/shared/services/team.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends CoreBaseComponent implements OnInit {
  username: string;
  password: string;
  activated = false;
  missingCountryUniqid = false;
  country: CountryModel;
  loggingIn = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private cookiesService: CookiesService,
    private activatedRoute: ActivatedRoute,
    public coreLayoutService: CoreLayoutService,
    private storage: StorageService,
    private countryService: CountryService,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private teamService: TeamService
  ) {
    super();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.activated = params.activated;
      if (params.reset_password_token) {
        this.coreLayoutService.openSidebar(ResetPasswordComponent, {token: params.reset_password_token});
      }
    });

    const countryUniqid = this.countryService.getCountryCode();
    if (!countryUniqid) {
      this.missingCountryUniqid = true;
    } else {
      this.countryService.getCurrentCountry(true).subscribe((res: ResponseModel) => {
        this.country = res.getData();
        this.countryService.currentCountry = res.getData();
        console.log({country: this.country})
        this.translateService.use(this.country.language_code);
        if (this.country.color) {
          this.changeCssVariable('--orange', this.country.color);
        }
      }, err => console.log(err));
    }

  }

  loginUser() {
    this.loggingIn = true;
    const username = this.username;
    const password = this.password;
    const data = {
      username,
      password,
    };
    this.authService.login(data).subscribe((res: ResponseModel) => {
      const authData = res.getData();
      // tslint:disable-next-line: deprecation
      this.teamService.getCurrentUserTeam().subscribe((res: ResponseModel) => {
        this.loggingIn = false;
        const userTeamData = res.getData();
        if (userTeamData.is_locked) {
          this.teamService.isLocked = true;
          this.router.navigate(['locked']);
        } else {
          this.router.navigate(['research']);
        }
      });
    }, err => {
      console.log(err);
      this.loggingIn = false;
      this.toastService.error(this.translateService.instant('INVALID_LOGIN'));
    });
  }

  openForgotPassword() {
    this.coreLayoutService.openSidebar(ForgotPasswordComponent);
  }
}
