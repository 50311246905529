<div>
    <div *ngIf="question">
        <div class="question-content px-3">
            <div class="attached-file-container" *ngIf="question.question_data.attached_pdf_files">
                <span class="attached-file" *ngFor="let pdf of question.question_data.attached_pdf_files">
                    <a [href]="pdf.pdf_url | privatesrc" target="blank">{{pdf.name}}</a>
                </span>
            </div>
            <div class="attached-image-container" *ngIf="question.question_data.attached_images">
                <img *ngFor="let img of question.question_data.attached_images" [src]="img.img_url | privatesrc" [alt]="img.name">
            </div>

            <!-- answer box -->
            <div *ngIf="!question.answered_by_currentuser && (question.is_masked || answerBox || !block.is_room_group)">
                <div class="panel shadow">
                    <h5 class="mb-3">
                        {{'SENTENCE_COMPLETIONS_INSTRUCTIONS' | translate}}
                    </h5>
                    <div *ngFor="let in of nrInputs" class="form-group" [class.mb-0]="in === (nrInputs.length - 1)">
                        <input class="form-control" type="text" [(ngModel)]="answer.completion_comments[in]">
                    </div>

                    <div class="mt-4" *ngIf="question.question_data.mandatory_comment">
                        <div>
                            <label for="mandatory_comment">{{'COMMENT_YOUR_CHOICE' | translate}}</label>
                            <textarea [(ngModel)]="answer.comment" name="mandatory_comment" id="mandatory_comment" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="my-3 text-right">
                        <button *ngIf="!submitting" (click)="submit()" class="btn-primary sticky-footer"
                            [disabled]="!answer.completion_comments || answer.completion_comments.length !== nrInputs.length">{{
                                ((question.minimum_multiple_answers_nr && question.minimum_multiple_answers_nr > 0 && !block.is_room_group)
                                ? 'SHOW_ANSWER'
                                : 'SEND_ANSWER' )| translate
                            }}</button>
                        <button *ngIf="submitting" class="btn-sicche"><i class="fal fa-spinner-third fa-spin"></i></button>
                    </div>
                </div>
            </div>

            <!-- risposte altri utenti -->
            <div class="container-fluid mt-3" *ngIf="question.answered_by_currentuser || (!question.is_masked && block.is_room_group)">

                <div *ngIf="answers">
                    <div *ngFor="let a of answers" id="answer_id_{{a.id}}">
                        <div  class="panel shadow m-0 h-100 d-flex flex-column mt-3" id="answer_id_{{a.id}}">
                            <div class="d-flex p-0">
                                <div class="col-2 col-xl-1 px-1 text-center">
                                    <div class="avatar-image-container" [style.background-image]="'url(' + (a.team.avatar_url | privatesrc) + ')'">
                                        <div class="placeholder-avatar" *ngIf="!a.team.avatar_url">
                                            <i class="fal fa-user-alt"></i>
                                        </div>
                                    </div>
                                    <span (click)="like(a)"><small class="bold"><i class="fal fa-heart" [ngClass]="{'fill': a.total_like_to_answer || a.total_like_to_comment || a.currentuser_like_answer}"></i>&nbsp;{{a.total_like_to_answer || a.total_like_to_comment}}</small></span>
                                </div>
                                <!-- custom -->
                                <div [className]="block.is_room_group ? 'col-10 col-xl-11' : 'col-12'">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class=" user-name mb-2">{{a.team.nickname}}</div>
                                        </div>
                                    </div>

                                    <!-- testo -->
                                    <div *ngIf="a.answer_data.completion_comments">
                                        <div *ngFor="let completionComment of a.answer_data.completion_comments">
                                            {{completionComment}}
                                        </div>
                                    </div>

                                    <!-- eventuali commenti obbligatori -->
                                    <div *ngIf="a.answer_data.comment">
                                        <div class="sentence-comment mt-4">
                                            <b>{{'COMMENT' | translate}}:</b> {{a.answer_data.comment}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- comments and likes -->
                            <app-questions-comments-like [a]="a"  [answers]="answers"></app-questions-comments-like>
                        </div>
                    </div>
                </div>
                <div *ngIf="answers && answers.length == 0">
                    {{'NO_ANSWERS_YET' | translate}}
                </div>

                <button *ngIf="!question.answered_by_currentuser && !(!question.answered_by_currentuser && (question.is_masked || answerBox || !block.is_room_group))" (click)="answerBox = true" class="btn-sicche btn-primary sticky-footer">
                   <span *ngIf="question.minimum_multiple_answers_nr && block.is_room_group">
                        {{'ADD_NEW_POST' | translate}}<i class="fal fa-reply ml-2 mr-0"></i>
                    </span>
                    <span *ngIf="!question.minimum_multiple_answers_nr || block.is_room_group">
                        {{'SHOW_ANSWER' | translate}}<i class="fal fa-reply ml-2 mr-0"></i>
                    </span>
                </button>
            </div>
        </div>
    </div>
</div>
