import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '@app/core/services/auth.service';


@Injectable({
  providedIn: 'root'
})

export class OnBoardedGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private authService: AuthService
    ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      console.log(this.auth.user);
      return true;
  }

  isAuthenticated(): boolean/*Observable<boolean>*/ {
    return this.auth.isAuthenticated();
  }

}
