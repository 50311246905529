import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { BlockModel } from '@app/shared/models/block.model';
import { AnswerService } from '@app/shared/services/answer.service';
import { TeamModel } from '@app/shared/models/team.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@app/shared/services/notification.service';
import { AnswerBaseComponent } from '../answer-base-component';
import { BlockService } from '@app/shared/services/block.service';
import { SemanticDifferentials } from '../models/semantic-differentials';

@Component({
  selector: 'app-semantic-differentials',
  templateUrl: './semantic-differentials.component.html',
  styleUrls: ['./semantic-differentials.component.scss']
})
export class SemanticDifferentialsComponent extends AnswerBaseComponent implements OnInit {


  @Input() questionInput: QuestionModel;
  @Input() blockInput: BlockModel;
  @Input() userInput: TeamModel;
  @Output() closeAnswerInput = new EventEmitter();

  answer: SemanticDifferentials[] = [];


  constructor(
    public toastService: ToastrService,
    public translateService: TranslateService,
    public answerService: AnswerService,
    public notificationService: NotificationService,
    public blockService: BlockService
  ) {
    super(toastService, translateService, answerService, notificationService, blockService);
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['link'],
        ['emoji']
      ]
    };
  }

  ngOnInit(): void {
    this.initialize(this.questionInput, this.blockInput, this.userInput, this.closeAnswerInput, this.answer);
    if (this.question.question_data.randomize_order) {
      this.question.question_data.answers = this.randomizeOrder(this.question.question_data.answers);
    }
    this.question.question_data.answers
      .forEach(a => {
        if (a.type && a.type === 'pre') {
          a.label_left = this.translateService.instant(a.label_left_key);
          a.label_right = this.translateService.instant(a.label_right_key);
        }
      })
  }

  getAnswerFromId(id: number) {
    return this.question.question_data.answers.find(a => a.id === id);
  }

  checkAnswer(id: number, value: number) {
    this.answer = this.answer.filter(a => a.optionid !== id);         // remove old (if there is)
    this.answer = [...this.answer, { optionid: id, rating: value }];  // push new
  }

  isChecked(id: number, value: number) {
    const temp = this.answer.find(a => a.optionid === id);
    return (temp ? temp.rating === value : false);
  }
}
