import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ResponseModel } from '@app/core/models/response.model';
import { AuthService } from '@app/core/services/auth.service';
import { CountryModel } from '@app/shared/models/country.model';
import { FaqModel } from '@app/shared/models/faq.model';
import { User } from '@app/shared/models/user.model';
import { CountryService } from '@app/shared/services/country.service';
import { HelpService } from '@app/shared/services/help.service';
// import { LanguagesService } from '@app/shared/services/languages.service';
import { environment } from '@environments/environment';
import * as angular from 'angular';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent extends CoreBaseComponent implements OnInit {

  faqs: FaqModel[];
  currentUser: User;
  currentId: string;
  ready = false;
  country: CountryModel;

  constructor(
    private helpService: HelpService,
    private authService: AuthService,
    private domSanitizer: DomSanitizer,
    private countryService: CountryService
  ) {
    super();
  }

  ngOnInit() {
    this.authService.current().subscribe((userRes: ResponseModel) => {
      this.currentUser = userRes.getData();
      this.helpService.getFaq().subscribe((res: ResponseModel) => {
        this.faqs = res.getData().faq ? res.getData().faq : [];
        this.sanitizeUrl();
        this.ready = true;
        console.log(this.faqs)
      });
    });
    this.country = this.countryService.getSessionCountry()
  }

  sanitizeUrl() {
    const srcRegex = new RegExp('src\s*=\s*"(.+?)"');

    for (const faq of this.faqs) {
      faq.answer = this.replaceAll(faq.answer, '/systemFiles', environment.websiteUrl + '/systemFiles');
      faq.answer = this.domSanitizer.bypassSecurityTrustHtml(faq.answer);
      faq.vimeo_embed = this.domSanitizer.bypassSecurityTrustHtml(faq.vimeo_embed);
    }
  }

}
