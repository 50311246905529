import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TeamService } from '@app/shared/services/team.service';
import { PrivateSrcPipe } from '@app/core/pipes/private-src.pipe';
import { TeamModel } from '@app/shared/models/team.model';
import { ResponseModel } from '@app/core/models/response.model';
import { AuthService } from '@app/core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-password-selection',
  templateUrl: './password-selection.component.html',
  styleUrls: ['./password-selection.component.scss']
})
export class PasswordSelectionComponent implements OnInit {

  constructor(
    private router: Router,
    private teamService: TeamService,
    private privateSrcPipe: PrivateSrcPipe,
    private authService: AuthService,
    private translateService: TranslateService,
    private toastService: ToastrService,
    private activatedRoute: ActivatedRoute
  ) { }

  user: TeamModel;
  realName = false;
  password: string;
  password2: string;
  changePasswordOnly: string;

  ngOnInit(): void {
    this.authService.current().subscribe((res: ResponseModel) => {
      this.user = res.getData();
    });
    this.changePasswordOnly = this.activatedRoute.snapshot.paramMap.get('change_password_only');
  }

  submit() {
    if (this.password.length < 6) {
      return this.toastService.error(this.translateService.instant('PASSWORD_TOO_SHORT'));
    }
    if (this.password === this.password2) {
      this.user.password = this.password;
      this.authService.updateCurrent(this.user).subscribe((res: ResponseModel) => {
        this.teamService.updateCurrentUserTeam(this.user).subscribe((res: ResponseModel) => {
          this.toastService.success(this.translateService.instant('PASSWORD_MODIFIED'));
          this.goToPrivacyPolicy();
        });
        //this.toastService.success(this.translateService.instant('PASSWORD_MODIFIED'));
        //this.goToPrivacyPolicy();
      });
    } else {
      this.toastService.error(this.translateService.instant('PASSWORD_MISMATCH'));
    }
  }

  goToPrivacyPolicy() {
    if (this.changePasswordOnly) {
      this.router.navigateByUrl('/research');
    } else {
      this.router.navigateByUrl('/onboarding/privacy-policy');
    }
  }

}
