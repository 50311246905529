<div>
    <div *ngIf="question">
        <div class="question-content px-3">
            <div class="attached-file-container" *ngIf="question.question_data.attached_pdf_files">
                <span class="attached-file" *ngFor="let pdf of question.question_data.attached_pdf_files">
                    <a [href]="pdf.pdf_url | privatesrc" target="blank">{{pdf.name}}</a>
                </span>
            </div>
            <div class="attached-image-container" *ngIf="question.question_data.attached_images">
                <img *ngFor="let img of question.question_data.attached_images" [src]="img.img_url | privatesrc" [alt]="img.name">
            </div>

            <!-- answer box -->
            <div *ngIf="!question.answered_by_currentuser && (question.is_masked || answerBox || !block.is_room_group)">
                <div class="py-3">
                    <!-- answer custom -->
                    <h5 *ngIf="answerService.isMobile()" class="mb-3">
                        {{ 'TURN_PHONE_HORIZONTAL' | translate }}
                    </h5>

                    <div *ngFor="let a of question.question_data.answers" class="mb-3">
                        <div class="panel shadow d-flex align-items-start justify-content-center px-2">
                            <div class="landscape-label">
                                <p class="mr-2 text-right"><span class="label-dark">{{ a.label_left }}</span></p>
                            </div>
                            <div class="semantic-radio-container w-100">

                                <div class="d-flex align-items-start justify-content-between">
                                    <span class="item grade-a d-flex justify-content-start align-items-center flex-column">
                                        <input [name]="a.id + '-semantic-rating'" type="radio" [id]="a.id + '-rating-1'" [checked]="isChecked(a.id, 1)" (click)="checkAnswer(a.id, 1)">
                                        <p class="m-0 mobile-label"><span class="label-dark">{{ a.label_left }}</span></p>
                                    </span>
                                    <span class="item grade-b">
                                        <input [name]="a.id + '-semantic-rating'" type="radio" [id]="a.id + '-rating-2'" [checked]="isChecked(a.id, 2)" (click)="checkAnswer(a.id, 2)">
                                    </span>
                                    <span class="item grade-c">
                                        <input [name]="a.id + '-semantic-rating'" type="radio" [id]="a.id + '-rating-3'" [checked]="isChecked(a.id, 3)" (click)="checkAnswer(a.id, 3)">
                                    </span>
                                    <span class="item grade-d">
                                        <input [name]="a.id + '-semantic-rating'" type="radio" [id]="a.id + '-rating-4'" [checked]="isChecked(a.id, 4)" (click)="checkAnswer(a.id, 4)">
                                    </span>
                                    <span class="item grade-e d-flex justify-content-start align-items-center flex-column">
                                        <input [name]="a.id + '-semantic-rating'" type="radio" [id]="a.id + '-rating-5'" [checked]="isChecked(a.id, 5)" (click)="checkAnswer(a.id, 5)">
                                        <p class="m-0 mobile-label"><span class="label-dark">{{ a.label_right }}</span></p>
                                    </span>
                                </div>
                                <div class="semantic-gradient"></div>
                            </div>
                            <div class="landscape-label">
                                <p class="ml-2 text-left"><span class="label-dark">{{ a.label_right }}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="mt-3 text-right">
                        <button *ngIf="!submitting" (click)="submit()" class="btn-primary sticky-footer" [disabled]="answer.length !== question.question_data.answers.length">{{
                                ((question.minimum_multiple_answers_nr && question.minimum_multiple_answers_nr > 0 && !block.is_room_group)
                                ? 'SHOW_ANSWER'
                                : 'SEND_ANSWER' )| translate
                            }}</button>
                        <button *ngIf="submitting" class="btn-primary sticky-footer"><i class="fal fa-spinner-third fa-spin"></i></button>
                    </div>
                </div>
            </div>

            <!-- risposte altri utenti -->
            <div class="container-fluid mt-3" *ngIf="question.answered_by_currentuser || (!question.is_masked && block.is_room_group)">

                <div *ngIf="answers">
                    <div *ngFor="let a of answers" id="answer_id_{{a.id}}">
                        <div  class="panel shadow m-0 h-100 d-flex flex-column mt-3" id="answer_id_{{a.id}}">
                            <div class="d-flex p-0">
                                <div class="col-2 col-xl-1 px-1 text-center">
                                    <div class="avatar-image-container" [style.background-image]="'url(' + (a.team.avatar_url | privatesrc) + ')'">
                                        <div class="placeholder-avatar" *ngIf="!a.team.avatar_url">
                                            <i class="fal fa-user-alt"></i>
                                        </div>
                                    </div>
                                    <span (click)="like(a)"><small class="bold"><i class="fal fa-heart" [ngClass]="{'fill': a.total_like_to_answer || a.total_like_to_comment || a.currentuser_like_answer}"></i>&nbsp;{{a.total_like_to_answer || a.total_like_to_comment}}</small></span>
                                </div>
                                <!-- custom -->
                                <div [className]="block.is_room_group ? 'col-10 pr-0' : 'col-12'">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class=" user-name mb-2">{{a.team.nickname || (a.team.firstname + ' ' + a.team.lastname)}}</div>
                                        </div>
                                    </div>

                                    <div *ngFor="let answerData of a.answer_data" class="border rounded p-3 d-flex align-items-center justify-content-center semantic-panel">
                                        <!--<div class="semantic-radio-container">

                                            <div class="d-flex align-items-center justify-content-between">
                                                <input type="radio" disabled [checked]="answerData.rating === 1">
                                                <input type="radio" disabled [checked]="answerData.rating === 2">
                                                <input type="radio" disabled [checked]="answerData.rating === 3">
                                                <input type="radio" disabled [checked]="answerData.rating === 4">
                                                <input type="radio" disabled [checked]="answerData.rating === 5">
                                            </div>
                                            <div class="labels d-flex justify-content-between mb-2">
                                                <div class="text-left pt-2 mb-2">
                                                    <p class="m-0"><span class="label-dark">{{ getAnswerFromId(answerData.optionid).label_left }}</span></p>
                                                </div>
                                                <div class="text-right mt-2">
                                                    <p class="m-0"><span class="label-dark">{{ getAnswerFromId(answerData.optionid).label_right }}</span></p>
                                                </div>
                                            </div>
                                        </div>-->
                                        <div class="landscape-label">
                                            <p class="mr-2 text-right"><span class="label-dark">{{ getAnswerFromId(answerData.optionid).label_left }}</span></p>
                                        </div>
                                        <div class="semantic-radio-container visual-only w-100">

                                            <div class="d-flex align-items-start justify-content-between">
                                                <span class="item grade-a d-flex justify-content-start align-items-center flex-column">
                                                    <input type="radio" disabled [checked]="answerData.rating === 1">
                                                    <p class="m-0 mobile-label"><span class="label-dark">{{ getAnswerFromId(answerData.optionid).label_left }}</span></p>
                                                </span>
                                                <span class="item grade-b">
                                                    <input type="radio" disabled [checked]="answerData.rating === 2">
                                                </span>
                                                <span class="item grade-c">
                                                    <input type="radio" disabled [checked]="answerData.rating === 3">
                                                </span>
                                                <span class="item grade-d">
                                                    <input type="radio" disabled [checked]="answerData.rating === 4">
                                                </span>
                                                <span class="item grade-e d-flex justify-content-start align-items-center flex-column">
                                                    <input type="radio" disabled [checked]="answerData.rating === 5">
                                                    <p class="m-0 mobile-label"><span class="label-dark">{{ getAnswerFromId(answerData.optionid).label_right }}</span></p>
                                                </span>
                                            </div>
                                            <div class="semantic-gradient"></div>
                                        </div>
                                        <div class="landscape-label">
                                            <p class="ml-2 text-left"><span class="label-dark">{{ getAnswerFromId(answerData.optionid).label_right }}</span></p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!-- comments and likes -->
                            <app-questions-comments-like [a]="a"  [answers]="answers"></app-questions-comments-like>
                        </div>
                    </div>
                </div>
                <div *ngIf="answers && answers.length == 0">
                    {{'NO_ANSWERS_YET' | translate}}
                </div>

                <button *ngIf="!question.answered_by_currentuser && !(!question.answered_by_currentuser && (question.is_masked || answerBox || !block.is_room_group))" (click)="answerBox = true" class="btn-sicche btn-primary sticky-footer">
                   <span *ngIf="question.minimum_multiple_answers_nr && block.is_room_group">
                        {{'ADD_NEW_POST' | translate}}<i class="fal fa-reply ml-2 mr-0"></i>
                    </span>
                    <span *ngIf="!question.minimum_multiple_answers_nr || block.is_room_group">
                        {{'SHOW_ANSWER' | translate}}<i class="fal fa-reply ml-2 mr-0"></i>
                    </span>
                </button>
            </div>
        </div>
    </div>
</div>
