<div class="bg-orange vh-100 d-flex align-items-center overflowy-hidden" *ngIf="country">
    <div class="container">
        <div class="row">
            <div class="col-12 col-xl-6 offset-xl-3 text-center pt-5">
                <div class="text-center">
                    <h1 class="h2">{{'PRIVACY_POLICY' | translate}}</h1>
                    <p>{{'PRIVACY_INSTRUCTIONS' | translate}}</p>

                    <div class="privacy-content bg-white" id="privacy" [innerHTML]="country.privacy_html" class="privacy-field offset-md-2 col-md-8 offset-xl-3 col-xl-6">

                    </div>

                    <div class="mt-5 d-flex text-center">
                        <input id="privacycheck" type="checkbox" [disabled]="!scrolledDown" (click)="accept()" [checked]="accepted">
                        <label for="privacycheck" style="font-size:1.3rem;line-height:1.6rem;text-align:left;margin-left:8px;">{{'I_ACCEPT_PRIVACY' | translate}}</label>
                    </div>


                </div>

                <div class="mt-4 text-center">
                    <button class="btn-sicche" [disabled]="!accepted" (click)="goToPrivacySummary()">
                        {{'CONTINUE' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>