import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

interface ImagePin {
  pin_uniqueid: number;
  position: { x: number, y: number }; // approximated position depending on visualization dimension of the image
  comment: string;
  emoticon: any;
}

@Component({
  selector: 'app-pin-on-image',
  templateUrl: './pin-on-image.component.html',
  styleUrls: ['./pin-on-image.component.scss']
})
export class PinOnImageComponent implements OnInit {

  @Input() media: any;
  @Input() requireEmoticons = false;
  @Input() form: UntypedFormGroup;
  @Input() pinsControlName = 'pins';
  @Input() inputPins: ImagePin[];
  @Output() pinsChange = new EventEmitter<ImagePin[]>();

  @ViewChild('imageel') imageel: ElementRef;
  ready = false;
  currentPinUniqueid = 0;
  selectedPin: ImagePin = null;   // for visualization
  formNewPin: UntypedFormGroup;
  showForm = false;

  get pins(): ImagePin[] {
    return this.form
      ? this.form.controls[this.pinsControlName].value
      : this.inputPins;
  }

  constructor(
    public toastService: ToastrService,
    public translateService: TranslateService,
  ) { }


  ngOnInit(): void {
    // if there's no form input, then this is only a visualization component. No action permitted.
    if (this.form) {
      this.formNewPin = new UntypedFormGroup({
        position: new UntypedFormGroup({
          x: new UntypedFormControl('', Validators.required),
          y: new UntypedFormControl('', Validators.required)
        }),
        emoticon: new UntypedFormControl('', this.requireEmoticons ? Validators.required : null),
        comment: new UntypedFormControl('', Validators.required)
      });
      this.currentPinUniqueid = this.pins.reduce((max, pin) => pin.pin_uniqueid > max ? pin.pin_uniqueid : max, 0);
    }
  }

  pinPlace(event: any) {
    if (!this.form) { return; }
    const relativeClickPos = {
      x: event.layerX - this.imageel.nativeElement.offsetLeft,
      y: event.layerY - this.imageel.nativeElement.offsetTop
    };
    const realClickPos = {
      x: (this.imageel.nativeElement.naturalWidth / this.imageel.nativeElement.width) * relativeClickPos.x,
      y: (this.imageel.nativeElement.naturalHeight / this.imageel.nativeElement.height) * relativeClickPos.y
    };
    this.formNewPin.patchValue({ position: { x: realClickPos.x, y: realClickPos.y }});

    this.showForm = true;
  }

  pinConfirm() {
    if (!this.formNewPin.valid) { return; }
    this.currentPinUniqueid++;
    (this.form.controls[this.pinsControlName] as UntypedFormArray).push(new UntypedFormGroup({
      pin_uniqueid: new UntypedFormControl(this.currentPinUniqueid),
      position: new UntypedFormGroup({
        x: new UntypedFormControl(this.formNewPin.value.position.x),
        y: new UntypedFormControl(this.formNewPin.value.position.y)
      }),
      comment: new UntypedFormControl(this.formNewPin.value.comment),
      emoticon: new UntypedFormControl(this.formNewPin.value.emoticon)
    }));
    this.formNewPin.patchValue({ emoticon: '', comment: '' });
    this.pinsChange.next(this.pins);
  }

  getPinDistance(pin?: ImagePin): { left: string, top: string } {
    return pin
      ? {
        left: `${100 * pin.position.x / this.imageel.nativeElement.naturalWidth}%`,
        top: `${100 * pin.position.y / this.imageel.nativeElement.naturalHeight}%`,
      }
      : {
        left: `${100 * this.formNewPin.value.position.x / this.imageel.nativeElement.naturalWidth}%`,
        top: `${100 * this.formNewPin.value.position.y / this.imageel.nativeElement.naturalHeight}%`
      };
  }

  pinSelect(pin: ImagePin) {
    this.selectedPin = pin;
  }

  pinDelete(pin: ImagePin) {
    (this.form.controls[this.pinsControlName] as UntypedFormArray).removeAt(
      this.pins.findIndex(p => p.pin_uniqueid === pin.pin_uniqueid)
    );
    this.selectedPin = null;
    this.pinsChange.next(this.pins);
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
}
