export const SITENAME = "";

export const Mode = {
    USE_HASH: true,
    STORAGE_DRIVER: 'localStorage',
    AUTH_TYPE: 'phpsessid' /* bearer / phpsessid */,
    BASIC_AUTH: false,
    HEADER_WITH_CREDENTIALS: false,
    DECIMALS_PLACES: 2,
    PASSWORD_MIN_LENGTH: 8
};

export const BASIC_AUTH = {
    USERNAME: '',
    PASSWORD: ''
}

export const StandardRoutes = {
    LOGIN: 'login',
    PRIVATE_DASHBOARD: 'research'
};