import { Injectable, OnInit, ɵConsole } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { QuestionModel } from '@app/shared/models/question.model';
import { BlockModel } from '@app/shared/models/block.model';
import { TeamModel } from '@app/shared/models/team.model';
import { ResponseModel } from '@app/core/models/response.model';
import { AnswerModel } from '@app/shared/models/answer.model';
import { CommentModel } from '@app/shared/models/comment.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AnswerService } from '@app/shared/services/answer.service';
import { NotificationService } from '@app/shared/services/notification.service';
import * as angular from 'angular';
import { NoticeModel } from '@app/shared/models/notice.model';
import { BlockService } from '@app/shared/services/block.service';

@Injectable()
export abstract class AnswerBaseComponent extends CoreBaseComponent implements OnInit {

  constructor(
    public toastService: ToastrService,
    public translateService: TranslateService,
    public answerService: AnswerService,
    public notificationService: NotificationService,
    public blockService: BlockService
  ) {
    super();
  }
  question: QuestionModel;
  block: BlockModel;
  user: TeamModel;
  closeAnswer: any;

  videos = new FormData();
  videoCount = 0;
  videoNamesKey = [];

  answerBox = false;
  answers: AnswerModel[];
  currentComment: string;
  submitting: boolean;
  notice: NoticeModel;

  answer: any;

  modules: any;

  imagePreviews = [];
  videoPreviews = [];
  // tslint:disable-next-line: contextual-lifecycle
  ngOnInit() {
    this.serveNotification();
    return null;
  }

  initialize(question: QuestionModel, block: BlockModel, user: TeamModel, closeAnswer: any, answer: any) {
    this.question = question;
    this.block = block;
    this.user = user;
    this.closeAnswer = closeAnswer;
    this.answer = answer;

    if (this.notificationService.currentNotice) {
      console.log(this.notificationService.currentNotice);
      this.notice = angular.copy(this.notificationService.currentNotice);
      // todo this.notificationService.currentNotice = null;
      this.block = new BlockModel({is_room_group: this.notice.is_room_group});
    }
    console.log({block: this.block});
    if (!this.question.is_masked || this.question.answered_by_currentuser) {
      if (this.block.is_room_group) {
        this.getAnswers();
      } else {
        this.getUserAnswer();
      }
    }
    if (!this.question.is_masked && !this.question.answered_by_currentuser) {
      this.answerBox = true;
    }
  }


  getAnswers() {
    this.answerService.getAnswers(this.question.id).subscribe((res: ResponseModel) => {
      this.answers = res.getData();
      if ([
        'closed-single', 'closed-multiple', 'image-association', 'grouping-category',
        'semantic-differentials', 'preference-ranking', 'attitude-scale', 'pin-on-media'
      ].indexOf(this.question.type) !== -1 && this.question.is_masked) {
        this.answers = this.answers.filter(a => a.creatoruserid === this.user.userid);
      }
      if (this.question.minimum_multiple_answers_nr && this.question.minimum_multiple_answers_nr > 0) {
        this.answers.reverse();
      }
      this.serveNotification();
    });
  }


  serveNotification() {
    this.notificationService.currentNotice = null;
    if (this.notice && !((this.notice.from_team.role == 'RESEARCHER' || this.notice.from_team.role == 'OWNER') && this.notice.type == 'LIKE')) {
      const scrollToId   = (this.notice.commentid && this.notice.type !== 'LIKE') ? this.notice.commentid : this.notice.answerid;
      const scrollToType = (this.notice.commentid && this.notice.type !== 'LIKE') ? 'comment' : 'answer';
      // settimeout il dom viene renderizzato dopo il costruttore, quindi getElementById ritorna null
      console.log('SCROLL TO', this.notice);
      setTimeout(() => {
        const element = document.getElementById(`${scrollToType}_id_${scrollToId}`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          setTimeout(() => {
            element.classList.add('animate')
            element.classList.add('scale-smaller')
            setTimeout(() => {
              element.classList.remove('scale-smaller')
            }, 500)
          }, 1000);
        } else {
          this.toastService.error(this.translateService.instant('COMMENT_DELETED'));
        }
      }, 1000);
    }
  }


  getUserAnswer() {
    this.answerService.getUserAnswer(this.question.id).subscribe((res: ResponseModel) => {
      this.answers = res.getData();
      if (this.question.minimum_multiple_answers_nr && this.question.minimum_multiple_answers_nr > 0) {
        this.answers.reverse();
      }
      this.serveNotification();
      console.log('answers', this.answers);
    });
  }

  submit() {
    this.submitting = true;
    if (!this.answer) {
      this.answer = {}
    }
    Object.keys(this.answer).forEach(k => {
      if (typeof this.answer[k] === 'string') {
        this.answer[k] = this.answer[k].trim().replace(/  +/g, ' ');
      }
    });

    this.answerService.createAnswer(this.question.id, this.answer, this.videos).subscribe((res: ResponseModel) => {
      this.submitting = false;
      this.answerBox = false;
      this.question.answered_by_currentuser = true;
      if (!this.answers) {
        this.answers = [];
      }
      this.answers = [res.getData()[0], ...this.answers];
      if (this.question.is_masked || (this.question.minimum_multiple_answers_nr && this.question.minimum_multiple_answers_nr > 0)) {
        if (this.block.is_room_group) {
          this.getAnswers();
        } else {
          this.getUserAnswer();
        }
      } else {
        this.closeAnswer.emit('');
      }
    });
  }

  addImage(file: File, element = null) {
    if (file.type.indexOf('image') !== -1) {
      let newImage;
      const reader = new FileReader();
      reader.onload = (e) => {
        newImage = e.target.result;
        this.answer.images = [...this.answer.images, { image: newImage }];
        this.previewImages();

        if (element) {
          element.target.value = null
        }
      };
      reader.readAsDataURL(file);
    } else {
      this.toastService.error(this.translateService.instant('FILE_IS_NOT_IMAGE'));
    }
  }

  removeImage(imageToRemove) {
    const answerImages = this.answer.images;
    let index;
    for (let i = 0; i < answerImages.length; i++) {
      const image = answerImages[i];
      if (image.image === imageToRemove || image.image_url === imageToRemove) {
        index = i;
      }
    }
    if (Number.isInteger(index)) {
      answerImages.splice(index, 1);
    }
    this.answer.images = answerImages;
    this.previewImages();
  }

  addVideo(files: FileList, element = null) {
    if (files.item(0).type.indexOf('video') !== -1) {
      console.log(files.item(0));
      if (files.item(0).size >= Number(this.getBytesFromMegabytes(2000))) {
        this.toastService.error(this.translateService.instant('VIDEO_TOO_BIG'));
      } else {
        this.videoCount++;
        this.videos.append('videos' + this.videoCount, files.item(0));
        this.videoNamesKey = [...this.videoNamesKey, {name: files.item(0).name, key: 'videos' + this.videoCount}];
        this.toastService.success(this.translateService.instant('VIDEO_ADDED'));

        if (element) {
          element.target.value = null
        }
      }
    } else {
      this.toastService.error(this.translateService.instant('FILE_IS_NOT_VIDEO'));
    }
  }

  removeVideo(videoKey) {
    this.videoCount--;
    this.videos.delete(videoKey);
    this.videoNamesKey = this.videoNamesKey.filter(vid => vid.key !== videoKey);
  }

  previewImages() {
    this.imagePreviews = [];
    for (const img of this.answer.images) {
      this.imagePreviews = [...this.imagePreviews, img.image || img.image_url];
    }
  }

  previewVideos() {
    this.videoPreviews = [];
    for (const vid of this.answer.videos) {
      this.videoPreviews = [...this.videoPreviews, vid.video || vid.video_url];
    }
  }

  like(a: AnswerModel) {
    if (!a.currentuser_like_answer) {
      this.answerService.like(a.id).subscribe((res: ResponseModel) => {
        for (const answer of this.answers) {
          if (answer.id === a.id) {
            a.currentuser_like_answer = true;
            a.total_like_to_answer++;
          }
        }
      });
    } else {
      this.answerService.unlike(a.id).subscribe();
      for (const answer of this.answers) {
        if (answer.id === a.id) {
          a.currentuser_like_answer = false;
          a.total_like_to_answer--;
        }
      }
    }
  }

  likeComment(answer: AnswerModel, comment: CommentModel) {
    if (!comment.currentuser_like_comment) {
      this.answerService.likeComment(answer.id, comment.id).subscribe((res: ResponseModel) => {
        const answers = this.answers;
        for (const a of answers) {
          if (a.id === answer.id) {
            for (const c of a.comments) {
              if (c.id === comment.id) {
                c.currentuser_like_comment = true;
                c.total_like_to_comment++;
              }
            }
          }
        }
      });
    } else {
      this.answerService.unlike(answer.id, comment.id).subscribe((res: ResponseModel) => {
        const answers = this.answers;
        for (const a of answers) {
          if (a.id === answer.id) {
            for (const c of a.comments) {
              if (c.id === comment.id) {
                c.currentuser_like_comment = false;
                c.total_like_to_comment--;
              }
            }
          }
        }
      });
    }
  }

  postComment(answer: AnswerModel) {
    this.answerService.createCommment(answer.id, this.currentComment).subscribe((res: ResponseModel) => {
      console.log(res.getData());
      const answers = this.answers;
      for (const a of answers) {
        if (a.id === answer.id) {
          a.comments.push(res.getData()[0]);
          a.addComment = false;
        }
      }
      this.answers = answers;
      this.currentComment = '';
    });
  }

  randomizeOrder(array: any[]): any[] {
    let currentIndex = array.length;
    while (0 !== currentIndex) {
      const randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      const tmp = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = tmp;
    }
    return array;
  }
}
