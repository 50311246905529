import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TeamModel } from '@app/shared/models/team.model';
import { TeamService } from '@app/shared/services/team.service';
import { ResponseModel } from '@app/core/models/response.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(
    private teamService: TeamService,
    private toastService: ToastrService,
    private translateService: TranslateService
  ) { }

  @Input() user: TeamModel;
  @Output() saved = new EventEmitter();
  public saving = false;
  public userAvatarHolder = null;

  ngOnInit(): void {
    this.userAvatarHolder = this.user.avatar_url;
  }

  uploadAvatar(files: FileList) {
    const reader = new FileReader();
    let newImage;
    reader.onload = (e) => {
      newImage = e.target.result;
      this.user.avatar = newImage;
      this.user.avatar_url = newImage;
      this.userAvatarHolder = newImage;
    };
    reader.readAsDataURL(files.item(0));
  }

  submit() {
    this.saving = true;
    delete this.user.avatar_url;
    if (this.user.nickname) {
      this.teamService.updateCurrentUserTeam(this.user).subscribe((res: ResponseModel) => {
        this.user = res.getData();
        this.toastService.success(this.translateService.instant('DATA_UPDATED'));
        this.teamService.userUpdated.emit(this.user);
        this.saved.emit(this.user);
        this.saving = false;
      });
    } else {
      this.toastService.error(this.translateService.instant('MISSING_DATA'));
      this.saving = false;
    }
  }

}
