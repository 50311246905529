import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { QuestionModel } from '../models/question.model';
import { BlockService } from './block.service';
import { DatetimeToDbPipe } from '@app/core/pipes/datetime-to-db.pipe';
import { CountryService } from './country.service';


@Injectable({
  providedIn: 'root'
})
export class QuestionService extends BaseService implements BaseServiceI {
  // tslint:disable: variable-name

  constructor(
    public router: Router,
    public apiService: ApiService,
    private http: HttpClient,
    private blockService: BlockService,
    private datetimeToDbPipe: DatetimeToDbPipe,
    private countryService: CountryService
  ) {
    super(router, apiService, QuestionModel);
  }

  getQuestionsByBlockId(blockid, include_answer_current_user = false) {


    return this.sub('questions').get('', {
      blockid,
      sort_attribute: 'position',
      sort_order: 'asc',
      include_answer_current_user,
      answer_check_date: this.datetimeToDbPipe.transform(this.blockService.currentDay.date)
    });
  }


}
