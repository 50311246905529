import { Component, OnInit, Input, Output, ViewContainerRef, ViewChild, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { QuestionModel } from '@app/shared/models/question.model';
import { BlockModel } from '@app/shared/models/block.model';
import { EventEmitter } from '@angular/core';
import { AnswerHostDirective } from './answer-host.directive';
import { QuestionTypesService } from '@app/shared/services/question-types.service';
import { TeamModel } from '@app/shared/models/team.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-answer-group',
  templateUrl: './answer-group-chat.component.html',
  styleUrls: ['./answer-group-chat.component.scss']
})
export class AnswerGroupChatComponent extends CoreBaseComponent implements OnInit {

  @Input() question: QuestionModel;
  @Input() block: BlockModel;
  @Input() user: TeamModel;
  @Output() answered = new EventEmitter();
  @Output() hide = new EventEmitter();
  @ViewChild(AnswerHostDirective, { static: true }) bannerHost: AnswerHostDirective;

  sanitizedTitle: any;

  constructor(
    public viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private questionTypesService: QuestionTypesService,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit(): void {
    console.log("answer group", this.question);
    this.openQuestionComponent();
    this.sanitizedTitle = this.sanitizer.bypassSecurityTrustHtml(this.question.title);
  }


  openQuestionComponent() {
    const component = this.questionTypesService.getQuestionComponent(this.question.type)[0].component;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const viewContainerRef = this.bannerHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    if (componentRef.instance.constructor === component) {
      (componentRef.instance as any).questionInput = this.question;
      (componentRef.instance as any).blockInput = this.block;
      (componentRef.instance as any).userInput = this.user;
      (componentRef.instance as any).closeAnswerInput.subscribe(() => {
        this.cancel();
        this.answered.emit('');
      });
    }
  }

  cancel() {
    this.hide.emit('');
  }


}