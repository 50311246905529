<div class="container-fluid">
	<!-- Heading -->
	<app-header></app-header>
	<!-- /Heading -->
	<!-- Container Wrapper -->
	<div class="container px-4 h-100 bg-orange" id="onBoarding" *ngIf="currentPage == 'boarding'">
		<!-- Welcome -->
		<div class="row align-items-center justify-content-center">
			<div class="col-12 text-center">
				<h2 class="text-white">Benvenuto!</h2>
			</div>
			<div class="col-12 f-15 text-center muli-bold text-white">
				Scegli come vuoi essere chiamato all'interno della ricerca
			</div>
			<div class="col-12 f-12 text-center text-white">
				(potrai modificare e inserire altri dati nel tuo portfolio personale)
			</div>
		</div>
		<!-- /Welcome -->
		<!-- Input -->
		<div class="row justify-content-center">
			<div class="col-12">
				<div class="form-group border-white">
					<label for="nickname" class="px-3">Nickname</label>
					<input type="text" class="form-control px-3 pb-2" name="nickname" id="nickname"
						placeholder="Type your name here">
				</div>
				<input type="checkbox" name="realName" id="realName" (change)="showRealName()">
				<label for="realName" class="text-white f-14">Utilizza il mio vero nome</label>
				<div class="form-group border-white" id="groupName" *ngIf="realNameVisible">
					<label for="name" class="px-3">Name</label>
					<input type="text" class="form-control px-3 pb-2" name="name" id="name"
						placeholder="Type your real name here">
				</div>
			</div>
		</div>
		<!-- /Input -->
		<!-- Change Image -->
		<div class="row m-auto">
			<div class="col-12 d-flex justify-content-center align-items-center">
				<img src="../../../../../assets/images/avatar.png" width="85px" height="85px">
			</div>
		</div>
		<!-- /Change Image -->
		<!-- Continue -->
		<div class="row justify-content-center align-items-end m-auto">
			<button type="button" class="btn-sicche" (click)="currentPage = 'policy'">
				<i class="far fa-arrow-right"></i> Continua
			</button>
		</div>
		<!-- /Continue -->
	</div>

	<div class="container px-4 h-100" id="privacyPolicy" *ngIf="currentPage == 'policy'">
		<div class="row text-center">
			<div class="col-md-12">
				<h2 class="text-white">Privacy Policy</h2>
				<p class="text-white">Per continuare è necessario leggere e accettare le condizioni di utilizzo esposte
					all'interno della nostra privacy policy</p>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12" id="privacyPolicyText" (scroll)="scrollHandler($event)">
				Loremipsum LorempiusmsidisdsdLoremipsum Lorempiusmsidisdsd
				Loremipsum LorempiusmsidisdsdLoremipsum Lorempiusmsidisdsd
				Loremipsum LorempiusmsidisdsdLoremipsum Lorempiusmsidisdsd
				Loremipsum LorempiusmsidisdsdLoremipsum Lorempiusmsidisdsd
				Loremipsum LorempiusmsidisdsdLoremipsum Lorempiusmsidisdsd
				Loremipsum LorempiusmsidisdsdLoremipsum Lorempiusmsidisdsd
				Loremipsum LorempiusmsidisdsdLoremipsum Lorempiusmsidisdsd
			</div>
		</div>
		<div class="row mt-2">
			<div class="col-md-12" *ngIf="canAcceptPrivacy">
				<input type="checkbox" name="confirmPrivacy" id="realName" (change)="askUncheck()" checked>
				<label for="confirmPrivacy" class="text-white f-14">Accetto la privacy</label>
			</div>
			<div class="col-md-12">
				<button type="button" class="btn-sicche" [attr.disabled]="canAcceptPrivacy ? null : true">
					<i class="far fa-arrow-right"></i> Continua
				</button>
			</div>
		</div>
	</div>
</div>