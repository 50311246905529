import { BaseModel } from '@app/core/models/base';

/**
 * User model class
 */

export class TeamModel extends BaseModel {

    // tslint:disable: variable-name
    id?: number;
    userid?: number;
    avatar: any;
    avatar_url: string;
    username: string;
    password: string;
    email: string;
    firstname: string;
    lastname: string;
    nickname: string;
    locked: boolean;
    note: string;
    company: string;
    language_code: string;
    telephone: string;
    default_privacy_html: string;
    consentA: boolean;
    consentB: boolean;
    last_login: string;
    nr_researches: number;
    // country/room related
    invite_status: string;
    role: string;
    uniqid: string;
    roomid: number;
    sample_variable_tags: string[];
    personas_tags: string[];
    msg?: string;
    country_uniqid?: string;
    datetime_first_block: Date;
    request_change_password: boolean;
    has_onboarded: boolean;

    static getResourceName() {
        return 'teams';
    }

    getFullName() {
        return `${this.firstname} ${this.lastname}`;
    }

}
