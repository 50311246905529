import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ResponseModel } from '@app/core/models/response.model';
import { CountryService } from '@app/shared/services/country.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent extends CoreBaseComponent implements OnInit {

  constructor(
    private router: Router,
    private countryService: CountryService
  ) {
    super();
  }

  ngOnInit(): void {
    this.router.navigateByUrl('/onboarding/nickname-selection');
    this.countryService.getCurrentCountry(true).subscribe((res: ResponseModel) => {
      // this.translateService.use(this.country.language_code);
      if (res.getData().color) {
        this.changeCssVariable('--orange', res.getData().color);
      }
    }, err => console.log(err));
  }

}
