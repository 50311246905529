import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { ModalService } from '@app/core/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ToolbarService } from '@app/core/services/toolbar.service';
import { SidebarService } from '@app/core/services/sidebar.service';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { LogoComponent } from '@app/shared/components/logo/logo.component';
import { Interval } from '@app/core/helpers/interval';
import { ResponseModel } from '@app/core/models/response.model';
import { AuthLoginService } from '@app/shared/services/auth-login.service';
import { PingService } from '@app/shared/services/ping.service';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { CountryService } from '@app/shared/services/country.service';

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss']
})
export class PrivateComponent extends CoreBaseComponent implements OnInit {

  public initialized = true;
  public demoWarning = false;
  public subscriptionStatus: string;
  public updatingApp = false;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private toolbarService: ToolbarService,
    public sidebarService: SidebarService,
    private coreSidebarLayoutService: CoreLayoutService,
    private authLoginService: AuthLoginService,
    private pingService: PingService,
    private countryService: CountryService
  ) {
      super();
      this.router.events.subscribe(this.navigationInterceptor.bind(this));
      // Reset navigation sidebar
      this.sidebarService.reset();
      // Reset dynamic sidebar
      this.coreSidebarLayoutService.reset();

      const ping = new Interval(60 * 5 * 1000, () => {
        pingService.ping('/ping').subscribe((response: ResponseModel) => {});
      });
      ping.run();
  }

  ngOnInit() {
    // this.authLoginService.getCurrentUser().subscribe((res: ResponseModel) => {
    //   console.log(res.getData());
    // });

  }


  private navigationInterceptor(e: RouterEvent) {
    if (e instanceof NavigationStart) {
      document.body.classList.add('app-loading');
    }
    if (e instanceof NavigationEnd) {
      // todo this.appService.scrollTop(0, 0);
    }
    if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {
      document.body.classList.remove('app-loading');
    }
  }

  ngAfterViewInit() {

  }


}
