<div class="offset-md-3 col-md-6 offset-xl-4 col-xl-4 login-form-wrapper px-5">
    <div class="container-fluid" *ngIf="!missingCountryUniqid">
        <div class="row py-5">
            <div class="col text-center logo">
                <img [src]="(country && country.logo_url) ? (country.logo_url) : './assets/images/sicche-logo.svg'">
            </div>
        </div>
        <div class="row pb-4">
            <div class="col text-center">
                <h5>{{'LOGIN_MESSAGE' | translate}}</h5>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="email" class="placeholder">{{'EMAIL' | translate}}</label>
                    <input type="email" class="form-control" id="email" [(ngModel)]="username">
                </div>
                <form (submit)="loginUser()">
                    <div>
                        <small class="field-note" (click)="openForgotPassword()">
                            <a class="as-link">{{'PASSWORD_FORGOT' | translate}}</a>
                        </small>
                        <div class="form-group">
                            <label for="password" class="placeholder">{{'PASSWORD' | translate}}</label>
                            <input type="password" class="form-control" name="password" id="password" [(ngModel)]="password">
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <app-core-spinner [toggle]="loggingIn"></app-core-spinner>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col text-center">
                <div class="row justify-content-center">
                    <!-- login() -->
                    <button type="button" class="btn-sicche" (click)="loginUser()"><span><i class="fal fa-sign-in-alt"></i>&nbsp;{{ 'LOGIN' | translate }}</span></button>
                </div>
            </div>
        </div>
    </div>


    <div class="container-fluid" *ngIf="missingCountryUniqid">
        <div class="row py-5">
            <h6>{{'MISSING_COUNTRY_CODE' | translate}}</h6>
        </div>
    </div>
</div>