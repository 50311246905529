<div class="row p-3 justify-content-center align-items-center">
  <div class="col-2">
    <div class="profile-image cursor-pointer">
      <!--<img src="../../../../../assets/images/avatar.png" width="45px" height="45px" data-toggle="modal"
        data-target="#modalProfile">-->
      <img src="../../../../../assets/images/avatar.png" srcset="../../../../../assets/images/avatar.png 1x, ../../../../../assets/images/avatar@2x.png 2x" alt="user avatar" data-toggle="modal" data-target="#modalProfile">
    </div>
  </div>
  <div class="col-2 offset-8 d-flex align-items-center">
    <!-- Change to fal -->
    <i class="fal fa-bell"></i>
  </div>
</div>

<!-- Modal -->
<div class="modal left fade" id="modalProfile" tabindex="-1" role="dialog" aria-labelledby="profileMenuLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="myModalLabel">Left Sidebar</h4>
      </div>
      <div class="modal-body">
        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
          wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt
          laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin
          coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes
          anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
          occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard
          of them accusamus labore sustainable VHS.
        </p>
      </div>
    </div><!-- modal-content -->
  </div><!-- modal-dialog -->
</div><!-- modal -->