// tslint:disable: max-line-length
import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { CommunicationsComponent } from '@app/pages/layers/private/research/block/question-list/answer-components/communications/communications.component';
import { OpenAnswerComponent } from '@app/pages/layers/private/research/block/question-list/answer-components/open-answer/open-answer.component';
import { SentenceCompletionComponent } from '@app/pages/layers/private/research/block/question-list/answer-components/sentence-completion/sentence-completion.component';
import { VocalAnswerComponent } from '@app/pages/layers/private/research/block/question-list/answer-components/vocal-answer/vocal-answer.component';
import { ImageAnswerComponent } from '@app/pages/layers/private/research/block/question-list/answer-components/image-answer/image-answer.component';
import { VideoAnswerComponent } from '@app/pages/layers/private/research/block/question-list/answer-components/video-answer/video-answer.component';
import { ClosedSingleComponent } from '@app/pages/layers/private/research/block/question-list/answer-components/closed-single/closed-single.component';
import { ClosedMultipleComponent } from '@app/pages/layers/private/research/block/question-list/answer-components/closed-multiple/closed-multiple.component';
import { AttitudeScaleComponent } from '@app/pages/layers/private/research/block/question-list/answer-components/attitude-scale/attitude-scale.component';
import { SemanticDifferentialsComponent } from '@app/pages/layers/private/research/block/question-list/answer-components/semantic-differentials/semantic-differentials.component';
import { GroupingCategoryComponent } from '@app/pages/layers/private/research/block/question-list/answer-components/grouping-category/grouping-category.component';
import { PreferenceRankingComponent } from '@app/pages/layers/private/research/block/question-list/answer-components/preference-ranking/preference-ranking.component';
import { PinOnMediaComponent } from '@app/pages/layers/private/research/block/question-list/answer-components/pin-on-media/pin-on-media.component';
import { ImageAssociationComponent } from '@app/pages/layers/private/research/block/question-list/answer-components/image-association/image-association.component';

@Injectable({
  providedIn: 'root'
})
export class QuestionTypesService extends BaseService implements BaseServiceI {
    // tslint:disable: variable-name
  constructor(public router: Router, public apiService: ApiService) {
    super(router, apiService, null);
  }

  _questionsTypes = [
      {
        type: 'communications',
        component: CommunicationsComponent
      },
      {
        type: 'open-answer',
        component: OpenAnswerComponent
      },
      {
        type: 'sentence-completion',
        component: SentenceCompletionComponent
      },
      {
        type: 'vocal-answer',
        component: VocalAnswerComponent
      },
      {
        type: 'image-answer',
        component: ImageAnswerComponent
      },
      {
        type: 'video-answer',
        component: VideoAnswerComponent
      },
      {
        type: 'closed-single',
        component: ClosedSingleComponent
      },
      {
        type: 'closed-multiple',
        component: ClosedMultipleComponent
      },
      {
        type: 'attitude-scale',
        component: AttitudeScaleComponent
      },
      {
        type: 'semantic-differentials',
        component: SemanticDifferentialsComponent
      },
      {
        type: 'grouping-category',
        component: GroupingCategoryComponent
      },
      {
        type: 'preference-ranking',
        component: PreferenceRankingComponent
      },
      {
        type: 'pin-on-media',
        component: PinOnMediaComponent
      },
      {
        type: 'image-association',
        component: ImageAssociationComponent
      }
  ];

  get questionTypes() {
      return this._questionsTypes;
  }

  getQuestionComponent(type: string) {
    return this._questionsTypes.filter(q => q.type === type);
  }
}
