<div class="">
    <div class="p-3 question-content" *ngIf="!showQuestion">
        <div class="sidebar-header d-flex align-items-center justify-content-end w-100 pb-3" >
            <button class="close-button text-center" (click)="goBackToHome()">
                <i class="fal fa-times"></i>
            </button>
        </div>

        <h4 class="sidebar-title mb-5" *ngIf="block">{{block.title}}</h4>


        <div clas="row" *ngIf="operation('loading-questions').submitting">
            <div class="col-12">
                <app-core-spinner [toggle]="true"></app-core-spinner>
            </div>
        </div>

        <div>
            <span *ngIf="questions && questions.length > 0 && hasNotDisabledQuestion(questions)">
                <!-- queste con bordo colorato -->
                <p class="mt-3 bold">
                    {{ 'UNANSWERED_QUESTIONS' | translate }}
                </p>
                <div *ngFor="let q of questions">
                    <div *ngIf="!q.answered_by_currentuser && !q.disabled" class="bg-white border-active shadow bold p-3 my-2 card d-flex flex-row align-items-center" (click)="openQuestion(q)">
                        <span class="flex-grow-0 mr-2">#{{ q.index }}:</span><span class="flex-grow-1 block-with-text">{{ q.title | striphtmlKeepSpaces }}</span><span class="flex-grow-0"><i class="far fa-chevron-right text-primary bold"></i></span>
                    </div>
                </div>
            </span>


            <!-- <span *ngIf="multipleAnswerQuestions && multipleAnswerQuestions.length > 0 && hasNotDisabledQuestion(multipleAnswerQuestions)">
                <p class="mt-5 bold" [innerHTML]="'UNDEFINED_ANSWER_NR_QUESTIONS' | translate">
                </p>

            </span> -->
            <p class="mt-5 bold">
                {{ 'ANSWERED_QUESTIONS' | translate }}
            </p>

            <span *ngIf="answeredMultipleAnswerQuestions && answeredMultipleAnswerQuestions.length > 0">
                <!-- queste con bordo colorato -->
                <div class="bg-white border-active shadow bold p-3 my-2 card" *ngFor="let q of answeredMultipleAnswerQuestions">
                    <span *ngIf="q.minimum_multiple_answers_nr && q.minimum_multiple_answers_nr >= 1" (click)="openQuestion(q)">
                        <div class="d-flex flex-row align-items-center" *ngIf="!q.disabled" [ngClass]="(q.disabled ? 'disabled' : '')" >
                            <span class="flex-grow-0 mr-2">#{{ q.index }}:</span><span class="flex-grow-1 block-with-text mr-2">{{ q.title | striphtmlKeepSpaces }}</span>
                            <span class="flex-grow-0 current-users-answers">
                                <i *ngIf="q.nr_current_user_answers < q.minimum_multiple_answers_nr && q.minimum_multiple_answers_nr > 1" class="far fa-exclamation-triangle text-orange bold mr-2"></i>
                                <span class="mr-2" *ngIf="q.minimum_multiple_answers_nr > 1">{{q.nr_current_user_answers || 0}} {{'ON' | translate}} {{q.minimum_multiple_answers_nr}}</span>
                                <i class="far fa-chevron-right text-primary bold"></i>
                            </span>
                        </div>
                        <div class="multiple-times-label">
                            <span >
                                {{'YOU_CAN_ANSWER_MULTIPLE_TIMES' | translate}}
                            </span>
                        </div>
                    </span>
                </div>
            </span>

            <span *ngIf="answeredQuestions && answeredQuestions.length > 0">
                <!-- queste senza bordo colorato -->
                <div class="bg-white border shadow bold p-3 my-2 card" *ngFor="let q of answeredQuestions">
                    <span *ngIf="!q.minimum_multiple_answers_nr || q.minimum_multiple_answers_nr < 1">
                        <div class="d-flex flex-row align-items-center" [ngClass]="(q.disabled ? 'disabled' : '')" (click)="openQuestion(q)">
                            <span class="flex-grow-0 mr-2">#{{ q.index }}:</span><span class="flex-grow-1 block-with-text">{{ q.title | striphtmlKeepSpaces }}</span><span class="flex-grow-0"><i class="far fa-chevron-right text-primary bold"></i></span>
                        </div>
                    </span>
                </div>
            </span>


        </div>

    </div>

    <div *ngIf="!questions && !loading">
        {{ 'NO_AVAILABLE_QUESTIONS' | translate }}
    </div>

    <div class="slide" [className]="showQuestion ? 'slide-in' : 'slide-out'">
        <app-answer-group *ngIf="showQuestion" [question]="currentQuestion" [user]="user" [block]="block" (hide)="closeAnswer()" (answered)="unlockNextQuestion()"></app-answer-group>
    </div>

</div>