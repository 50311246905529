import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { BlockModel } from '@app/shared/models/block.model';
import 'quill-emoji/dist/quill-emoji.js';
import { OpenAnswer } from '../models/open-answer';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AnswerService } from '@app/shared/services/answer.service';
import { TeamModel } from '@app/shared/models/team.model';
import { NotificationService } from '@app/shared/services/notification.service';
import { AnswerBaseComponent } from '../answer-base-component';
import { BlockService } from '@app/shared/services/block.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-open-answer',
  templateUrl: './open-answer.component.html',
  styleUrls: ['./open-answer.component.scss']
})
export class OpenAnswerComponent extends AnswerBaseComponent implements OnInit {
  // tslint:disable: prefer-for-of

  @Input() questionInput: QuestionModel;
  @Input() blockInput: BlockModel;
  @Input() userInput: TeamModel;
  @Output() closeAnswerInput = new EventEmitter();

  constructor(
    public toastService: ToastrService,
    public translateService: TranslateService,
    public answerService: AnswerService,
    public notificationService: NotificationService,
    public blockService: BlockService,
    private sanitizer: DomSanitizer
  ) {
    super(toastService, translateService, answerService, notificationService, blockService);
    this.modules = {
      'emoji-shortname': !this.isMobile(),
      'emoji-textarea': !this.isMobile(),
      'emoji-toolbar': !this.isMobile(),
      toolbar: this.isMobile() ? [] : [
        ['clean', 'bold', 'italic', 'underline', 'strike'],
        ['link'],
        ['emoji']
      ]
    };
  }


  answer: OpenAnswer = {
    text: null,
    images: [],
    videos: []
  };

  ngOnInit(): void {
    this.initialize(this.questionInput, this.blockInput, this.userInput, this.closeAnswerInput, this.answer);
  }

  previewVideos() {
    this.videoPreviews = [];
    for (const vid of this.answer.videos) {
      if (vid.video) {
        this.videoPreviews = [...this.videoPreviews, this.sanitizer.bypassSecurityTrustUrl(vid.video)];
      } else {
        this.videoPreviews = [...this.videoPreviews, vid.video_url];
      }
    }
  }



}
