import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from './core/services/auth.service';
import { ResponseModel } from './core/models/response.model';
import { Router } from '@angular/router';
import { CountryService } from './shared/services/country.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(
    private authService: AuthService,
    private router: Router,
    private countryService: CountryService,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit() {
    this.authService.current().subscribe((res: ResponseModel) => {

    }, (err) => {
      this.router.navigate(['login']);
    });

    if (this.countryService.getCountryCode()) {
      this.countryService.getCurrentCountry(true).subscribe((res: ResponseModel) => {
        this.translateService.use(res.getData().language_code);
      })
    }
  }

  ngOnDestroy() {
  }
}


