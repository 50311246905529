import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-sicche-emoticon',
  templateUrl: './sicche-emoticon.component.html',
  styleUrls: ['./sicche-emoticon.component.scss']
})
export class SiccheEmoticonComponent implements OnInit, OnChanges {

  @Input() emoticon: string | number; // 1 | 2 | 3
  @Input() width = 43;

  constructor() { }

  ngOnInit() {
    this.init();
  }

  init() {
    this.emoticon = parseInt(`${this.emoticon}`, 10);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

}

