import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  currentPage: string;
  realNameVisible: boolean;
  canAcceptPrivacy: boolean;

  constructor() { }

  ngOnInit(): void {
    this.currentPage = "boarding";
    this.realNameVisible = false;
    this.canAcceptPrivacy = false
  }

  showRealName() {
    this.realNameVisible = !this.realNameVisible;
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    if (event.srcElement.scrollTop >= 164) {
      this.canAcceptPrivacy = true;
    }
  }

  askUncheck() {
    alert("Sicuro di non voler accettare? Potrai continuare solo accettando la privacy")
  }
}
