import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { BlockModel } from '@app/shared/models/block.model';
import { TeamModel } from '@app/shared/models/team.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AnswerService } from '@app/shared/services/answer.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { OpenAnswer } from '../models/open-answer';
import { AnswerBaseComponent } from '../answer-base-component';
import { SentenceCompletion } from '../models/sentence-completion';
import { BlockService } from '@app/shared/services/block.service';

@Component({
  selector: 'app-sentence-completion',
  templateUrl: './sentence-completion.component.html',
  styleUrls: ['./sentence-completion.component.scss']
})
export class SentenceCompletionComponent extends AnswerBaseComponent implements OnInit {

  @Input() questionInput: QuestionModel;
  @Input() blockInput: BlockModel;
  @Input() userInput: TeamModel;
  @Output() closeAnswerInput = new EventEmitter();
  nrInputs: number[] = [];

  constructor(
    public toastService: ToastrService,
    public translateService: TranslateService,
    public answerService: AnswerService,
    public notificationService: NotificationService,
    public blockService: BlockService
  ) {
    super(toastService, translateService, answerService, notificationService, blockService);
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['link'],
        ['emoji']
      ]
    };
  }


  answer: SentenceCompletion = {
    completion_comments: [],
    comment: null
  };

  ngOnInit(): void {
    this.initialize(this.questionInput, this.blockInput, this.userInput, this.closeAnswerInput, this.answer);
    this.nrInputs = Array(parseInt(this.questionInput.question_data.n_required_words, 10)).fill(0).map((e, i) => i);
  }

  submit() {
    this.answer.completion_comments = this.answer.completion_comments.map(c => c.trim().replace(/  +/g, ' '));
    super.submit();
  }
}
