import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { BlockModel } from '@app/shared/models/block.model';
import { ResponseModel } from '@app/core/models/response.model';
import { ClosedMultiple } from '../models/closed-multiple';
import { AnswerService } from '@app/shared/services/answer.service';
import { TeamModel } from '@app/shared/models/team.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AnswerModel } from '@app/shared/models/answer.model';
import { OpenAnswer } from '../models/open-answer';
import { CommentModel } from '@app/shared/models/comment.model';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { ClosedSingle } from '../models/closed-single';
import { NotificationService } from '@app/shared/services/notification.service';
import { AnswerBaseComponent } from '../answer-base-component';
import { BlockService } from '@app/shared/services/block.service';
import { GroupingCategory } from '../models/grouping-category';

@Component({
  selector: 'app-grouping-category',
  templateUrl: './grouping-category.component.html',
  styleUrls: ['./grouping-category.component.scss']
})
export class GroupingCategoryComponent extends AnswerBaseComponent implements OnInit {


  @Input() questionInput: QuestionModel;
  @Input() blockInput: BlockModel;
  @Input() userInput: TeamModel;
  @Output() closeAnswerInput = new EventEmitter();

  answer: GroupingCategory[] = [];


  constructor(
    public toastService: ToastrService,
    public translateService: TranslateService,
    public answerService: AnswerService,
    public notificationService: NotificationService,
    public blockService: BlockService
  ) {
    super(toastService, translateService, answerService, notificationService, blockService);
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['link'],
        ['emoji']
      ]
    };
  }

  ngOnInit(): void {
    this.initialize(this.questionInput, this.blockInput, this.userInput, this.closeAnswerInput, this.answer);
    if (this.question.question_data.randomize_order) {
      this.question.question_data.answers = this.randomizeOrder(this.question.question_data.answers);
    }
  }

  getAnswerFromId(id: number) {
    return this.question.question_data.answers.find(a => a.id === id);
  }

  checkAnswer(id: number, category: string) {
    this.answer = this.answer.filter(a => a.optionid !== id); // remove old (if there is)
    this.answer = [...this.answer, { optionid: id, category }];  // push new
  }

  isChecked(id: number, category: string) {
    const temp = this.answer.find(a => a.optionid === id);
    return (temp ? temp.category === category : false);
  }
}
