<div class="container-fluid">
    <div class="row mb-3">
        <div class="col bg-orange p-3 pb-4" id="heading" style="border-bottom-left-radius: 2000% 600px;border-bottom-right-radius: 5500% 3000px;">
            <div class="d-flex justify-content-between align-items-center">
                <div class="" *ngIf="user">
                  <div class="profile-image" (click)="openProfileSidebar()" style="background-image: url({{user.avatar_url | privatesrc}})">
                    <!--<img [src]="'https://www.w3schools.com/howto/img_avatar.png'" width="45px" height="45px" data-toggle="modal" data-target="#modalProfile">-->
                    <!-- <img [src]="(user.avatar_url | privatesrc) || '../../../../../assets/images/avatar.png'" width="45px" height="45px" data-toggle="modal" data-target="#modalProfile"> -->
                    <!--<img *ngIf="user" [src]="user.avatar_url | privatesrc" alt="user avatar">-->
                    <i class="fal fa-bars"></i>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                    <span class="notification" (click)="openNotificationSidebar()">
                        <i class="fal fa-bell" [ngClass]="noticesCount && noticesCount > 0 ? 'ring-bell-animation' : ''"></i>
                        <span class="notification-number">{{noticesCount}}</span>
                    </span>
                </div>
            </div>
            <div class="container" *ngIf="user">
                <!-- <button type="button" (click)="logout()">LOGOUT</button> -->
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h3>{{'HELLO' | translate}} {{user.nickname || user.firstname}}!</h3>
                        <p>{{'RESEARCH_INSTRUCTIONS' | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row mb-4" *ngIf="country">
        <div class="col-md-12">
            <app-core-spinner [toggle]="loadingDays"></app-core-spinner>
            <h1 class="h2 mb-2"><b>{{country.title}}</b></h1>
            <div class="row">
                <!-- <div class="col-6">
                    <p><i class="fal fa-calendar-alt f-16"></i>
                        {{'FROM' | translate}} {{country.research_date_start_effective | dateToDayMonth}}
                        {{'TO' | translate}} {{country.research_date_end_effective | dateToDayMonth}}
                    </p>
                </div> -->
                <div class="col-12 text-center" *ngIf="!loadingDays">
                    <p class="mb-0 mt-4"><i class="fal fa-check mr-2"></i> <b>{{completionPerc}}% {{'COMPLETED' | translate}}</b></p>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2 justify-content-center">
        <div class="col-12 mb-4 text-center" *ngIf="country && (!allBlocks || allBlocks.length === 0) && !loadingBlocks && country.research_date_start_effective">
            <div class="row">
                <div class="col-md-2 offset-md-5">
                    <img src="assets/images/sicche_icon_default.png" alt="" class="img-fluid" style="max-width: 250px">
                </div>
            </div>
            {{'STILL_NO_BLOCKS' | translate: {param1: (country.research_date_start_effective | dateToLocaleString), param2: (country.   research_date_start_effective | dateToHourMinutes:meridian)} }}
        </div>
        <div class="col-8 col-md-4 col-sm-3" *ngIf="blocks">
            <!-- Non è centrato nella riga! Nemmeno con no-gutters sulla row-->
            <div class="form-group" *ngIf="day">
                <app-core-select
                    [classes]="'form-control rounded-select'"
                    [placeholder]="'CHOOSE'"
                    [cache]="true"
                    [customList]="days"
                    [value]="'date'"
                    [label]="'name'"
                    [(ngmodel)]="day.date"
                    (change)="changeDate($event)"
                    >
                </app-core-select>
                <i class="fal fa-check input-check" *ngIf="dayIsCompleted"></i>
            </div>
        </div>
    </div>

    <div clas="row">
        <div class="col-12">
            <app-core-spinner [toggle]="operation('loading-blocks').submitting"></app-core-spinner>
        </div>
    </div>
    <!-- <div class="row mb-3">
        <ul class="nav nav-pills mx-auto w-fit-content">
            <li class="nav-item" *ngFor="let r of rooms" [className]="(room.id == r.id ? 'active' : '')">
                <a (click)="setRoom(r)" class="nav-link">{{ r.title }}</a>
            </li>
        </ul>
    </div> -->
    <div class="row mb-0 text-right" *ngIf="!operation('loading-blocks').submitting && blocks && blocks.length > 0">

        <div class="text-center w-100 p-3" *ngIf="blocks.length === 0 && diaryBlocks.length === 0">
            {{"NO_BLOCKS_FOR_TODAY" | translate}}
        </div>

        <div class="w-100 text-right overflow-hidden" [flickity]="{
            freeScroll: true,
            contain: true,
            pageDots: false,
            percentPosition: false,
            cellAlign: 'left',
            groupCells: true
        }" class="w-100 text-right overflow-hidden" #ds>
            <div *ngIf="day">
                <app-block [flickityChild] *ngFor="let b of blocks" [block]="b" [day]="day.date" (click)="openQuestionList(b)" class="scroll-item bg-white"></app-block>
            </div>
        </div>

    </div>

    <div class="row mb-0 mt-n3" *ngIf="highlightedBlocks && highlightedBlocks.length > 0">
        <div class="w-100 text-right" [flickity]="{
            freeScroll: true,
            contain: true,
            pageDots: false,
            percentPosition: false,
            cellAlign: 'left',
            groupCells: true
        }" class="w-100 text-right" #ds>
            <div *ngIf="day">
                <app-block [flickityChild] *ngFor="let b of highlightedBlocks" [day]="day.date"  [block]="b" (click)="openQuestionList(b)" class="scroll-item bg-white"></app-block>
            </div>
        </div>
    </div>

    <div class="row mb-0 mt-n3" *ngIf="diaryBlocks && diaryBlocks.length > 0">
        <div class="col d-flex align-items-center justify-content-center mb-3">
            <small>{{'DIARY' | translate}}</small>
        </div>
        <div class="w-100 text-right" [flickity]="{
            freeScroll: true,
            contain: true,
            pageDots: false,
            percentPosition: false,
            cellAlign: 'left',
            groupCells: true
        }" class="w-100 text-right" #ds>
            <div *ngIf="day">
                <app-block [flickityChild] *ngFor="let b of diaryBlocks" [day]="day.date"  [block]="b" (click)="openQuestionList(b)" class="scroll-item bg-white"></app-block>
            </div>
        </div>
    </div>

</div>