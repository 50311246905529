<div class="p-1">
    <div class="row no-gutters mb-2">
        <div class="col-md-12">
            <h3>Domanda #2</h3>
        </div>
    </div>
    <div class="row no-gutters mb-2">
        <div class="col-md-12">
            <p>Quali tra queste immagini ti fanno pensare al concetto di <b>biologico?</b>Scegline <b>almeno 3,</b> per
                ciascuna aggiungi una <b>breve spiegazione</b> del motivo per cui l'hai scelta</p>
        </div>
    </div>
    <div class="row-no-gutters overflow-hidden">
        <div class="col-md-4">
            <!-- <img src="../../../../../../assets/images/blogimage.png" class="img-fluid"> -->
        </div>
    </div>
    <div class="row no-gutters align-items-end">
        <div class="col-md-12 text-center">
            <button type="button" class="btn-sicche mt-auto">
                Rispondi<i class="fal fa-reply ml-2 mr-0"></i>
            </button>
        </div>
    </div>
</div>