import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, TemplateRef, QueryList, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { BlockModel } from '@app/shared/models/block.model';
import { TeamModel } from '@app/shared/models/team.model';
import { AnswerBaseComponent } from '../answer-base-component';
import { PinOnMedia } from '../models/pin-on-media';
import { AnswerService } from '@app/shared/services/answer.service';
import { BlockService } from '@app/shared/services/block.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, MinLengthValidator, Validators } from '@angular/forms';

@Component({
  selector: 'app-pin-on-media',
  templateUrl: './pin-on-media.component.html',
  styleUrls: ['./pin-on-media.component.scss']
})
export class PinOnMediaComponent extends AnswerBaseComponent implements OnInit {

  @Input() questionInput: QuestionModel;
  @Input() blockInput: BlockModel;
  @Input() userInput: TeamModel;
  @Output() closeAnswerInput = new EventEmitter();

  @ViewChild('viewVideo') videoplayer: ElementRef;
  videoPlays = 0;
  videoCurrentTime = 0;
  fullscreen = false;

  formSteps = [];
  currentStep = null;
  ratings: number[] = [];
  form: UntypedFormGroup;
  answer: PinOnMedia;
  imageTimer: number;

  constructor(
    public toastService: ToastrService,
    public translateService: TranslateService,
    public answerService: AnswerService,
    public notificationService: NotificationService,
    public blockService: BlockService,
  ) {
    super(toastService, translateService, answerService, notificationService, blockService);
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['link'],
        ['emoji']
      ]
    };
  }

  ngOnInit(): void {
     console.log('answer', this.answers)
    this.initialize(this.questionInput, this.blockInput, this.userInput, this.closeAnswerInput, this.answer);
    this.form = new UntypedFormGroup({
      pins: new UntypedFormArray(
        [],
        this.question.question_data.require_pins
          ? this.minLengthArray(this.question.question_data.pins_min_num)
          : null
      ),
      rating: new UntypedFormControl('', this.question.question_data.require_rating ? Validators.required : null),
      comment: new UntypedFormControl('', this.question.question_data.require_comment ? Validators.required : null),
    });

    this.ratings = this.question.question_data.require_rating
      ? Array(this.question.question_data.rating_range === '0_10' ? 11 : 7).fill(0)
        .map((e, i) => this.question.question_data.rating_range === '0_10' ? i : (i + 1))
      : [];

    this.formSteps = [
      { id: 'intr', couldPrev: false, couldNext: true, canNext: () => true, nextTxt: this.translateService.instant('VIEW_MEDIA') },
      { id: 'view', couldPrev: false, couldNext: true, canNext: () => this.canAdvanceMediaContent() },
    ];

    if (this.question.question_data.require_rating) {
      this.formSteps.push(
        { id: 'rate', couldPrev: false, couldNext: true, canNext: () => this.form.controls.rating.valid },
      );
    }

    if (this.question.question_data.require_comment) {
      this.formSteps.push({ id: 'comm', couldPrev: false, couldNext: true, canNext: () => this.form.controls.comment.valid });
    }

    if (this.question.question_data.require_pins) {
      this.formSteps.push({ id: 'pins', couldPrev: false, couldNext: true, canNext: () => this.form.controls.pins.valid });
    }
    this.imageTimer = this.question.question_data.image_max_seconds;
    this.formSteps[this.formSteps.length - 1].couldNext = false;
    this.currentStep = 0;
  }

  minLengthArray = (min: number) => {
    return (c: AbstractControl): { [key: string]: any } => {
      return c.value.length >= min ? null : { MinLengthArray: true };
    };
  }

  canAdvanceMediaContent(): boolean {
    // return true;
    return this.question.question_data.media_type === 'video'
      ? this.videoPlays >= 1
      : false;
  }

  goFullScreen(video: any) {
    this.fullscreen = !this.fullscreen;
  }

  updateVideoCurrentTime(event: any) {
    this.videoCurrentTime = parseInt(event.target.currentTime);
  }

  num(n: string): number {
    return parseInt(n);
  }

  isPlaying(video: any) {
    return !!(!video.paused && !video.ended && video.readyState > 2);
  }

  videoEnd(event: any) {
    this.videoPlays++;
    if (this.question.question_data.video_max_views && this.videoPlays >= this.question.question_data.video_max_views) {
      this.nextStep();
    }
  }

  imageStart() {
    if (this.question.question_data.image_max_seconds) {
      const timer = () => {
        this.imageTimer--;
        if (this.imageTimer <= 0) {
          this.currentStep = 2; // salta al terzo
        } else {
          setTimeout(() => timer(), 1000);
        }
      };
      setTimeout(() => timer(), 1000);
    }
  }

  imageNext() { // click on image in full screen view
    if (!this.question.question_data.image_max_seconds) {
      this.nextStep();
    }
  }

  nextStep() {
    this.currentStep = this.currentStep + 1;
  }

  prevStep() {
    this.currentStep = this.currentStep - 1;
  }

  submit() {
    this.answer = this.form.getRawValue();
    super.submit();
  }
}
