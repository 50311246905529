// tslint:disable: max-line-length

import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
// App.component
import { AppComponent } from './app.component';

// info: need to be imported in every child module
import {TranslateModule } from '@ngx-translate/core';

// flickity carousel
import { FlickityModule } from 'ngx-flickity';


// Pages
import { PublicComponent } from '@app/pages/layers/public/public.component';
import { PrivateComponent } from '@app/pages/layers/private/private.component';
import { HomeComponent } from './pages/layers/public/home/home.component';
import { AuthComponent } from './pages/layers/private/auth/auth.component';
import { LoginComponent } from './pages/layers/public/login/login.component';
import { HeaderComponent } from './pages/layers/public/components/header/header.component';
import { QuestionComponent } from './pages/layers/public/components/question/question.component';
import { ResearchComponent } from './pages/layers/private/research/research.component';
import { QuestionBlockComponent } from './pages/layers/private/components/question-block/question-block.component';
import { QuestionContainerComponent } from './pages/layers/private/components/question-container/question-container.component';
import { BlockComponent } from './pages/layers/private/research/block/block.component';
import { QuestionListComponent } from './pages/layers/private/research/block/question-list/question-list.component';
import { ResetPasswordComponent } from './pages/layers/public/login/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './pages/layers/public/login/forgot-password/forgot-password.component';
import { AnswerGroupChatComponent } from './pages/layers/private/research/block/question-list/answer-group-chat/answer-group-chat.component';
import { AnswerHostDirective } from './pages/layers/private/research/block/question-list/answer-group-chat/answer-host.directive';
import { CommunicationsComponent } from './pages/layers/private/research/block/question-list/answer-components/communications/communications.component';
import { OpenAnswerComponent } from './pages/layers/private/research/block/question-list/answer-components/open-answer/open-answer.component';
import { SentenceCompletionComponent } from './pages/layers/private/research/block/question-list/answer-components/sentence-completion/sentence-completion.component';
import { VocalAnswerComponent } from './pages/layers/private/research/block/question-list/answer-components/vocal-answer/vocal-answer.component';
import { ImageAnswerComponent } from './pages/layers/private/research/block/question-list/answer-components/image-answer/image-answer.component';
import { VideoAnswerComponent } from './pages/layers/private/research/block/question-list/answer-components/video-answer/video-answer.component';
import { ClosedSingleComponent } from './pages/layers/private/research/block/question-list/answer-components/closed-single/closed-single.component';
import { ClosedMultipleComponent } from './pages/layers/private/research/block/question-list/answer-components/closed-multiple/closed-multiple.component';
import { AttitudeScaleComponent } from './pages/layers/private/research/block/question-list/answer-components/attitude-scale/attitude-scale.component';
import { SemanticDifferentialsComponent } from './pages/layers/private/research/block/question-list/answer-components/semantic-differentials/semantic-differentials.component';
import { GroupingCategoryComponent } from './pages/layers/private/research/block/question-list/answer-components/grouping-category/grouping-category.component';
import { PreferenceRankingComponent } from './pages/layers/private/research/block/question-list/answer-components/preference-ranking/preference-ranking.component';
import { PinOnMediaComponent } from './pages/layers/private/research/block/question-list/answer-components/pin-on-media/pin-on-media.component';
import { ImageAssociationComponent } from './pages/layers/private/research/block/question-list/answer-components/image-association/image-association.component';
import { SetCountryComponent } from './pages/layers/public/set-country/set-country.component';
import { NicknameSelectionComponent } from './pages/layers/private/onboarding/nickname-selection/nickname-selection.component';
import { PrivacyPolicyComponent } from './pages/layers/private/onboarding/privacy-policy/privacy-policy.component';
import { SummaryComponent } from './pages/layers/private/onboarding/summary/summary.component';
import { OnboardingComponent } from './pages/layers/private/onboarding/onboarding.component';
import { PinOnVideoComponent } from './pages/layers/private/research/block/question-list/answer-components/pin-on-media/pin-on-video/pin-on-video.component';
import { PinOnImageComponent } from './pages/layers/private/research/block/question-list/answer-components/pin-on-media/pin-on-image/pin-on-image.component';

// html editor
import { QuillModule } from 'ngx-quill';
import { ProfileSidebarComponent } from './pages/layers/private/profile-sidebar/profile-sidebar.component';
import { ProfileComponent } from './pages/layers/private/profile-sidebar/profile/profile.component';
import { InstructionsComponent } from './pages/layers/private/profile-sidebar/instructions/instructions.component';
import { DemoComponent } from './pages/layers/private/profile-sidebar/demo/demo.component';
import { FaqComponent } from './pages/layers/private/profile-sidebar/faq/faq.component';
import { PasswordSelectionComponent } from './pages/layers/private/onboarding/password-selection/password-selection.component';
import { NotificationListComponent } from './pages/layers/private/components/notification-list/notification-list.component';
import { CommentsLikeComponent } from './pages/layers/private/research/block/question-list/answer-components/comments-like/comments-like.component';
import { SetDayBlockComponent } from './pages/layers/public/set-day-block/set-day-block.component';
import { LockedComponent } from './pages/layers/private/locked/locked.component';
import { ChoicesCommentsComponent } from './pages/layers/private/research/block/question-list/answer-components/image-association/choices-comments/choices-comments.component';

@NgModule({
    declarations: [
        AppComponent,
        // Pages
        PublicComponent,
        PrivateComponent,
        HomeComponent,
        AuthComponent,
        LoginComponent,
        HeaderComponent,
        QuestionComponent,
        ResearchComponent,
        QuestionBlockComponent,
        QuestionContainerComponent,
        BlockComponent,
        QuestionListComponent,
        ResetPasswordComponent,
        ForgotPasswordComponent,
        AnswerGroupChatComponent,
        AnswerHostDirective,
        CommunicationsComponent,
        OpenAnswerComponent,
        SentenceCompletionComponent,
        VocalAnswerComponent,
        ImageAnswerComponent,
        VideoAnswerComponent,
        ClosedSingleComponent,
        ClosedMultipleComponent,
        AttitudeScaleComponent,
        SemanticDifferentialsComponent,
        GroupingCategoryComponent,
        PreferenceRankingComponent,
        PinOnMediaComponent,
        PinOnVideoComponent,
        PinOnImageComponent,
        ImageAssociationComponent,
        SetCountryComponent,
        NicknameSelectionComponent,
        PrivacyPolicyComponent,
        SummaryComponent,
        OnboardingComponent,
        ProfileSidebarComponent,
        ProfileComponent,
        InstructionsComponent,
        DemoComponent,
        FaqComponent,
        PasswordSelectionComponent,
        NotificationListComponent,
        CommentsLikeComponent,
        SetDayBlockComponent,
        LockedComponent,
        ChoicesCommentsComponent
    ],
    imports: [
        ReactiveFormsModule,
        FormsModule,
        BrowserModule,
        RouterModule,
        DragDropModule,
        AppRoutingModule,
        TranslateModule,
        CoreModule,
        FlickityModule,
        SharedModule,
        QuillModule.forRoot()
    ],
    providers: [],
    bootstrap: [AppComponent],
    exports: [TranslateModule]
})
export class AppModule { }
