import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { CountryModel } from '../models/country.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountryService extends BaseService implements BaseServiceI {

  constructor(public router: Router, public apiService: ApiService, private http: HttpClient) {
    super(router, apiService, CountryModel);
  }

  currentCountryUniqid: string;
  currentCountry$: CountryModel;

  getCountry(id: string, countryCode: string) {
    return this.get(`${countryCode}`, {include_stats: true});
  }

  getCurrentCountry(guest = false) {
    if (this.getCountryCode()) {
      if (guest) {
        return this.get(this.getCountryCode() + '/guest-data');
      } else {
        return this.get(this.getCountryCode(), {include_stats: true});
      }
    }
  }

  getSessionCountry() {
    return this.currentCountry;
  }

  get currentCountry() {
    return this.currentCountry$;
  }

  set currentCountry(country: CountryModel) {
    this.currentCountry$ = country;
  }

  setCountryCode(country_uniqid: string) {
    localStorage.removeItem('country_uniqid');
    try {
      localStorage.setItem('country_uniqid', country_uniqid);
      this.currentCountryUniqid = country_uniqid;
      console.log(`Set country_uniqid: ${this.currentCountryUniqid}`);
    } catch (error) {
      console.log(error)
      return false;
    }
    return true;
  }

  getCountryCode() {
    const uniqid = localStorage.getItem('country_uniqid');
    return uniqid;
  }

  getCountryActiveDays(country_uniqid: string, no_future_days: boolean) {
    return this.get(country_uniqid + '/get-active-days', {no_future_days}, true);
  }

}
