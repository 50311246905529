import { Injectable, EventEmitter } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { User } from '../models/user.model';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { ResponseModel } from '@app/core/models/response.model';
import { TeamModel } from '../models/team.model';
import { CountryService } from './country.service';
import { catchError, map } from 'rxjs/operators';
import { StorageService } from '@app/core/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class TeamService extends BaseService implements BaseServiceI {

  constructor(public router: Router, public apiService: ApiService, private countryService: CountryService) {
    super(router, apiService, TeamModel);
  }

  currentTeam$: TeamModel;

  userUpdated = new EventEmitter();
  isLocked$: boolean;

  set isLocked(isLocked: boolean) {
    this.isLocked$ = isLocked;
  }
  get isLocked(): boolean {
    return this.isLocked$;
  }

  getCurrentUserTeam(reset = false) {
    let storage = new StorageService();
    if(!reset && storage.get('current_user')) {
      return new Observable(subscriber => {
        subscriber.next(new ResponseModel(storage.get('current_user')))
      })
    }
    // tslint:disable-next-line: variable-name
    const country_uniqid = this.countryService.getCountryCode();
    return this.get('current_user', {country_uniqid}).pipe(
      map((item: ResponseModel) => {
        storage.set('current_user', item);
        return item;
      }),
      catchError(null)
    );
  }

  updateCurrentUserTeam(user: TeamModel) {
    // TODO remove for prod
    user.country_uniqid = this.countryService.getCountryCode();
    user.role = 'INTERVIEWED';
    return this.sub('add_update_user').create(user);
  }

  set currentTeam(team: TeamModel) {
    this.currentTeam$ = team;
  }

  get currentTeam() {
    return this.currentTeam$;
  }


}
