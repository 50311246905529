import {Pipe, PipeTransform} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CountryService } from '../services/country.service';

@Pipe({
    name: 'dateAgo',
    pure: true
})
export class DateAgoPipe implements PipeTransform {

    constructor(
        private translate: TranslateService,
        private countryService: CountryService
        ) {}

    transform(value: any, printAgo: any = true): any {
        if (value) {
            if (typeof value === 'string' && value.indexOf('-') !== -1) {
                value = this.replaceAll(value, '-', '/');
            }

            let d = new Date(value);
            if (this.countryService.getSessionCountry()) {
                d = this.convertTZ(d, this.countryService.getSessionCountry().timezone);
            }

            const seconds = Math.floor((+new Date() - +d) / 1000);
            if (seconds < 29) { // less than 30 seconds ago will show as 'Just now'
                return this.translate.instant('JUST_NOW');
            }
            const intervals = {
                'YEAR': 31536000,
                'MONTH': 2592000,
                'WEEK': 604800,
                'DAY': 86400,
                'HOUR': 3600,
                'MINUTE': 60,
                'SECOND': 1
            };
            let counter: number;
            for (const i in intervals) {
                if (intervals[i]) {
                    counter = Math.floor(seconds / intervals[i]);
                    if (counter > 0) {
                        if (counter === 1) {
                            // 20211109 ticket 1198 rimosso ago
                            // singular
                            return counter + ' ' + this.translate.instant(i);
                            // return counter + ' ' + this.translate.instant(i) + ' ' + (printAgo ? this.translate.instant('AGO') : '');
                        } else {
                            // plural
                            return counter + ' ' + this.translate.instant(i + 'S');
                            // return counter + ' ' + this.translate.instant(i + 'S') + ' ' + (printAgo ? this.translate.instant('AGO') : '');
                        }
                    }
                }
            }
        }
        return value;
    }

    convertTZ(date, timezone: string) {
        const localeTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const dateFromServer = new Date(date)

        const dateFromServerOffset = getOffset(dateFromServer, timezone)
        const dateFromServerUTC = new Date(dateFromServer.getTime() - dateFromServerOffset)

        const localeOffset = getOffset(new Date(), localeTimezone)

        const finalDate = new Date(dateFromServerUTC.getTime() + localeOffset)

        return finalDate

        function getOffset(d, tz) {
            const a = d.toLocaleString("ja", {timeZone: tz}).split(/[/\s:]/);
            a[1]--;
            const t1 = Date.UTC.apply(null, a);
            const t2 = new Date(d).setMilliseconds(0);
            return -(t2 - t1);
        }
    }

    replaceAll(str, search, replace) {
        return str.replace(new RegExp(search, 'g'), replace);
    }
}
