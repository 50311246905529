<div class="container">
    <div class="row">
        <div class="col-12 col-xl-6 offset-xl-3 p-4 pt-5">
            <div class="text-center mt-5" *ngIf="user">
                <h1 class="h2">{{'HELLO' | translate}} {{user.nickname}}!</h1>
                <p class="text-left" [innerHTML]="'SUMMARY.FIRST_BLOCK' | translate"></p>
                <p>{{ 'AT_DATETIME' | translate: {date: user.datetime_first_block | dateToLocaleString, time: user.datetime_first_block | dateToHourMinutes: meridian } }}</p>
                <p class="text-left" [innerHTML]="'SUMMARY.SECOND_BLOCK' | translate"></p>
                <p class="text-left" [innerHTML]="'SUMMARY.THIRD_BLOCK' | translate"></p>
                <p class="text-left" class="bold mt-3" [innerHTML]="'SUMMARY.THANKS' | translate"></p>

                <!--<a [routerLink]="['/research']" class="btn-sicche mt-3" style="color:white">{{'CONTINUE' | translate}}</a>-->
                <button (click)="goToResearch()" class="btn-sicche mt-4" style="color:white">{{'CONTINUE' | translate}}</button>
            </div>
        </div>
    </div>
</div>