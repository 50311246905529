import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { AnswerModel } from '../models/answer.model';
import { BlockService } from './block.service';
import { DatetimeToDbPipe } from '@app/core/pipes/datetime-to-db.pipe';
import { CountryService } from './country.service';


@Injectable({
  providedIn: 'root'
})
export class AnswerService extends BaseService implements BaseServiceI {
  // tslint:disable: variable-name

  constructor(
    public router: Router,
    public apiService: ApiService,
    private http: HttpClient,
    public blockService: BlockService,
    private dateToDb: DatetimeToDbPipe,
    private countryService: CountryService
  ) {
    super(router, apiService, AnswerModel);
  }

  createAnswer(questionid: number, answerData: any, form: FormData = new FormData()) {
    console.log(answerData);
    const device = (this.isMobile() ? 'MOBILE' : 'DESKTOP');
    const data = {
      questionid,
      answer_data: answerData,
      device,
      diary_datetime: this.dateToDb.transform(this.blockService.currentDay.date, this.countryService.currentCountry.timezone)
    };
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        let element = data[key];
        if (typeof element === 'object') {
          element = JSON.stringify(element);
        }
        form.append(key, element);
      }
    }

    return this.apiService.postFormData('/answer-management/answers', form);
    return this.sub('answers').create(data);
    // return this.sub('answers').create(data);
  }

  createCommment(answerid: number, comment: string, commentid: number = null, private_userid = null, form: FormData = new FormData(), otherdata = {}) {
    form.append('answerid', answerid.toString());
    form.append('comment', comment ? comment : '');
    form.append('commentid', commentid ? commentid.toString() : null);
    form.append('private_userid', private_userid);
    form.append('comment_otherdata', JSON.stringify(otherdata));

    // return this.sub('comments').create({answerid, comment, commentid, private_userid});
    return this.apiService.postFormData('/answer-management/comments', form);
  }

  getAnswers(questionid) {
    return this.get('answers', {questionid, include_comment: true});
  }

  getUserAnswer(questionid, include_comment = true) {
    // tslint:disable-next-line: max-line-length
    return this.get('answers', {questionid, include_comment, only_current_user: true, diary_datetime: this.dateToDb.transform(this.blockService.currentDay.date, this.countryService.currentCountry.timezone)});
  }

  likeComment(answerid: number, commentid: number) {
    return this.sub('comments').create({answerid, flag_like: true, parentid: commentid});
  }
  like(answerid) {
    return this.sub('comments').create({answerid, flag_like: true});
  }
  unlike(answerid: number, commentid = null) {
    return this.sub(`remove_like/${answerid}`).create({commentid});
  }

  deleteComment(commentid: number) {
    return this.deleteById('/answer-management/comments/', commentid.toString());
  }

  isMobile() {
    let isMobile = false;
    // tslint:disable: max-line-length
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
        isMobile = true;
    }
    return isMobile;
  }

}
