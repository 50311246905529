<div class="row">
    <div class="col-md-3 p-2" *ngFor="let a of answer">
        <div class="panel shadow">
            <div>
                <img  class="w-100 aspect-ratio-1-1" [src]="getImageUrlByAnswer(a)">
            </div>
            <div class="mt-2">
                <label for="comment_{{a.optionid}}">{{'COMMENT_YOUR_CHOICE' | translate}}</label>
                <textarea [(ngModel)]="a.comment" id="comment_{{a.optionid}}" class="form-control"></textarea>
            </div>
        </div>
    </div>


</div>