import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BlockService } from '@app/shared/services/block.service';

@Component({
  selector: 'app-set-day-block',
  templateUrl: './set-day-block.component.html',
  styleUrls: ['./set-day-block.component.scss']
})
export class SetDayBlockComponent implements OnInit {

  constructor(
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private blockService: BlockService
  ) { }

  ngOnInit(): void {
    const dateRaw = this.activatedRoute.snapshot.paramMap.get('date');
    const blockid = this.activatedRoute.snapshot.paramMap.get('blockid');
    const date = new Date(parseInt(dateRaw));

    this.blockService.redirectToBlock = {date, blockid};

    if (this.authService.isAuthenticated()) {
      this.router.navigate(['research']);
    } else {
      this.router.navigate(['login']);
    }
  }

}
// http://localhost:4300/#/go-to-block/1598306400000/435
