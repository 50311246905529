<div>
    <div class="sidebar-header col-12 d-flex justify-content-end">
        <div class="close-button">
            <i (click)="close()" class="fal fa-times"></i>
        </div>
    </div>

    <!-- se nessuna pagina -->
    <div class="col-12" *ngIf="!selected">
        <div class="row">
            <div class="col-12" *ngIf="country.logo_url">
                <small>{{'RESEARCH_CREATED_BY' | translate}}</small> <br><img class="mt-2" style="max-height: 50px;" [src]="country.logo_url">
            </div>

            <div class="col-12 text-center py-5">
                <!--<img [src]="user.avatar_url || '../../../../../assets/images/avatar.png'" alt="">-->
                <!--
                <div class="profile-image big">
                    <img [src]="user.avatar_url || '../../../../../assets/images/avatar.png'" alt="user avatar">
                </div>
                -->

                <!-- se possibile usare questo html-->

                <div class="avatar-image-container" [ngStyle]="{'background-image': 'url('+ (user.avatar_url | privatesrc) +')'}">
                    <div class="placeholder-avatar" *ngIf="!user.avatar_url">
                        <i class="fal fa-user-alt"></i>
                    </div>
                </div>
                <div class="mt-3">{{user.nickname}}</div>
            </div>
            <div class="col-10 offset-1 col-md-6 offset-md-3 mt-2 text-center">
                <button (click)="selected = 'profile'" class="btn-sicche btn-secondary w-100">{{'PROFILE.EDIT_PROFILE' | translate}}</button>
            </div>
            <div class="col-10 offset-1 col-md-6 offset-md-3 mt-2 text-center" *ngIf="room && room.instructions_html">
                <button (click)="selected = 'instructions'" class="btn-sicche btn-secondary w-100">{{'PROFILE.INSTRUCTIONS' | translate}}</button>
            </div>
            <!-- <div class="col-10 offset-1 col-md-4 offset-md-4 mt-2 text-center" >
                <button (click)="selected = 'demo'" class="btn-sicche btn-secondary w-100">{{'PROFILE.DEMO' | translate}}</button>
            </div> -->
            <div class="col-10 offset-1 col-md-6 offset-md-3 mt-2 text-center">
                <button (click)="selected = 'faq'" class="btn-sicche btn-secondary w-100">{{'PROFILE.DEMO' | translate}}</button>
            </div>
            <div class="col-10 offset-1 col-md-4 offset-md-4 mt-4 text-center">
                <a (click)="logout()">{{'PROFILE.LOGOUT' | translate}}</a>
            </div>
        </div>
    </div>

    <!-- se gestione profilo -->
    <div class="col-12" *ngIf="selected == 'profile'">
        <app-profile [user]="user" (saved)="selected = null;"></app-profile>
    </div>
    <!-- se gestione istruzioni -->
    <div class="col-12" *ngIf="selected == 'instructions'">
        <app-instructions [room]="room"></app-instructions>
    </div>
    <!-- se demo -->
    <div class="col-12" *ngIf="selected == 'demo'">

    </div>
    <!-- se faq -->
    <div class="col-12" *ngIf="selected == 'faq'">
        <app-faq></app-faq>
    </div>

    <div class="col-12" style="font-size: .8rem; position: absolute; bottom: 5px">
        Version {{version}}
    </div>
</div>