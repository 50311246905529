<div class="question-detail-box p-3">
    <div class="sidebar-header d-flex align-items-center justify-content-end w-100 pb-3" >
        <button class="close-button text-center" (click)="close()">
            <i class="fal fa-times"></i>
        </button>
    </div>

    <h4 class="sidebar-title">{{'NOTICES' | translate}}</h4>

    <ng-container *ngIf="!notices.length">
        <div class="alert alert-warning">
            {{ 'THERE_ARE_NO_NOTICES' | translate }}
        </div>
    </ng-container>

    <div class="sidebar-body p-0 py-3" *ngIf="notices">
        <div *ngFor="let n of notices">
            <div [ngClass]="(n.read ? 'read' : 'border-active')" class=" border-active shadow p-3 mb-2 card d-flex flex-row align-items-center" (click)="openNotice(n)">
                <span class="d-flex flex-grow-1 align-items-center">
                    <div *ngIf="n.from_team.avatar_url" class="avatar-image-container" style="background-image: url({{((n.from_team.avatar_url | privatesrc) || '')}})">
                    </div>
                    <div class="avatar-image-container" *ngIf="!n.from_team.avatar_url">
                        <div class="placeholder-avatar">
                            <i class="fal fa-user-alt"></i>
                        </div>
                    </div>
                    <div class="d-flex flex-grow-1 flex-column pl-3">
                        <div *ngIf="n.from_team.role == 'INTERVIEWED'"><b>{{n.from_team.nickname || (n.from_team.firstname + ' ' + n.from_team.lastname)}}</b></div>
                        <div *ngIf="n.from_team.role != 'INTERVIEWED'"><b class="moderator-name">{{n.from_team.firstname}}, {{'MODERATOR' | translate}}</b></div>
                        <span class="block-with-text" *ngIf="n.type === 'COMMENT'">{{'NOTIFICATION.COMMENT' | translate}}</span>
                        <span class="block-with-text" *ngIf="n.type === 'LIKE'">{{'NOTIFICATION.LIKE' | translate}}</span>
                        <span class="block-with-text" *ngIf="n.type === 'MENTION'">{{'NOTIFICATION.MENTION' | translate}}</span>
                    </div>
                </span><span class="flex-grow-0"><i class="far fa-chevron-right text-primary bold text-orange"></i></span>
            </div>
        </div>
    </div>

</div>
