<div class="bg-orange vh-100 d-flex align-items-center overflowy-hidden">
    <div class="container" *ngIf="user">
        <div class="row">
            <div class="offset-1 offset-md-3 offset-xl-4 col-10 col-md-6 col-xl-4 text-center mb-4">
                <h1 class="h2 black mb-3 text-center">{{'CHANGE_PASSWORD' | translate}}</h1>
                <p class="d-flex justify-content-center">{{'CHANGE_PASSWORD_SUBTITLE' | translate}}</p>
            </div>
            <div class="offset-1 offset-md-3 offset-xl-4 col-10 col-md-6 col-xl-4 mt-3 p-3">
                <div class="form-group">
                    <input minlength="6" class="form-control" [(ngModel)]="password"  type="password">
                    <span class="placeholder">{{'PASSWORD' | translate}}</span>
                </div>
                <div class="form-group">
                    <input minlength="6" class="form-control" [(ngModel)]="password2"  type="password">
                    <span class="placeholder">{{'PASSWORD_REPEAT' | translate}}</span>
                </div>
                <!--<input type="text"  [readOnly]="realName"[(ngModel)]="user.nickname" >-->

                <div class="text-center">
                    <button [disabled]="!password || !password2" class="btn-sicche" (click)="submit()">
                        <i class="fal fa-arrow-right"></i>{{'CONTINUE' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
