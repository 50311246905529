<div class="question-detail-box">
    <div class="sidebar-header d-flex align-items-center justify-content-end w-100 p-3" >
        <!-- <h4 class="sidebar-title mb-0" [innerHTML]="question.title"></h4> -->
        <button class="close-button text-center" (click)="cancel()">
            <i class="fal fa-times"></i>
        </button>
    </div>

    <div class="px-3" [innerHTML]="sanitizedTitle"></div>

    <ng-template appAnswerHost></ng-template>

</div>

