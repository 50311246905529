import { Component, OnInit, Input } from '@angular/core';
import { RoomModel } from '@app/shared/models/room.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss']
})
export class InstructionsComponent implements OnInit {

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  @Input() room: RoomModel;
  sanitizedInstructions: any;

  ngOnInit(): void {
    this.sanitizedInstructions = this.sanitizer.bypassSecurityTrustHtml(this.room.instructions_html);
  }

}
