import { BaseModel } from '@app/core/models/base';

/**
 * User model class
 */

export class BlockModel extends BaseModel {

    // tslint:disable: variable-name
    country_uniqid: string;
    roomid: number;
    id: number;
    day: Date;
    title: string;
    datetime_start: Date;
    datetime_end: Date;
    room_last_active_day: Date;
    room_date_end: Date;
    is_room_group: boolean;
    image: any;
    image_url: string;
    image_del: boolean;
    device: string;
    is_sequential_questions: boolean;
    is_diary: boolean;
    is_highlighted: boolean;
    has_geolocation: boolean;
    geolocation_lat: string;
    geolocation_long: string;
    geolocation_address: string;
    completion_perc: number;
    nr_question_total: number;
    nr_question_answered: number;
    current_interviewed_completion_perc: number;
    current_interviewed_nr_question_total: number;
    current_interviewed_nr_question_total_for_today: number;
    current_interviewed_nr_question_answered: number;
    current_interviewed_nr_question_total_for_date: number;
    current_interviewed_nr_question_answered_for_date: number;
    current_interviewed_nr_question_answered_for_today: number;

    static getResourceName() {
        return 'block-management';
    }
}
