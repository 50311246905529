import { Component, OnInit } from '@angular/core';
import { CoreBaseComponent } from '@app/core/components/base.component';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { UserService } from '@app/shared/services/user.service';
import { AuthService } from '@app/core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ResponseModel } from '@app/core/models/response.model';
import { ServiceErrorModel } from '@app/core/models/service-error.model';
import { CoreLayoutService } from '@app/core/services/layout.service';
import { CountryService } from '@app/shared/services/country.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends CoreBaseComponent implements OnInit {

  form: UntypedFormGroup = null;
  language_code = 'en';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private translate: TranslateService,
    public coreLayoutService: CoreLayoutService,
    private countryService: CountryService,
  ) {
      super();
  }

  ngOnInit() {
    this.language_code = this.countryService.getSessionCountry().language_code
    this.form = this.formBuilder.group({
      username: new UntypedFormControl('', [
        Validators.required
      ])
    });
  }

  submit() {
    this.operation('requesting').reset().isSubmitting();
    const data = this.form.getRawValue();
    data.otherdata = 'APP-INT';

    data.language_code = this.language_code

    this.authService.askPasswordReset(data).subscribe((res: ResponseModel) => {
      this.operation('requesting').isSuccess();
    }, (error: ServiceErrorModel) => {
      this.operation('requesting').isFailed(error);
    });
  }

}
