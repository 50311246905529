<div>
    <div *ngIf="question">
        <div class="question-content px-3">
            <div class="attached-file-container" *ngIf="question.question_data.attached_pdf_files">
                <span class="attached-file" *ngFor="let pdf of question.question_data.attached_pdf_files">
                    <a [href]="pdf.pdf_url | privatesrc" target="blank">{{pdf.name}}</a>
                </span>
            </div>
            <div class="attached-image-container" *ngIf="question.question_data.attached_images">
                <img *ngFor="let img of question.question_data.attached_images" [src]="img.img_url | privatesrc" [alt]="img.name">
            </div>

            <!-- answer box -->
            <div *ngIf="!question.answered_by_currentuser && (question.is_masked || answerBox || !block.is_room_group)">
                <div class="py-3">
                    <!-- answer custom -->
                    <h5 *ngIf="answerService.isMobile()" class="mb-3">
                        {{ 'TURN_PHONE_HORIZONTAL' | translate }}
                    </h5>

                    <div *ngFor="let s of question.question_data.statements; let i = index" class="answer-container mb-3">
                        <div class="panel shadow">
                            <p class="mb-4 text-md-center">{{ s.statement }}</p>
                            <div class="d-flex flex-grow-1 flex-column align-items-center justify-content-center mb-0">
                                <div class="">
                                    <div class="d-flex justify-content-center mb-4">
                                        <label
                                            *ngFor="let step of steps; let j = index"
                                            class="d-flex align-items-center mb-0 input-img-box">
                                            <div class="step-wrap d-flex align-items-start mb-0">
                                                <input
                                                    [class.with-img]="hasImageScale"
                                                    [class.no-img]="!hasImageScale"
                                                    [name]="'steps-group-' + i"
                                                    type="checkbox"
                                                    [id]="'step-' + i + '-' + step"
                                                    [checked]="isChecked(i, step)"
                                                    (click)="checkAnswer(i, step)">
                                                <p class="mb-0">{{ step }}</p>
                                                <ng-container *ngIf="hasImageScale">
                                                    <div class="image-for-radio-wrap" [class.selected]="isChecked(i, j)">
                                                        <img [src]="question.question_data.images[j].image_url | privatesrc" alt="">
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="">
                                        <div class="labels-container d-flex justify-content-between">
                                            <div class="step-label">
                                                <div class="text mb-1">{{ question.question_data.label_left }}</div>
                                                <app-sicche-emoticon *ngIf="question.question_data.include_side_emoticons" [emoticon]="3" [width]="40"></app-sicche-emoticon>
                                            </div>
                                            <div class="step-label">
                                                <div class="text mb-1">{{ question.question_data.label_center }}</div>
                                                <app-sicche-emoticon *ngIf="question.question_data.include_center_emoticon" [emoticon]="2" [width]="40"></app-sicche-emoticon>
                                            </div>
                                            <div class="step-label">
                                                <div class="text mb-1">{{ question.question_data.label_right }}</div>
                                                <app-sicche-emoticon *ngIf="question.question_data.include_side_emoticons" [emoticon]="1" [width]="40"></app-sicche-emoticon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="mt-3 text-right">
                        <button *ngIf="!submitting" (click)="submit()" class="btn-primary sticky-footer" [disabled]="answer.length !== question.question_data.statements.length">{{
                                ((question.minimum_multiple_answers_nr && question.minimum_multiple_answers_nr > 0 && !block.is_room_group)
                                ? 'SHOW_ANSWER'
                                : 'SEND_ANSWER' )| translate
                            }}</button>
                        <button *ngIf="submitting" class="btn-primary sticky-footer"><i class="fal fa-spinner-third fa-spin"></i></button>
                    </div>
                </div>
            </div>

            <!-- risposte altri utenti -->
            <div class="container-fluid mt-3" *ngIf="question.answered_by_currentuser || (!question.is_masked && block.is_room_group)">
                <div *ngIf="answers">
                    <div *ngFor="let a of answers" id="answer_id_{{a.id}}">
                        <div  class="panel shadow m-0 h-100 d-flex flex-column mt-3" id="answer_id_{{a.id}}">
                            <div class="d-flex p-0">
                                <div class="col-2 col-xl-1 px-1 text-center">
                                    <div class="avatar-image-container" [style.background-image]="'url(' + (a.team.avatar_url | privatesrc) + ')'">
                                        <div class="placeholder-avatar" *ngIf="!a.team.avatar_url">
                                            <i class="fal fa-user-alt"></i>
                                        </div>
                                    </div>
                                    <span><small (click)="like(a)" class="bold"><i class="fal fa-heart" [ngClass]="{'fill': a.total_like_to_answer || a.total_like_to_comment || a.currentuser_like_answer}"></i>&nbsp;{{a.total_like_to_answer || a.total_like_to_comment}}</small></span>
                                </div>
                                <!-- custom -->
                                <div [className]="block.is_room_group ? 'col-10 pr-0' : 'col-12'">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class=" user-name mb-2">{{a.team.nickname || (a.team.firstname + ' ' + a.team.lastname)}}</div>
                                        </div>
                                    </div>

                                    <div *ngFor="let answerData of a.answer_data">
                                        <div class="d-flex check mb-4">
                                            <div class="flex-grow-1">
                                                <p class="m-0">{{ getStatementFromIdx(answerData.statement) }}</p>
                                            </div>
                                            <div class="d-flex align-items-start justify-content-center flex-grow-0">
                                                <!-- only show selected answer -->
                                                <label class="d-flex align-items-center mb-0 input-img-box">
                                                    <div class="step-wrap d-flex align-items-start mb-0">
                                                        <input
                                                            [class.with-img]="hasImageScale"
                                                            [class.no-img]="!hasImageScale"
                                                            type="checkbox"
                                                            checked
                                                            disabled>
                                                        <p class="mb-0">{{ answerData.step }}</p>
                                                        <ng-container *ngIf="hasImageScale">
                                                            <div class="image-for-radio">
                                                                <img [src]="question.question_data.images[answerData.statement].image_url | privatesrc" alt="">
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- comments and likes -->
                            <app-questions-comments-like [a]="a"  [answers]="answers"></app-questions-comments-like>
                        </div>
                    </div>
                </div>
                <div *ngIf="answers && answers.length == 0">
                    {{'NO_ANSWERS_YET' | translate}}
                </div>

                <button *ngIf="!question.answered_by_currentuser && !(!question.answered_by_currentuser && (question.is_masked || answerBox || !block.is_room_group))" (click)="answerBox = true" class="btn-sicche btn-primary sticky-footer">
                   <span *ngIf="question.minimum_multiple_answers_nr && block.is_room_group">
                        {{'ADD_NEW_POST' | translate}}<i class="fal fa-reply ml-2 mr-0"></i>
                    </span>
                    <span *ngIf="!question.minimum_multiple_answers_nr || block.is_room_group">
                        {{'SHOW_ANSWER' | translate}}<i class="fal fa-reply ml-2 mr-0"></i>
                    </span>
                </button>
            </div>
        </div>
    </div>
</div>
