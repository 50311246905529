import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AnswerModel } from '@app/shared/models/answer.model';
import { AnswerService } from '@app/shared/services/answer.service';
import { CommentModel } from '@app/shared/models/comment.model';
import { ResponseModel } from '@app/core/models/response.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { TeamService } from '@app/shared/services/team.service';
import { ChangeDetectorRef } from '@angular/core';
import { NotificationService } from '@app/shared/services/notification.service';
import { CountryService } from '@app/shared/services/country.service';


declare var MediaRecorder: any;

@Component({
  selector: 'app-questions-comments-like',
  templateUrl: './comments-like.component.html',
  styleUrls: ['./comments-like.component.scss']
})
export class CommentsLikeComponent implements OnInit {

  @Input() opened: boolean = true;
  @Input() a: AnswerModel;
  @Input() answers: AnswerModel[];
  currentComment: string;
  currentPrivateComment: string;

  commentVideo = new FormData();
  commentVideoCount = 0;
  commentImages = [];
  commentAudio;
  commentVideoNamesKey = [];
  imagePreviews = [];
  postingComment = false;

  addPrivateCommentID = 0;
  commentIsPrivate = true;
  public canComment = true;
  public sendingComment = false;

  recording = false;
  recordTime = 0;
  mediaRecorder: any;
  interval: any;
  audioChunks = [];
  audioBlob: any;
  audio: any;
  audioUrl: any;
  audioBuffer: AudioBuffer = null;

  timezone = null;

  constructor(
    private answerService: AnswerService,
    private toastService: ToastrService,
    private translate: TranslateService,
    private teamService: TeamService,
    private ref: ChangeDetectorRef,
    private notificationService: NotificationService,
    private countryService: CountryService
  ) { }

  async ngOnInit() {
    this.timezone = this.countryService.currentCountry.timezone

    this.canComment = this.teamService.currentTeam.userid === this.a.team.userid;
    if (this.opened) {
      this.a.showComments = true;
    }
    try {
      // this.initVocal();

    } catch (err) {
      this.toastService.error(this.translate.instant('INCOMPATIBLE_DEVICE_FOR_AUDIO_REC'));
    }
    this.serveNotification();
  }

  async initVocal() {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    // here accessing MediaRecorder to check if it exists gives ReferenceError, like it has never been declared.
    // if (!MediaRecorder) {
    //   this.toastService.error('using polyfill');
    //   MediaRecorder = AudioRecorder;
    // }

    this.mediaRecorder = new MediaRecorder(stream);

    // start
    this.mediaRecorder.addEventListener('dataavailable', e => {
      this.audioChunks.push(e.data);
    });

    // stop
    this.mediaRecorder.addEventListener('stop', () => {
      window.AudioContext = window.AudioContext;
      const audioContext = new AudioContext();
      const f = new FileReader();
      const selfComponent = this;

      f.onloadend = (e) => {
        audioContext.decodeAudioData((e.target.result as Buffer), function (buffer) {
          function appendBuffer(b1: AudioBuffer, b2: AudioBuffer) {
            const numberOfChannels = Math.min(b1.numberOfChannels, b2.numberOfChannels);
            const tmp = audioContext.createBuffer(numberOfChannels, (b1.length + b2.length), b1.sampleRate);
            for (var i = 0; i < numberOfChannels; i++) {
                const channel = tmp.getChannelData(i);
                channel.set(b1.getChannelData(i), 0);
                channel.set(b2.getChannelData(i), b1.length);
            }
            return tmp;
          }

          selfComponent.audioBuffer = selfComponent.audioBuffer !== null
            ? appendBuffer(selfComponent.audioBuffer, buffer)
            : buffer;

          const blob = new Blob([selfComponent.audioBufferToWav(selfComponent.audioBuffer)], { type: "audio/wav" });

          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            selfComponent.commentAudio = reader.result;
            selfComponent.ref.detectChanges();
          };

          selfComponent.audioUrl = window.URL.createObjectURL(blob);
          selfComponent.audio = new Audio(selfComponent.audioUrl);

        }, function (e) {
          selfComponent.toastService.error(JSON.stringify(e));
        });
      };

      f.readAsArrayBuffer(this.audioChunks[this.audioChunks.length - 1]);
    });
  }

  audioBufferToWav(buffer, opt?) {
    opt = opt || {}

    var numChannels = buffer.numberOfChannels
    var sampleRate = buffer.sampleRate
    var format = opt.float32 ? 3 : 1
    var bitDepth = format === 3 ? 32 : 16

    var result
    if (numChannels === 2) {
      result = this.interleave(buffer.getChannelData(0), buffer.getChannelData(1))
    } else {
      result = buffer.getChannelData(0)
    }

    return this.encodeWAV(result, format, sampleRate, numChannels, bitDepth)
  }

  serveNotification() {
    if(this.notificationService.currentNotice && this.notificationService.currentNotice.isCommentNotice() || this.notificationService.currentNotice?.isCommentType()) {
      setTimeout(() => {
        const el = document.getElementById('comment_id_' + this.notificationService.currentNotice.commentid);
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
        this.notificationService.currentNotice = null;
      }, 500);
    }
  }

  likeComment(answer: AnswerModel, comment: CommentModel) {
    if (!comment.currentuser_like_comment) {
      this.answerService.likeComment(answer.id, comment.id).subscribe((res: ResponseModel) => {
        const answers = this.answers;
        for (const a of answers) {
          if (a.id === answer.id) {
            for (const c of a.comments) {
              if (c.id === comment.id) {
                c.currentuser_like_comment = true;
                c.total_like_to_comment++;
              }
            }
          }
        }
      });
    } else {
      this.answerService.unlike(answer.id, comment.id).subscribe((res: ResponseModel) => {
        const answers = this.answers;
        for (const a of answers) {
          if (a.id === answer.id) {
            for (const c of a.comments) {
              if (c.id === comment.id) {
                c.currentuser_like_comment = false;
                c.total_like_to_comment--;
              }
            }
          }
        }
      });
    }
  }

  postComment(answer: AnswerModel) {
    if (!this.postingComment) {
      let private_userid = null;
      this.postingComment = true;
      if (this.commentIsPrivate && this.hasPriavateComment(answer)) {
        private_userid = this.teamService.currentTeam.userid;
      }
      let mediaComment = this.commentImages;
      if (this.commentAudio) {
        mediaComment = [...mediaComment, {audio: this.commentAudio}];
        this.reset();
      }
      this.answerService.createCommment(answer.id, this.currentComment, null, private_userid, this.commentVideo, mediaComment).subscribe((res: ResponseModel) => {
        console.log(res.getData());
        this.commentImages = [];
        this.commentVideo = new FormData();
        this.commentVideoCount = 0;
        this.commentVideoNamesKey = [];
        this.imagePreviews = [];

        const answers = this.answers;
        for (const a of answers) {
          if (a.id === answer.id) {
            a.comments.push(res.getData()[0]);
            a.addComment = false;
          }
        }
        this.answers = answers;
        this.currentComment = '';
        this.postingComment = false;
        // scroll to comment
        setTimeout(() => {
          const el = document.getElementById('comment_id_' + res.getData()[0].id);
          el.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 500);
      });
    }
  }

  addVideo(files: FileList, element = null) {
    if (files.item(0).type.indexOf('video') !== -1) {
      console.log(files.item(0));
      this.commentVideoCount++;
      this.commentVideo.append('videos' + this.commentVideoCount, files.item(0));
      this.commentVideoNamesKey = [...this.commentVideoNamesKey, {name: files.item(0).name, key: 'videos' + this.commentVideoCount}];
      this.toastService.success(this.translate.instant('VIDEO_ADDED'));

      if (element) {
        element.target.value = null
      }
    } else {
      this.toastService.error(this.translate.instant('FILE_IS_NOT_VIDEO'));
    }
  }
  removeVideo(videoKey) {
    this.commentVideoCount--;
    this.commentVideo.delete(videoKey);
    this.commentVideoNamesKey = this.commentVideoNamesKey.filter(vid => vid.key !== videoKey);
  }


  startStop() {
    if (this.recording) {
      this.stop();
    } else {
      this.start();
    }
    this.recording = !this.recording;
  }
  start() {
    if (this.mediaRecorder) {
      this.interval = setInterval(() => this.recordTime++, 1000);
      this.mediaRecorder.start();
    } else {
      this.toastService.error(this.translate.instant('NO_RECORDING_AUTH'));
    }
  }

  stop() {
    clearInterval(this.interval);
    this.mediaRecorder.stop();
  }

  reset() {
    this.audioChunks = [];
    this.commentAudio = null;
    this.audioBlob = null;
    this.audio = null;
    this.recordTime = 0;
    try {
      this.stopAudio();
    } catch (error) {
      console.log(error);
    }
  }

  playAudio() {
    this.audio.play();
  }

  stopAudio() {
    this.audio.pause();
    this.audio.currentTime = 0;
  }


  addImage(file: any) {
    if (file.type.indexOf('image') !== -1) {
      let newImage;
      const reader = new FileReader();
      reader.onload = (e) => {
        newImage = e.target.result;
        this.commentImages = [...this.commentImages, {image: newImage}];
        this.previewImages();
      };
      reader.readAsDataURL(file);
    } else {
      this.toastService.error(this.translate.instant('FILE_IS_NOT_IMAGE'));
    }
  }

  addImages(files: FileList)  {
    Array.from(files).forEach(file => {
      this.addImage(file);
    })
  }

  removeImage(imageToRemove) {
    const commentImages = this.commentImages;
    let index;
    for (let i = 0; i < commentImages.length; i++) {
      const image = commentImages[i];
      if (image.image === imageToRemove || image.image_url === imageToRemove) {
        index = i;
      }
    }
    if (Number.isInteger(index)) {
      commentImages.splice(index, 1);
    }
    this.commentImages = commentImages;
    this.previewImages();
  }

  previewImages() {
    this.imagePreviews = [];
    for (const img of this.commentImages) {
      this.imagePreviews = [...this.imagePreviews, img.image || img.image_url];
    }
  }


  hasPriavateComment(answer: AnswerModel) {
    for (const comment of answer.comments) {
      if (comment.private_userid) {
        return true;
      }
    }
    return false;
  }

  privateCommentScroll(answer: AnswerModel) {
    this.commentIsPrivate = true;
    for (const a of this.answers) {
      if (a.id === answer.id) {
        a.addComment = true;
      }
    }
    setTimeout(() => {
      const el = document.getElementById('comment_input_answer_id_' + answer.id);
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 200);
  }

  stringify(data) {
    return JSON.stringify(data)
  }

  encodeWAV (samples, format, sampleRate, numChannels, bitDepth) {
    var bytesPerSample = bitDepth / 8
    var blockAlign = numChannels * bytesPerSample

    var buffer = new ArrayBuffer(44 + samples.length * bytesPerSample)
    var view = new DataView(buffer)

    /* RIFF identifier */
    this.writeString(view, 0, 'RIFF')
    /* RIFF chunk length */
    view.setUint32(4, 36 + samples.length * bytesPerSample, true)
    /* RIFF type */
    this.writeString(view, 8, 'WAVE')
    /* format chunk identifier */
    this.writeString(view, 12, 'fmt ')
    /* format chunk length */
    view.setUint32(16, 16, true)
    /* sample format (raw) */
    view.setUint16(20, format, true)
    /* channel count */
    view.setUint16(22, numChannels, true)
    /* sample rate */
    view.setUint32(24, sampleRate, true)
    /* byte rate (sample rate * block align) */
    view.setUint32(28, sampleRate * blockAlign, true)
    /* block align (channel count * bytes per sample) */
    view.setUint16(32, blockAlign, true)
    /* bits per sample */
    view.setUint16(34, bitDepth, true)
    /* data chunk identifier */
    this.writeString(view, 36, 'data')
    /* data chunk length */
    view.setUint32(40, samples.length * bytesPerSample, true)
    if (format === 1) { // Raw PCM
      this.floatTo16BitPCM(view, 44, samples)
    } else {
      this.writeFloat32(view, 44, samples)
    }

    return buffer
  }

  interleave (inputL, inputR) {
    var length = inputL.length + inputR.length
    var result = new Float32Array(length)

    var index = 0
    var inputIndex = 0

    while (index < length) {
      result[index++] = inputL[inputIndex]
      result[index++] = inputR[inputIndex]
      inputIndex++
    }
    return result
  }

  writeFloat32 (output, offset, input) {
    for (var i = 0; i < input.length; i++, offset += 4) {
      output.setFloat32(offset, input[i], true)
    }
  }

  floatTo16BitPCM (output, offset, input) {
    for (var i = 0; i < input.length; i++, offset += 2) {
      var s = Math.max(-1, Math.min(1, input[i]))
      output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true)
    }
  }

  writeString (view, offset, string) {
    for (var i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i))
    }
  }
}
