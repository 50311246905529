<div>
    <div *ngIf="question">
        <div class="question-content px-3">
            <div class="attached-file-container" *ngIf="question.question_data.attached_pdf_files">
                <span class="attached-file" *ngFor="let pdf of question.question_data.attached_pdf_files">
                    <a [href]="pdf.pdf_url | privatesrc" target="blank">{{pdf.name}}</a>
                </span>
            </div>
            <div class="attached-image-container" *ngIf="question.question_data.attached_images">
                <img *ngFor="let img of question.question_data.attached_images" [src]="img.img_url | privatesrc" [alt]="img.name">
            </div>

            <!-- answer box -->
            <div *ngIf="!question.answered_by_currentuser && (question.is_masked || answerBox || !block.is_room_group)">
                <div class="py-3">
                    <!-- answer custom -->
                    <div *ngFor="let a of question.question_data.answers" class="answer-container row px-2 mb-3">
                        <div class="col-12 panel shadow m-0">
                            <div class="row">
                                <div class="col-6 col-sm-4 pr-2 d-flex align-items-center justify-content-center">
                                    <div class="text-center">
                                        <img *ngIf="a.image_url" [src]="a.image_url | privatesrc" alt="">
                                        <p class="m-0" [class.mt-2]="!!a.image_url">{{ a.comment }}</p>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-8 pl-2 d-flex align-items-start justify-content-center">
                                    <div class="btn-check d-inline-block text-center">
                                        <label class="w-100" *ngFor="let cat of question.question_data.categories">
                                            <div class="category-wrap d-flex align-items-start check mb-0">
                                                <input
                                                    [name]="a.id"
                                                    type="radio"
                                                    [id]="a.id + '-rating-' + cat"
                                                    [checked]="isChecked(a.id, cat)"
                                                    (click)="checkAnswer(a.id, cat)">
                                                <p class="mb-0"><span>{{ cat }}</span></p>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="mt-3 text-right">
                        <button *ngIf="!submitting" (click)="submit()" class="btn-primary sticky-footer" [disabled]="answer.length !== question.question_data.answers.length">{{
                                ((question.minimum_multiple_answers_nr && question.minimum_multiple_answers_nr > 0 && !block.is_room_group)
                                ? 'SHOW_ANSWER'
                                : 'SEND_ANSWER' )| translate
                            }}</button>
                        <button *ngIf="submitting" class="btn-primary sticky-footer"><i class="fal fa-spinner-third fa-spin"></i></button>
                    </div>
                </div>
            </div>

            <!-- risposte altri utenti -->
            <div class="container-fluid mt-3" *ngIf="question.answered_by_currentuser || (!question.is_masked && block.is_room_group)">
                <div *ngIf="answers">
                    <div *ngFor="let a of answers" id="answer_id_{{a.id}}">
                        <div  class="panel shadow m-0 h-100 d-flex flex-column mt-3" id="answer_id_{{a.id}}">
                            <div class="d-flex p-0">
                                <div class="col-2 col-xl-1 px-1 text-center">
                                    <div class="avatar-image-container" [style.background-image]="'url(' + (a.team.avatar_url | privatesrc) + ')'">
                                        <div class="placeholder-avatar" *ngIf="!a.team.avatar_url">
                                            <i class="fal fa-user-alt"></i>
                                        </div>
                                    </div>
                                    <span (click)="like(a)"><small class="bold"><i class="fal fa-heart" [ngClass]="{'fill': a.total_like_to_answer || a.total_like_to_comment || a.currentuser_like_answer}"></i>&nbsp;{{a.total_like_to_answer || a.total_like_to_comment}}</small></span>
                                </div>
                                <!-- custom -->
                                <div [className]="block.is_room_group ? 'col-10' : 'col-12'">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class=" user-name mb-2">{{a.team.nickname || (a.team.firstname + ' ' + a.team.lastname)}}</div>
                                        </div>
                                    </div>

                                    <div *ngFor="let answerData of a.answer_data">
                                        <div class="row mb-3">
                                            <div class="col-5 col-sm-6 col-lg-3 pr-2 d-flex align-items-center justify-content-center">
                                                <div class="text-center">
                                                    <img *ngIf="getAnswerFromId(answerData.optionid).image_url" [src]="getAnswerFromId(answerData.optionid).image_url | privatesrc" alt="">
                                                    <p class="m-0" [class.mt-2]="!!getAnswerFromId(answerData.optionid).image_url">
                                                        {{ getAnswerFromId(answerData.optionid).comment }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-7 col-sm-6 col-lg-3 pl-2 d-flex align-items-start justify-content-center">
                                                <!-- only show selected category -->
                                                <label
                                                    class="d-flex flex-column align-items-start justify-content-start mb-0 input-img-box">
                                                    <div class="category-wrap d-flex align-items-start check mb-0">
                                                        <input
                                                            disabled
                                                            type="radio"
                                                            checked>
                                                        <p class="mb-0"><span>{{ answerData.category }}</span></p>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- comments and likes -->
                            <app-questions-comments-like [a]="a"  [answers]="answers"></app-questions-comments-like>
                        </div>
                    </div>
                </div>
                <div *ngIf="answers && answers.length == 0">
                    {{'NO_ANSWERS_YET' | translate}}
                </div>

                <button *ngIf="!question.answered_by_currentuser && !(!question.answered_by_currentuser && (question.is_masked || answerBox || !block.is_room_group))" (click)="answerBox = true" class="btn-sicche btn-primary sticky-footer">
                   <span *ngIf="question.minimum_multiple_answers_nr && block.is_room_group">
                        {{'ADD_NEW_POST' | translate}}<i class="fal fa-reply ml-2 mr-0"></i>
                    </span>
                    <span *ngIf="!question.minimum_multiple_answers_nr || block.is_room_group">
                        {{'SHOW_ANSWER' | translate}}<i class="fal fa-reply ml-2 mr-0"></i>
                    </span>
                </button>
            </div>
        </div>
    </div>
</div>
