import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { BlockModel } from '@app/shared/models/block.model';
import { TeamModel } from '@app/shared/models/team.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AnswerService } from '@app/shared/services/answer.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { AnswerBaseComponent } from '../answer-base-component';
import { VocalAnswer } from '../models/vocal-answer';
import { BlockService } from '@app/shared/services/block.service';
import AudioRecorder from 'audio-recorder-polyfill'

declare global {
  interface Window {
    MediaRecorder: any;
    webkitAudioContext: any;
  }
}

window.MediaRecorder = AudioRecorder;
// declare var MediaRecorder: any;

@Component({
  selector: 'app-vocal-answer',
  templateUrl: './vocal-answer.component.html',
  styleUrls: ['./vocal-answer.component.scss']
})


export class VocalAnswerComponent extends AnswerBaseComponent implements OnInit {

  @Input() questionInput: QuestionModel;
  @Input() blockInput: BlockModel;
  @Input() userInput: TeamModel;
  @Output() closeAnswerInput = new EventEmitter();

  constructor(
    public toastService: ToastrService,
    public translateService: TranslateService,
    public answerService: AnswerService,
    public notificationService: NotificationService,
    public blockService: BlockService,
    private ref: ChangeDetectorRef,
  ) {
    super(toastService, translateService, answerService, notificationService, blockService);
  }

  answer: VocalAnswer = {
    vocal: null,
    written: null,
  };

  recording = false;
  recordTime = 0;
  mediaRecorder: any;
  interval: any;
  audioChunks = [];
  audioBlob: any;
  audio: any;
  audioUrl: any;
  audioBuffer: AudioBuffer = null;
  showWrittenAnswer = false;


  async ngOnInit(): Promise<void> {
    this.initialize(this.questionInput, this.blockInput, this.userInput, this.closeAnswerInput, this.answer);
    if (this.question.answered_by_currentuser) {
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log(stream);
      // here accessing MediaRecorder to check if it exists gives ReferenceError, like it has never been declared.

      this.mediaRecorder = new window.MediaRecorder(stream);

      // start
      this.mediaRecorder.addEventListener('dataavailable', e => {
        this.audioChunks.push(e.data);
      });

      // stop
      this.mediaRecorder.addEventListener('stop', () => {
        window.AudioContext = window.AudioContext // Default
          || window.webkitAudioContext // Safari and old versions of Chrome
          || false;

        if (!AudioContext) {
          this.recordError(new Error('No AudioContext available'));
          return;
        }

        const audioContext = new AudioContext();
        const f = new FileReader();
        const selfComponent = this;

        f.onloadend = (e) => {
          audioContext.decodeAudioData((e.target.result as Buffer), function (buffer) {
            function appendBuffer(b1: AudioBuffer, b2: AudioBuffer) {
              const numberOfChannels = Math.min(b1.numberOfChannels, b2.numberOfChannels);
              const tmp = audioContext.createBuffer(numberOfChannels, (b1.length + b2.length), b1.sampleRate);
              for (var i = 0; i < numberOfChannels; i++) {
                  const channel = tmp.getChannelData(i);
                  channel.set(b1.getChannelData(i), 0);
                  channel.set(b2.getChannelData(i), b1.length);
              }
              return tmp;
            }

            selfComponent.audioBuffer = selfComponent.audioBuffer !== null
              ? appendBuffer(selfComponent.audioBuffer, buffer)
              : buffer;

            const blob = new Blob([selfComponent.audioBufferToWav(selfComponent.audioBuffer)], { type: "audio/wav" });

            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              selfComponent.answer.vocal = reader.result;
              selfComponent.ref.detectChanges();
            };

            selfComponent.audioUrl = window.URL.createObjectURL(blob);
            selfComponent.audio = new Audio(selfComponent.audioUrl);

          }, function (e) {
            selfComponent.recordError(e);
          });
        };

        f.readAsArrayBuffer(this.audioChunks[this.audioChunks.length - 1]);
      });

    } catch (err) {
      this.recordError(err);
    }
  }

  recordError(e) {
    console.error(e);
    this.showWrittenAnswer = true;
    this.toastService.error(this.translateService.instant('INCOMPATIBLE_DEVICE_FOR_AUDIO_REC'));
  }

  startStop() {
    if (this.recording) {
      this.stop();
    } else {
      this.start();
    }
    this.recording = !this.recording;
  }

  start() {
    if (this.mediaRecorder) {
      this.interval = setInterval(() => this.recordTime++, 1000);
      this.mediaRecorder.start();
    } else {
      this.toastService.error(this.translateService.instant('NO_RECORDING_AUTH'));
    }
  }

  stop() {
    clearInterval(this.interval);
    this.mediaRecorder.stop();
  }

  reset() {
    this.audioChunks = [];
    this.answer.vocal = null;
    this.audioBlob = null;
    this.audio = null;
    this.recordTime = 0;
    try {
      this.stopAudio();
    } catch (error) {
      console.log(error);
    }
  }

  playAudio() {
    this.audio.play();
  }

  stopAudio() {
    this.audio.pause();
    this.audio.currentTime = 0;
  }

  // https://github.com/Jam3/audiobuffer-to-wav/blob/master/index.js

  audioBufferToWav(buffer, opt?) {
    opt = opt || {}
  
    var numChannels = buffer.numberOfChannels
    var sampleRate = buffer.sampleRate
    var format = opt.float32 ? 3 : 1
    var bitDepth = format === 3 ? 32 : 16
  
    var result
    if (numChannels === 2) {
      result = this.interleave(buffer.getChannelData(0), buffer.getChannelData(1))
    } else {
      result = buffer.getChannelData(0)
    }
  
    return this.encodeWAV(result, format, sampleRate, numChannels, bitDepth)
  }
  
  encodeWAV (samples, format, sampleRate, numChannels, bitDepth) {
    var bytesPerSample = bitDepth / 8
    var blockAlign = numChannels * bytesPerSample
  
    var buffer = new ArrayBuffer(44 + samples.length * bytesPerSample)
    var view = new DataView(buffer)
  
    /* RIFF identifier */
    this.writeString(view, 0, 'RIFF')
    /* RIFF chunk length */
    view.setUint32(4, 36 + samples.length * bytesPerSample, true)
    /* RIFF type */
    this.writeString(view, 8, 'WAVE')
    /* format chunk identifier */
    this.writeString(view, 12, 'fmt ')
    /* format chunk length */
    view.setUint32(16, 16, true)
    /* sample format (raw) */
    view.setUint16(20, format, true)
    /* channel count */
    view.setUint16(22, numChannels, true)
    /* sample rate */
    view.setUint32(24, sampleRate, true)
    /* byte rate (sample rate * block align) */
    view.setUint32(28, sampleRate * blockAlign, true)
    /* block align (channel count * bytes per sample) */
    view.setUint16(32, blockAlign, true)
    /* bits per sample */
    view.setUint16(34, bitDepth, true)
    /* data chunk identifier */
    this.writeString(view, 36, 'data')
    /* data chunk length */
    view.setUint32(40, samples.length * bytesPerSample, true)
    if (format === 1) { // Raw PCM
      this.floatTo16BitPCM(view, 44, samples)
    } else {
      this.writeFloat32(view, 44, samples)
    }
  
    return buffer
  }
  
  interleave (inputL, inputR) {
    var length = inputL.length + inputR.length
    var result = new Float32Array(length)
  
    var index = 0
    var inputIndex = 0
  
    while (index < length) {
      result[index++] = inputL[inputIndex]
      result[index++] = inputR[inputIndex]
      inputIndex++
    }
    return result
  }
  
  writeFloat32 (output, offset, input) {
    for (var i = 0; i < input.length; i++, offset += 4) {
      output.setFloat32(offset, input[i], true)
    }
  }
  
  floatTo16BitPCM (output, offset, input) {
    for (var i = 0; i < input.length; i++, offset += 2) {
      var s = Math.max(-1, Math.min(1, input[i]))
      output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true)
    }
  }
  
  writeString (view, offset, string) {
    for (var i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i))
    }
  }

  canAnswer(): boolean {
    return (!this.showWrittenAnswer && !!this.answer.vocal && !this.recording) ||
      (this.showWrittenAnswer && !!this.answer.written);
  }
}
