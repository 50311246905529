import { BaseModel } from '@app/core/models/base';
import { CommentModel } from './comment.model';
import { QuoteModel } from './quote.model';
import { TeamModel } from './team.model';


export class AnswerModel extends BaseModel {

    // tslint:disable: variable-name
    id: number;
    creatoruserid: number;
    questionid: number;
    blockid: number;
    country_uniqid: string;
    question_type: string;
    nickname: string;
    answer_data: any;
    team: TeamModel;
    userid: number;
    read: boolean;
    answer_tags: string[];
    stars: number;
    favorite: boolean;
    creationtime: Date;
    device: string;
    comments: CommentModel[];
    nr_comments: number;
    total_like_to_answer: number;
    total_like_to_comment: number;
    quotes: QuoteModel[];
    currentuser_like_answer: boolean;
    // only fe related
    showComments: boolean;
    addComment: boolean;
    diary_datetime: Date;

    static getResourceName() {
        return 'answer-management';
    }

}
