<div class="mt-0">
    <div class="text-right mb-4">
        <!--
        <div class="col-6">
            <a (click)="a.showComments = !a.showComments">({{a.comments.length}})
                {{'COMMENTS' | translate}}</a>
        </div>
        <div class="col-6 text-right">
            {{ a.creationtime | dateAgo }}
        </div>
        -->
        <span>{{ a.creationtime | dateAgo:({printAgo: true, timezone: timezone}) }}</span>
    </div>

    <div class="border-top" *ngIf="a.showComments">

        <div class="container-fluid mt-3 border-bottom pb-3" *ngFor="let c of a.comments" id="comment_id_{{c.id}}">
            <div class="row mt-4  notice-content">
                <div class="col-2 col-xl-1 px-1 text-center">
                    <!--<img [src]="(c.team.avatar_url | privatesrc) || 'https://www.w3schools.com/howto/img_avatar.png'"
                        class="w-100 avatar">-->
                    <div class="avatar-image-container" style="background-image: url({{c.team.avatar_url | privatesrc}})">
                        <div class="placeholder-avatar" *ngIf="!c.team.avatar_url">
                            <i class="fal fa-user-alt"></i>
                        </div>
                    </div>
                    <span>
                        <small (click)="likeComment(a, c)" class="bold">
                            <i class="fal fa-heart" [ngClass]="{'fill': c.total_like_to_comment || c.currentuser_like_comment}"></i>&nbsp;{{c.total_like_to_comment}}</small>
                    </span>
                </div>
                <div class="col-10 col-xl-11">
                    <div class="col-12 user-name ml-0 pl-0" *ngIf="c.team.role == 'INTERVIEWED'">
                        {{c.team.nickname}}
                        <i class="fal fa-eye-slash" placement="top" *ngIf="c.private_userid"></i>
                    </div>
                    <div class="col-12 user-name ml-0 pl-0" *ngIf="c.team.role != 'INTERVIEWED'">{{c.team.firstname}}, {{'MODERATOR' | translate}}
                        <i class="fal fa-eye-slash" placement="top" [ngbTooltip]="'THIS_COMMENT_IS_PRIVATE' | translate" *ngIf="c.private_userid"></i>
                    </div>
                    <div *ngIf="c.comment && c.comment != 'undefined'" [innerHTML]="c.comment"></div>
                    <div class="mt-2" *ngIf="c.comment_otherdata">
                        <div class="comment-image" *ngIf="c.comment_otherdata">
                            <!-- comment images -->
                            <div class="answer-image-container" *ngFor="let image of c.comment_otherdata.images">
                                <img class="answer-image" [src]="image.image_url" alt="">
                            </div>
                            <!-- comment videos -->
                            <div *ngFor="let video of c.comment_otherdata.videos">
                                <video controls>
                                    <source [src]="video.video_url" type="video/mp4">
                                  </video>
                            </div>
                            <!-- comment audios -->
                            <div *ngFor="let audio of c.comment_otherdata.audios">
                                  <audio class="w-100" controls>
                                    <source [src]="audio.audio_url" type="audio/webm" controlsList="nodownload">
                                    {{'BROWSER_NOT_SUPPORTED' | translate}}
                                </audio>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <span>{{ c.creationtime | dateAgo:({printAgo: true, timezone: timezone}) }}</span>
            </div>
            <!-- <div class="text-right" *ngIf="c.private_userid && c.team.role != 'INTERVIEWED'">
                <button class="btn-sicche btn-secondary small btn-icon" (click)="privateCommentScroll(a)"><i class="fal fa-reply"></i></button>
            </div> -->
        </div>

       <div class="row mt-4 p-0" *ngIf="hasPriavateComment(a)">
           <div class="col-md-12">
               <label for="comment_private_{{a.id}}">
                   <input type="checkbox" id="comment_private_{{a.id}}" [(ngModel)]="commentIsPrivate">
                   {{'PRIVATE_COMMENT' | translate}}
               </label>
           </div>
       </div>



        <div class="col-12 mt-3">
            <div class="form-group" id="comment_input_answer_id_{{a.id}}">
                <textarea rows="3" [(ngModel)]="currentComment" class="form-control"></textarea>
                <span class="placeholder" *ngIf="!currentComment">
                    {{'ADD_COMMENT' | translate}}
                </span>
                <span *ngIf="sendingComment" class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </span>
            </div>
        </div>

        <div class="col-12 py-0 mt-4 px-3">
            <div class="row" *ngIf="canComment" >
                <div class="col-md-6">
                    <div class="image-upload form-group choose-file-input mb-md-0">
                        <label>
                            <span class="placeholder">{{'UPLOAD_IMAGE' | translate}}</span>
                            <input type="file" accept="image/*" multiple (change)="addImages($event.target.files)">
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="image-upload form-group choose-file-input mb-md-0">
                        <label>
                            <span class="placeholder">{{'UPLOAD_VIDEO' | translate}}</span>
                            <input type="file" accept="video/*" id="videoInput" (change)="addVideo($event.target.files, $event)">
                        </label>
                    </div>
                </div>
                <!-- <div class="col-md-4">
                    <div class="mb-3 d-flex align-items-center">
                        <button class="btn-sicche mr-4" [class.btn-primary]="!recording" [class.active]="recording" (click)="startStop()">
                            <i *ngIf="!recording" class="fal fa-microphone"></i>
                            <i *ngIf="recording" class="fas fa-microphone-slash"></i>
                        </button>
                        <span>{{recordTime | secondsToMinutesSeconds}}</span>
                    </div>
                </div> -->


                <div class="row">

                    <div *ngIf="imagePreviews.length > 0" class="col-6 col-md-4">
                        <div *ngFor="let img of imagePreviews">
                            <i class="fal fa-times remove-item-btn" (click)="removeImage(img)"></i>
                            <img [src]="img" class="answer-image">
                        </div>
                    </div>

                    <div *ngIf="commentVideoNamesKey.length > 0" class="col-6 col-md-4">
                        <div *ngFor="let vid of commentVideoNamesKey" class="answer-video-container">
                            <span *ngIf="vid">
                                <i class="fal fa-times remove-item-btn" (click)="removeVideo(vid.key)"></i>
                                <span>{{vid.name}}</span>
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div  class="text-right mt-4">
            <button class="btn-sicche btn-secondary" [disabled]="(!currentComment && commentVideoCount == 0 && commentImages.length == 0) || postingComment"
                (click)="postComment(a)">{{'SEND' | translate}}</button>
        </div>
    </div>
</div>