<div>
    <div *ngIf="question">
        <div class="question-content px-3">
            <div class="attached-file-container" *ngIf="question.question_data.attached_pdf_files">
                <span class="attached-file" *ngFor="let pdf of question.question_data.attached_pdf_files">
                    <a [href]="pdf.pdf_url | privatesrc" target="blank">{{pdf.name}}</a>
                </span>
            </div>
            <div class="attached-image-container" *ngIf="question.question_data.attached_images">
                <img *ngFor="let img of question.question_data.attached_images" [src]="img.img_url | privatesrc" [alt]="img.name">
            </div>

            <!-- answer box -->
            <div *ngIf="!question.answered_by_currentuser && (question.is_masked || answerBox || !block.is_room_group)">
                <div class="py-3">
                    <label class="answer-label w-100 mb-0">
                        <!-- answer custom -->
                        <div class="answer-container px-2" *ngFor="let a of question.question_data.answers">
                            <div class="panel shadow m-0 h-100 d-flex flex-column p-0">
                                <label [for]="a.id" class="d-flex flex-column align-items-start justify-content-start mb-0 input-img-box p-3">
                                    <div class="d-flex align-items-start check mb-0">
                                        <input type="checkbox" [id]="a.id" [checked]="isChecked(a.id)" (click)="checkAnswer(a.id)">
                                        <p class="px-2 mb-0">{{ a.comment }}</p>
                                    </div>

                                    <img *ngIf="a.image_url" [src]="a.image_url | privatesrc" alt="">
                                </label>
                                <div *ngIf="question.question_data.add_comment_field && isChecked(a.id)" class="mb-0 comment-box w-100 p-3 mt-2">
                                    <h5 class="mb-2">{{ 'ADD_COMMENT' | translate }}</h5>
                                    <div class="form-group required mb-0">
                                        <!--
                                        <label for="comment_{{a.id}}" class="placeholder">{{ 'ADD_COMMENT' | translate }}</label>
                                        <input id="comment_{{a.id}}" class="form-control" type="text" (change)="addCommentToAnswer(a.id)" [(ngModel)]="currentAnswerComment[a.id]">
                                        -->
                                        <textarea id="comment_{{a.id}}" class="form-control" type="text" (change)="addCommentToAnswer(a.id)" [(ngModel)]="currentAnswerComment[a.id]"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
                <div>
                    <div class="mt-3 text-right">
                        <button *ngIf="!submitting" (click)="submit()" class="btn-primary sticky-footer" [disabled]="!canSubmit()">{{
                                ((question.minimum_multiple_answers_nr && question.minimum_multiple_answers_nr > 0 && !block.is_room_group)
                                ? 'SHOW_ANSWER'
                                : 'SEND_ANSWER' )| translate
                            }}</button>
                        <button *ngIf="submitting" class="btn-primary sticky-footer"><i class="fal fa-spinner-third fa-spin"></i></button>
                    </div>
                </div>
            </div>

            <!-- risposte altri utenti -->
            <div class="container-fluid mt-3" *ngIf="question.answered_by_currentuser || (!question.is_masked && block.is_room_group)">
                <div *ngIf="answers">
                    <div *ngFor="let a of answers" id="answer_id_{{a.id}}">
                        <div  class="panel shadow m-0 h-100 d-flex flex-column mt-3" id="answer_id_{{a.id}}">
                            <div class="d-flex p-0">
                                <div class="col-2 col-xl-1 px-1 text-center">
                                    <div class="avatar-image-container" [style.background-image]="'url(' + (a.team.avatar_url | privatesrc) + ')'">
                                        <div class="placeholder-avatar" *ngIf="!a.team.avatar_url">
                                            <i class="fal fa-user-alt"></i>
                                        </div>
                                    </div>
                                    <span><small (click)="like(a)" class="bold"><i class="fal fa-heart" [ngClass]="{'fill': a.total_like_to_answer || a.total_like_to_comment || a.currentuser_like_answer}"></i>&nbsp;{{a.total_like_to_answer || a.total_like_to_comment}}</small></span>
                                </div>
                                <!-- custom -->
                                <div [className]="block.is_room_group ? 'col-10' : 'col-12'">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="user-name mb-2">{{a.team.nickname || (a.team.firstname + ' ' + a.team.lastname)}}</div>
                                        </div>
                                    </div>

                                    <div class="answer-label" *ngFor="let answerData of a.answer_data">
                                        <div class="answer-container">
                                            <label>
                                                <span>
                                                    <input type="checkbox" disabled [checked]="true">
                                                    {{ getAnswerFromId(answerData.optionid).comment }}
                                                </span>
                                                <img class="mt-3 d-flex" *ngIf="getAnswerFromId(answerData.optionid).image_url" [src]="getAnswerFromId(answerData.optionid).image_url" alt="">
                                            </label>
                                            <p class="mb-3">
                                                {{answerData.comment}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- comments and likes -->
                            <app-questions-comments-like [a]="a"  [answers]="answers"></app-questions-comments-like>
                        </div>
                    </div>
                </div>
                <div *ngIf="answers && answers.length == 0">
                    {{'NO_ANSWERS_YET' | translate}}
                </div>
                <button *ngIf="!question.answered_by_currentuser && !(!question.answered_by_currentuser && (question.is_masked || answerBox || !block.is_room_group))" (click)="answerBox = true" class="btn-sicche btn-primary sticky-footer">
                   <span *ngIf="question.minimum_multiple_answers_nr && block.is_room_group">
                        {{'ADD_NEW_POST' | translate}}<i class="fal fa-reply ml-2 mr-0"></i>
                    </span>
                    <span *ngIf="!question.minimum_multiple_answers_nr || block.is_room_group">
                        {{'SHOW_ANSWER' | translate}}<i class="fal fa-reply ml-2 mr-0"></i>
                    </span>
                </button>
            </div>
        </div>
    </div>
</div>
