import { Injectable } from '@angular/core';
import { BaseServiceI } from '@app/core/services/base-interface.service';
import { BaseService } from '@app/core/services/base.service';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { catchError, count } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { ServiceMessageModel } from '@app/core/models/service-message.model';
import { CountryModel } from '../models/country.model';
import { ResponseModel } from '@app/core/models/response.model';
import { NoticeModel } from '../models/notice.model';
import { CountryService } from './country.service';


@Injectable({
  providedIn: 'root'
})
export class NotificationService extends BaseService implements BaseServiceI {


  constructor(public router: Router, public apiService: ApiService, private http: HttpClient, private countryService: CountryService) {
    super(router, apiService, NoticeModel);
  }

  currentNotice$: NoticeModel;

  get currentNotice() {
    return this.currentNotice$;
  }

  set currentNotice(notice: NoticeModel) {
    this.currentNotice$ = notice;
  }

  getNotices() {
    return this.get('interviewed', {country_uniqid: this.countryService.getCountryCode()});
  }

  setNoticesRead(notificationids: number[]) {
    return this.sub('interviewed_read').create({country_uniqid: this.countryService.getCountryCode(), notificationids});
  }

}
