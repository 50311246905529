import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { BlockModel } from '@app/shared/models/block.model';
import { AnswerService } from '@app/shared/services/answer.service';
import { TeamModel } from '@app/shared/models/team.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@app/shared/services/notification.service';
import { AnswerBaseComponent } from '../answer-base-component';
import { BlockService } from '@app/shared/services/block.service';
import { ImageAnswer } from '../models/image-answer';
import { UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-image-answer',
  templateUrl: './image-answer.component.html',
  styleUrls: ['./image-answer.component.scss']
})
export class ImageAnswerComponent extends AnswerBaseComponent implements OnInit {


  @Input() questionInput: QuestionModel;
  @Input() blockInput: BlockModel;
  @Input() userInput: TeamModel;
  @Output() closeAnswerInput = new EventEmitter();

  comments: UntypedFormArray = new UntypedFormArray([]);
  answer: ImageAnswer = { images: [], comments: [] };


  constructor(
    public toastService: ToastrService,
    public translateService: TranslateService,
    public answerService: AnswerService,
    public notificationService: NotificationService,
    public blockService: BlockService,
  ) {
    super(toastService, translateService, answerService, notificationService, blockService);
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['link'],
        ['emoji']
      ]
    };
  }

  ngOnInit(): void {
    this.initialize(this.questionInput, this.blockInput, this.userInput, this.closeAnswerInput, this.answer);
  }

  addImages(files: FileList) {
    Array(files.length).fill(0).map((e, i) => i).forEach(fi => {
      if (this.question.question_data.add_comment_field) {
        this.comments.push(new UntypedFormControl('', [Validators.required]));
      }
      super.addImage(files[fi]);
    });
  }

  removeImageWithComment(img: any, i: number) {
    if (this.question.question_data.add_comment_field) {
      this.comments.removeAt(i);
    }
    super.removeImage(img);
  }

  submit() {
    this.answer.comments = this.comments.getRawValue().map(c => c.trim().replace(/  +/g, ' '));
    super.submit();
  }
}
