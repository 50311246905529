import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Mode } from '../../setup';
import { AuthGuard } from '@app/core/guards/auth/auth.guard';
import { AlreadyLoggedInGuard } from '@app/core/guards//auth/public.guard';
// Layers
import { PublicComponent } from '@app/pages/layers/public/public.component';
import { PrivateComponent } from '@app/pages/layers/private/private.component';
import { HomeComponent } from '@app/pages/layers/public/home/home.component';
import { LoginComponent } from './pages/layers/public/login/login.component';
import { QuestionComponent } from './pages/layers/public/components/question/question.component';
import { ResearchComponent } from './pages/layers/private/research/research.component';
import { QuestionListComponent } from './pages/layers/private/research/block/question-list/question-list.component';
import { OnboardingComponent } from './pages/layers/private/onboarding/onboarding.component';
import { NicknameSelectionComponent } from './pages/layers/private/onboarding/nickname-selection/nickname-selection.component';
import { PrivacyPolicyComponent } from './pages/layers/private/onboarding/privacy-policy/privacy-policy.component';
import { SummaryComponent } from './pages/layers/private/onboarding/summary/summary.component';
import { LogoutComponent } from './pages/layers/private/logout/logout.component';
import { SetCountryComponent } from './pages/layers/public/set-country/set-country.component';
import { PasswordSelectionComponent } from './pages/layers/private/onboarding/password-selection/password-selection.component';
import { SetDayBlockComponent } from './pages/layers/public/set-day-block/set-day-block.component';
import { LockedComponent } from './pages/layers/private/locked/locked.component';
import { OnBoardedGuard } from '@app/shared/guards/Onboarded.guard';

/**
 * Custom public routes
 */
export const PUBLIC_ROUTES: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'onboarding', component: HomeComponent },
  { path: 'set-country/:country_uniqid', component: SetCountryComponent },
];

/**
 * Custom private routes
 */
export const SECURE_ROUTES: Routes = [
  { path: 'set-country/:country_uniqid', component: SetCountryComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'go-to-block/:date/:blockid', component: SetDayBlockComponent, canActivate: [OnBoardedGuard] },
  { path: 'research', component: ResearchComponent, canActivate: [OnBoardedGuard] },
  { path: 'logout', component: LogoutComponent },
  { path: 'question-list', component: QuestionListComponent, canActivate: [OnBoardedGuard] },
  { path: 'locked', component: LockedComponent },
  { path: 'onboarding', component: OnboardingComponent, children: [
    { path: 'nickname-selection', component: NicknameSelectionComponent },
    { path: 'password-selection', component: PasswordSelectionComponent },
    { path: 'password-selection/:change_password_only', component: PasswordSelectionComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'summary', component: SummaryComponent }
  ] }
];


/**
 * Main route ''
 */
const APP_ROUTES: Routes = [
  { path: 'set-country/:country_uniqid', component: SetCountryComponent },
  { path: '', component: PublicComponent, canActivateChild: [AlreadyLoggedInGuard], children: PUBLIC_ROUTES },
  { path: '', component: PrivateComponent, canActivateChild: [AuthGuard], children: SECURE_ROUTES },

];

@NgModule({
  // 'preloadingStrategy' lets you preload all the lazy loaded modules after the 'main' ones have finished loading
  imports: [RouterModule.forRoot(APP_ROUTES, { preloadingStrategy: PreloadAllModules, useHash: Mode.USE_HASH })],
  exports: [RouterModule]
})

export class AppRoutingModule {
}


