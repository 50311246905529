<div>
    <div *ngIf="question">
        <div class="question-content px-3">
            <div class="attached-file-container" *ngIf="question.question_data.attached_pdf_files">
                <span class="attached-file" *ngFor="let pdf of question.question_data.attached_pdf_files">
                    <a [href]="pdf.pdf_url | privatesrc" target="blank">{{pdf.name}}</a>
                </span>
            </div>
            <div class="attached-image-container" *ngIf="question.question_data.attached_images">
                <img *ngFor="let img of question.question_data.attached_images" [src]="img.img_url | privatesrc" [alt]="img.name">
            </div>

            <!-- answer box -->
            <div *ngIf="!question.answered_by_currentuser && (question.is_masked || answerBox || !block.is_room_group)">
                <div class="py-3">
                    <!-- answer custom -->
                    <div class="col-12 panel shadow mt-0 h-100">
                        <p>{{ question.question_data.suggestion }}</p>

                        <div class="image-upload form-group mb-0 choose-file-input">
                            <label>
                                <span class="placeholder">{{'UPLOAD_VIDEO' | translate}}</span>
                                <!-- <input [disabled]="answer.videos.length >= 1" type="file" accept="video/*" (change)="addVideo($event.target.files)"> -->
                                <input type="file" [disabled]="videoNamesKey.length >= 1" accept="video/*" id="videoInput" (change)="addVideo($event.target.files, $event)">
                            </label>
                        </div>
                    </div>

                    <div *ngIf="videoPreviews.length > 0" class="row">
                        <div *ngFor="let vid of videoPreviews; let i = index" class="col-12 col-md-6 col-lg-4 d-flex flex-column">
                            <div *ngIf="vid" class="panel shadow flex-fill d-flex flex-column justify-content-between">
                                <div class="vid-wrap d-flex align-items-center justify-content-center flex-fill">
                                    <i class="fal fa-times remove-item-btn" (click)="removeVideo(vid)"></i>
                                    <video controls class="answer-video">
                                        <source [src]="vid" type="video/mp4">
                                    </video>
                                </div>
                                <!-- <div *ngIf="question.question_data.add_comment_field" class="flex-grow-0 form-group mt-3 mb-0 required">
                                    <input type="text" [formControl]="comments.controls[i]" class="form-control">
                                    <span class="placeholder">{{ 'COMMENT' | translate }}</span>
                                </div> -->
                            </div>
                        </div>
                    </div>
                   <div class="row">
                    <div *ngIf="videoNamesKey.length > 0" class="col-6 col-md-4">
                        <div *ngFor="let vid of videoNamesKey" class="answer-video-container">
                            <span *ngIf="vid">
                                <i class="fal fa-times remove-item-btn" (click)="removeVideo(vid.key)"></i>
                                <span>{{vid.name}}</span>
                            </span>
                        </div>
                    </div>
                   </div>
                </div>
                <div>
                    <div class="mt-3 text-right">
                        <button *ngIf="!submitting" (click)="submit()" class="btn-primary sticky-footer" [disabled]="videoNamesKey.length !== 1">{{
                                ((question.minimum_multiple_answers_nr && question.minimum_multiple_answers_nr > 0 && !block.is_room_group)
                                ? 'SHOW_ANSWER'
                                : 'SEND_ANSWER' )| translate
                            }}</button>
                        <button *ngIf="submitting" class="btn-primary sticky-footer"><i class="fal fa-spinner-third fa-spin"></i></button>
                    </div>
                </div>
            </div>

            <!-- risposte altri utenti -->
            <div class="container-fluid mt-3" *ngIf="question.answered_by_currentuser || (!question.is_masked && block.is_room_group)">

                <div *ngIf="answers">
                    <div *ngFor="let a of answers" id="answer_id_{{a.id}}">
                        <div  class="panel shadow m-0 h-100 d-flex flex-column mt-3" id="answer_id_{{a.id}}">
                            <div class="d-flex p-0">
                                <div class="col-2 col-xl-1 px-1 text-center">
                                    <div class="avatar-image-container" [style.background-image]="'url(' + (a.team.avatar_url | privatesrc) + ')'">
                                        <div class="placeholder-avatar" *ngIf="!a.team.avatar_url">
                                            <i class="fal fa-user-alt"></i>
                                        </div>
                                    </div>
                                    <span (click)="like(a)"><small class="bold"><i class="fal fa-heart" [ngClass]="{'fill': a.total_like_to_answer || a.total_like_to_comment || a.currentuser_like_answer}"></i>&nbsp;{{a.total_like_to_answer || a.total_like_to_comment}}</small></span>
                                </div>
                                <!-- custom -->
                                <div [className]="block.is_room_group ? 'col-10' : 'col-12'">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class=" user-name mb-2">{{a.team.nickname || (a.team.firstname + ' ' + a.team.lastname)}}</div>
                                        </div>
                                    </div>

                                    <div *ngIf="a.answer_data.videos && a.answer_data.videos.length > 0">
                                        <div class="answer-video-container" *ngFor="let vid of a.answer_data.videos">
                                            <video controls class="answer-video">
                                                <source [src]="vid.video_url | privatesrc" type="video/mp4">
                                            </video>
                                        </div>
                                        <!-- <div *ngIf="question.question_data.add_comment_field" class="comment flex-fill mx-2">
                                            <span class="placeholder">{{ 'COMMENT' | translate }}</span>
                                            <p>{{ answerData.comment }}</p>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <!-- comments and likes -->
                            <app-questions-comments-like [a]="a"  [answers]="answers"></app-questions-comments-like>
                        </div>
                    </div>
                </div>
                <div *ngIf="answers && answers.length == 0">
                    {{'NO_ANSWERS_YET' | translate}}
                </div>

                <button *ngIf="!question.answered_by_currentuser && !(!question.answered_by_currentuser && (question.is_masked || answerBox || !block.is_room_group))" (click)="answerBox = true" class="btn-sicche btn-primary sticky-footer">
                   <span *ngIf="question.minimum_multiple_answers_nr && block.is_room_group">
                        {{'ADD_NEW_POST' | translate}}<i class="fal fa-reply ml-2 mr-0"></i>
                    </span>
                    <span *ngIf="!question.minimum_multiple_answers_nr || block.is_room_group">
                        {{'SHOW_ANSWER' | translate}}<i class="fal fa-reply ml-2 mr-0"></i>
                    </span>
                </button>
            </div>
        </div>
    </div>
</div>
