<div class="px-5 h-100 d-flex align-items-center justify-content-center flex-column">
  <div *ngIf="!operation('requesting').success" class="container">
    <h1 class="h2 black mb-5 text-center">{{ 'REQUEST_RESET_THE_PASSWORD' | translate }}</h1>
    <div class="mt-3">
      <form *ngIf="form" [formGroup]="form">
          <div class="" [class.is-invalid]="form.get('username').invalid && form.get('username').touched">
            <div class="form-group mb-0 required">
              <label>
                <input required formControlName="username" type="text" class="form-control" id="username">
                <span class="placeholder">{{ 'USERNAME' | translate }}</span>
              </label>
            </div>
          </div>
          <div *ngIf="form.get('username').invalid && form.get('username').touched">
            <small>
              <span class="text-danger">{{ 'ERRORS.INVALID_USERNAME' | translate }}</span>
            </small>
          </div>
          <small *ngIf="operation('requesting').errors?.username">
            <span class="text-danger">{{ ('SERVER_ERRORS.' + operation('requesting').errors.username) | translate }}</span>
          </small>
          <small *ngIf="operation('requesting').errors">
            <span class="text-danger">{{ ('SERVER_ERRORS.' + operation('requesting').errors.getErrorByKey(0)) | translate }}</span>
          </small>
          <div class="text-center w-100 mt-3">
              <app-core-button-submit
                class="w-100 mt-1 d-block"
                [classes]="'btn-sicche active mx-auto'"
                [spinnerDimension]="'small'"
                [form]="form" 
                (submitCallback)="submit()" 
                [toggle]="operation('requesting').submitting">
                <span>{{ 'RESET' | translate }}</span>
            </app-core-button-submit>
          </div>
      </form>
    </div>
    <div class="col-12 text-center mt-4">
      <a (click)="coreLayoutService.closeSidebar()">{{ 'CANCEL' | translate }}</a>
    </div>
  </div>
  <div class="container" *ngIf="operation('requesting').success">
    <div class="va success-panel text-center">
      <div>
          <i class="text-primary far fa-check fa-3x mb-4"></i>
      </div>
      <h3>{{ 'REQUEST_HAS_BEEN_SENT' | translate }}</h3>
      <p>{{ 'ASK_PASSWORD_RESET.SUCCESS_MESSAGE' | translate }}</p>
      <br/>
      <!-- <a class="as-link" (click)="closeModal()">{{ 'OK_I_UNDERSTAND' | translate }}</a>-->
    </div>
  </div>
</div>