<header class="sidebar-header">
    <div class="close-button float-right mt-3">
        <i (click)="coreLayoutService.closeSidebar()" class="fal fa-times"></i>
    </div>
    <div class="d-flex align-items-center justify-content-between">
        <ng-content class="w-100" select="[slot=bar]"></ng-content>
        <div class="flex-grow-1 sidebar-title">
            <ng-content select="[slot=title]"></ng-content>
        </div>
        <div class="flex-grow-0">
            <ng-content select="[slot=buttons]"></ng-content>
        </div>
    </div>
</header>