import { BaseModel } from '@app/core/models/base';
import { CountryInterface } from './country.interface';


export class CountryModel extends BaseModel implements CountryInterface {

    // tslint:disable: variable-name
    id: number;
    country_code: string;
    language_code: string;
    researchid: string;
    country_uniqid: string;
    title: string;
    timezone: string;
    timezone_locked: boolean;
    logo: any;
    logo_url: string;
    // mandare true per rimuovere logo country
    logo_del: boolean;
    color: string;
    research_status: string;
    research_on_going: boolean;
    research_date_start: Date;
    research_date_start_effective: Date;
    research_date_end: Date;
    research_date_end_effective: Date;
    current_user_role: string;
    current_user_access_modify: boolean;
    current_user_access_moderate: boolean;
    current_user_access_message: boolean;
    current_user_access_report: boolean;
    // da aggiungere
    privacy_html: string;
    privacy_user_flag: boolean;
    completion_perc: number;
    nr_blocks: number;

    static getResourceName() {
        return 'countries';
    }

}
