import { Component, OnInit, Input } from '@angular/core';
import { BlockModel } from '@app/shared/models/block.model';

@Component({
  selector: 'app-question-container',
  templateUrl: './question-container.component.html',
  styleUrls: ['./question-container.component.scss']
})
export class QuestionContainerComponent implements OnInit {
  blocks: BlockModel[];

  constructor() { }

  ngOnInit(): void {
    /* let block1 = new BlockModel();
    block1.title = "Test";
    block1.datetime_start = new Date();
    block1.datetime_end = new Date();
    block1.has_geolocation = true;
    block1.geolocation_address = "Via Pismonte 9";
    block1.is_room_group = false;
    let block2 = new BlockModel();
    block2.title = "Test2";
    block2.datetime_start = new Date();
    block2.datetime_end = new Date();
    block2.has_geolocation = false;
    block2.is_room_group = true;
    this.blocks.push(block1, block2); */
  }

}
