import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '@app/core/services/storage.service';
import { AuthService } from '@app/core/services/auth.service';
import { CountryService } from '@app/shared/services/country.service';
import { ResponseModel } from '@app/core/models/response.model';

@Component({
  selector: 'app-set-country',
  templateUrl: './set-country.component.html',
  styleUrls: ['./set-country.component.scss']
})
export class SetCountryComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private countryService: CountryService
  ) { }

  invalidBrowser = false;
  currentLink = '';

  ngOnInit(): void {
    localStorage.removeItem('phpsessid');
    const countryUniqid = this.activatedRoute.snapshot.paramMap.get('country_uniqid');
    console.log('setting country code', countryUniqid);
    const success = this.countryService.setCountryCode(countryUniqid);
    this.currentLink = `https://respondents.sicche.com/#/set-country/${countryUniqid}`;
    setTimeout(() => {
      if (success) {
        if (this.authService.isAuthenticated()) {
          this.router.navigate(['research']);
        } else {
          this.router.navigate(['login']);
        }
      } else {
        this.invalidBrowser = true;
      }
    }, 500);
  }

  isUserUsingIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      return true;
    }
    return false;
  }

}
