<div class="d-flex justify-content-center mb-3">
    <div class="img-pin-wrap">
        <div (click)="pinPlace($event); scroll(formSectionRef);">
            <img
                #imageel
                class="answer-image"
                style="pointer-events: none"
                alt=""
                [src]="media.image_url | privatesrc"
                (load)="ready = true">
        </div>

        <ng-container *ngIf="ready">
            <ng-container *ngIf="form">
                <div *ngIf="formNewPin.value.position.x && formNewPin.value.position.y"
                    class="pin-placeholder"
                    [style.top]="getPinDistance().top"
                    [style.left]="getPinDistance().left"
                    (click)="showForm = true; scroll(formSectionRef);">
                    <div></div>
                </div>
            </ng-container>

            <ng-container *ngFor="let pin of pins">
                <div
                    class="pin"
                    [class.selected]="selectedPin && pin.pin_uniqueid === selectedPin.pin_uniqueid"
                    [style.top]="getPinDistance(pin).top"
                    [style.left]="getPinDistance(pin).left"
                    (click)="pinSelect(pin)">
                    <app-sicche-emoticon [emoticon]="pin.emoticon" [width]="26"></app-sicche-emoticon>
                    <span *ngIf="!pin.emoticon"><i class="fas fa-thumbtack m-0"></i></span>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>

<div *ngIf="form" class="text-center mb-3">
    {{ 'PIN_ON_IMAGE_INSTRUCTIONS' | translate }}
</div>

<div #formSectionRef class="d-flex justify-content-center mb-3">
    <ng-container *ngIf="showForm" [ngTemplateOutlet]="pinForm"></ng-container>
</div>

<div class="d-flex justify-content-center mb-3">
    <ng-container *ngIf="selectedPin" [ngTemplateOutlet]="pinView"></ng-container>
</div>

<ng-template #pinForm>
    <div class="card pin-form mb-3">
        <div class="card-header">
            <ng-container *ngIf="form">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        {{ 'NEW_PIN_HEADER' | translate }}
                    </div>
                    <div>
                        <div class="pin-control" (click)="showForm = false">
                            <i class="fas fa-times m-0"></i>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="card-body">
            <form [formGroup]="formNewPin" class="pin-form">
                <div *ngIf="requireEmoticons" class="form-group mb-2">
                    <div class="emoticon-input-wrap">
                        <input formControlName="emoticon" name="emoticon" type="radio" id="emo3" value="3">
                        <label for="emo3">
                            <app-sicche-emoticon [emoticon]="3" [width]="26"></app-sicche-emoticon>
                        </label>
                    </div>
                    <div class="emoticon-input-wrap">
                        <input formControlName="emoticon" name="emoticon" type="radio" id="emo2" value="2">
                        <label for="emo2">
                            <app-sicche-emoticon [emoticon]="2" [width]="26"></app-sicche-emoticon>
                        </label>
                    </div>
                    <div class="emoticon-input-wrap">
                        <input formControlName="emoticon" name="emoticon" type="radio" id="emo1" value="1">
                        <label for="emo1">
                            <app-sicche-emoticon [emoticon]="1" [width]="26"></app-sicche-emoticon>
                        </label>
                    </div>
                </div>
                <div class="mb-2 w-100">
                    <div class="form-group mb-0 required">
                        <textarea formControlName="comment" id="comment" class="form-control"></textarea>
                    </div>
                </div>
    
                <div class="d-flex justify-content-end">
                    <button
                        class="btn btn-outline-primary"
                        [disabled]="!formNewPin.valid"
                        (click)="pinConfirm(); showForm = false;">
                        {{ 'SAVE_COMMENT' | translate }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</ng-template>


<ng-template #pinView>
    <div class="card pin-view mb-3">
        <div *ngIf="form" class="card-header">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <!-- ({{ selectedPin.position.x | number: '1.0-0' }}, {{ selectedPin.position.y | number: '1.0-0' }}) -->
                </div>
                <div>
                    <a class="pin-control" (click)="pinDelete(selectedPin)">
                        <i class="far fa-trash-alt m-0"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div *ngIf="selectedPin.emoticon" class="text-center emoticon">
                <app-sicche-emoticon [emoticon]="selectedPin.emoticon" [width]="26"></app-sicche-emoticon>
            </div>
            <div *ngIf="selectedPin.comment" class="text-center" [class.mt-2]="selectedPin.emoticon">
                <span>{{ selectedPin.comment }}</span>
            </div>
        </div>
    </div>
</ng-template>
