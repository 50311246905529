<div class="card" *ngIf="block">
    <div class="image" *ngIf="block.image_url" [ngStyle]="{'background-image':'url('+ (block.image_url | privatesrc) +')'}">
    </div>
    <div class="image" *ngIf="!block.image_url" style="background-image:url('./assets/images/sfondo_sicche_blocco.jpg')">
    </div>
    <div class="description py-3">
        <div class="container-fluid description-content">
            <h4 class="mb-4 text-left title">{{ block.title }} <span *ngIf="block.is_diary && day"> - {{day | dateToDayMonth}}</span></h4>
            <div class="row description-text">
                <div class="col-12 description-data">
                    <p class="d-flex align-items-center text-left" *ngIf="!block.is_room_group"><i class="fal fa-user"></i> <span>{{ "ROOM.INDIVIDUAL_INTERVIEW" | translate }}</span></p>
                    <p class="d-flex align-items-center text-left" *ngIf="block.is_room_group"><i class="fal fa-user"></i> <span>{{ "ROOM.GROUP_INTERVIEW" | translate }}</span></p>
                    <p class="d-flex align-items-center text-left"><i class="fal fa-clock"></i>
                        {{'UNTIL' | translate}} {{block.datetime_end | dateToLocaleString}}
                    </p>
                    <p class="d-flex align-items-start text-left" *ngIf="block.has_geolocation">
                        <i class="fal fa-map-marker-alt"></i>
                        <span>
                            <small class="label">{{'YOU_NEED_TO_BE_LOCATED_IN' | translate}}</small><br>
                            <span class="mt-n1">{{ block.geolocation_address }}</span>
                        </span>
                    </p>
                </div>
                <div class="col-12 d-flex align-items-center missing-quest text-left description-answers">
                    <h3 class="extra-bold mr-2 mb-0">{{block.current_interviewed_nr_question_answered || 0}}/{{block.current_interviewed_nr_question_total || 0}}</h3>
                    <p class="bold label mb-0">{{ "ROOM.MISSING_ANSWERS" | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
