import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuestionModel } from '@app/shared/models/question.model';
import { BlockModel } from '@app/shared/models/block.model';
import { AnswerService } from '@app/shared/services/answer.service';
import { TeamModel } from '@app/shared/models/team.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@app/shared/services/notification.service';
import { AnswerBaseComponent } from '../answer-base-component';
import { BlockService } from '@app/shared/services/block.service';
import { AttitudeScale } from '../models/attitude-scale';

@Component({
  selector: 'app-attitude-scale',
  templateUrl: './attitude-scale.component.html',
  styleUrls: ['./attitude-scale.component.scss']
})
export class AttitudeScaleComponent extends AnswerBaseComponent implements OnInit {

  @Input() questionInput: QuestionModel;
  @Input() blockInput: BlockModel;
  @Input() userInput: TeamModel;
  @Output() closeAnswerInput = new EventEmitter();

  steps: any[] = [];
  hasImageScale = false;

  constructor(
    public toastService: ToastrService,
    public translateService: TranslateService,
    public answerService: AnswerService,
    public notificationService: NotificationService,
    public blockService: BlockService
  ) {
    super(toastService, translateService, answerService, notificationService, blockService);
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['link'],
        ['emoji']
      ]
    };
  }
  currentAnswerComment = {};


  answer: AttitudeScale[] = [];


  ngOnInit(): void {
    this.initialize(this.questionInput, this.blockInput, this.userInput, this.closeAnswerInput, this.answer);
    this.hasImageScale = this.question.question_data.images && this.question.question_data.images.length;
    this.steps = Array(this.hasImageScale
      ? this.question.question_data.images.length
      : this.question.question_data.steps
    )
      .fill(0)
      .map((e, i) => i + 1);

    if (this.question.question_data.include_zero) {
      // this.steps = this.steps.map(s => s - 1);
      this.steps = [0, ...this.steps];
    }

    if (this.question.question_data.randomize_order) {
      this.question.question_data.statements = this.randomizeOrder(this.question.question_data.statements);
    }
  }

  getStatementFromIdx(idx: number) {
    return this.question.question_data.statements[idx].statement;
  }

  checkAnswer(statementI: number, stepI: number) {
    const a = this.answer.find(s => s.statement === statementI);
    if (a) {
      a.step = stepI;
    } else {
      this.answer = [...this.answer, { statement: statementI, step: stepI}];
    }
  }

  isChecked(statement: number, step: number) {
    const a = this.answer.find(s => s.statement === statement);
    return a
      ? a.step === step
      : false;
  }
}
