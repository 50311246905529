import { Component, OnInit, Input } from '@angular/core';
import { NoticeModel } from '@app/shared/models/notice.model';
import { NotificationService } from '@app/shared/services/notification.service';
import { CoreLayoutService } from '@app/core/services/layout.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {

  constructor(
    private notificationService: NotificationService,
    private coreLayoutService: CoreLayoutService
  ) { }

  @Input() notices: NoticeModel[];

  toSetRead: number[];

  ngOnInit(): void {
    // this.toSetRead = [];
    this.toSetRead = this.notices ? this.notices.filter(n => n.type === 'LIKE').map(n => n.id) : [];
  }

  close() {
    this.notificationService.setNoticesRead(this.toSetRead).subscribe(() => {
      this.coreLayoutService.closeSidebar();
    });
    this.coreLayoutService.closeSidebar();
  }

  openNotice(n: NoticeModel) {
    this.toSetRead.push(n.id);
    this.notificationService.currentNotice = n;
    this.close();
  }
}
